.gg-abstract {
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 14px;
  height: 14px;
  transform: scale(var(--ggs, 1));
  border-right: 3px solid;
  border-top: 3px solid;
}
.gg-abstract::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 10px;
  height: 10px;
  left: 0;
  bottom: 0;
  border: 3px solid;
  border-radius: 12px;
}

.gg-add-r {
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 22px;
  height: 22px;
  border: 2px solid;
  transform: scale(var(--ggs, 1));
  border-radius: 4px;
}
.gg-add-r::after, .gg-add-r::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 10px;
  height: 2px;
  background: currentColor;
  border-radius: 5px;
  top: 8px;
  left: 4px;
}
.gg-add-r::after {
  width: 2px;
  height: 10px;
  top: 4px;
  left: 8px;
}

.gg-add {
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 22px;
  height: 22px;
  border: 2px solid;
  transform: scale(var(--ggs, 1));
  border-radius: 22px;
}
.gg-add::after, .gg-add::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 10px;
  height: 2px;
  background: currentColor;
  border-radius: 5px;
  top: 8px;
  left: 4px;
}
.gg-add::after {
  width: 2px;
  height: 10px;
  top: 4px;
  left: 8px;
}

.gg-adidas {
  position: relative;
  box-sizing: border-box;
  display: block;
  width: 23px;
  height: 15px;
  transform: scale(var(--ggs, 1));
  overflow: hidden;
}
.gg-adidas::before {
  content: "";
  position: absolute;
  box-sizing: border-box;
  display: block;
  width: 5px;
  height: 18px;
  background: currentColor;
  transform: rotate(-30deg);
  left: 4px;
  bottom: -14px;
  box-shadow: 7px -2px, 14px -4px;
}

.gg-airplane {
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 18px;
  height: 18px;
  transform: scale(var(--ggs, 1));
  background: linear-gradient(to left, currentColor 22px, transparent 0) no-repeat center/16px 2px, radial-gradient(circle, currentColor 60%, transparent 40%) no-repeat right center/2px 2px;
}
.gg-airplane::after {
  content: "";
  display: block;
  position: absolute;
  box-sizing: border-box;
  width: 10px;
  height: 8px;
  border-right: 4px solid;
}
.gg-airplane::before {
  content: "";
  display: block;
  position: absolute;
  box-sizing: border-box;
  width: 10px;
  height: 8px;
  border-right: 4px solid;
  border-top-right-radius: 2px;
  top: 0;
  transform: perspective(10px) rotateX(10deg) skewX(30deg);
  box-shadow: -4px 3px 0 -2px;
}
.gg-airplane::after {
  border-bottom-right-radius: 2px;
  bottom: 0;
  transform: perspective(10px) rotateX(-10deg) skewX(-30deg);
  box-shadow: -4px -3px 0 -2px;
}

.gg-alarm {
  background: linear-gradient(to left, currentColor 5px, transparent 0) no-repeat 4.5px 6px/5px 2px, linear-gradient(to left, currentColor 7px, transparent 0) no-repeat 4.5px 2px/2px 6px;
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 16px;
  height: 16px;
  border-radius: 100%;
  border: 2px solid transparent;
  transform: scale(var(--ggs, 1));
  box-shadow: 0 0 0 2px;
}
.gg-alarm::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 6px;
  height: 2px;
  background: currentColor;
  top: -4px;
}
.gg-alarm::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 6px;
  height: 2px;
  background: currentColor;
  top: -4px;
  left: -5px;
  transform: rotate(-42deg);
}
.gg-alarm::after {
  transform: rotate(42deg);
  right: -5px;
}

.gg-album {
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 18px;
  height: 18px;
  transform: scale(var(--ggs, 1));
  border-left: 7px solid transparent;
  border-right: 3px solid transparent;
  border-bottom: 8px solid transparent;
  box-shadow: 0 0 0 2px, inset 6px 4px 0 -4px, inset -6px 4px 0 -4px;
  border-radius: 3px;
}
.gg-album::after, .gg-album::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 2px;
  height: 5px;
  background: currentColor;
  transform: rotate(46deg);
  top: 5px;
  right: 4px;
}
.gg-album::after {
  transform: rotate(-46deg);
  right: 2px;
}

.gg-align-bottom {
  box-sizing: border-box;
  position: relative;
  display: block;
  height: 12px;
  width: 12px;
  border-bottom: 2px solid;
  transform: scale(var(--ggs, 1));
  margin-top: 4px;
}
.gg-align-bottom::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 4px;
  background: currentColor;
  bottom: 2px;
}
.gg-align-bottom::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 4px;
  background: currentColor;
  bottom: 2px;
  left: 1px;
  height: 12px;
}
.gg-align-bottom::after {
  height: 6px;
  right: 1px;
  opacity: 0.5;
}

.gg-align-center {
  box-sizing: border-box;
  position: relative;
  display: block;
  height: 12px;
  width: 12px;
  border-top: 2px solid transparent;
  transform: scale(var(--ggs, 1));
  margin-left: -4px;
}
.gg-align-center::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  height: 4px;
  background: currentColor;
}
.gg-align-center::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  height: 4px;
  background: currentColor;
  left: 2px;
  width: 12px;
  top: -2px;
}
.gg-align-center::after {
  width: 6px;
  right: 1px;
  top: 4px;
  opacity: 0.5;
}

.gg-align-left {
  box-sizing: border-box;
  position: relative;
  display: block;
  height: 12px;
  width: 12px;
  border-left: 2px solid;
  transform: scale(var(--ggs, 1));
  margin-left: -4px;
}
.gg-align-left::after, .gg-align-left::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  height: 4px;
  width: 12px;
  background: currentColor;
  left: 2px;
  top: 1px;
}
.gg-align-left::after {
  width: 6px;
  top: 7px;
  opacity: 0.5;
}

.gg-align-middle {
  box-sizing: border-box;
  position: relative;
  display: block;
  height: 12px;
  width: 12px;
  transform: scale(var(--ggs, 1));
  border-top: 2px solid transparent;
  margin-top: -8px;
}
.gg-align-middle::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 4px;
  background: currentColor;
}
.gg-align-middle::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 4px;
  background: currentColor;
  left: 1px;
  height: 12px;
  top: 2px;
}
.gg-align-middle::after {
  height: 6px;
  right: 1px;
  top: 5px;
  opacity: 0.5;
}

.gg-align-right {
  box-sizing: border-box;
  position: relative;
  display: block;
  height: 12px;
  width: 12px;
  border-right: 2px solid;
  transform: scale(var(--ggs, 1));
  margin-right: -4px;
}
.gg-align-right::after, .gg-align-right::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  height: 4px;
  width: 12px;
  background: currentColor;
  right: 2px;
  top: 1px;
}
.gg-align-right::after {
  width: 6px;
  top: 7px;
  opacity: 0.5;
}

.gg-align-top {
  box-sizing: border-box;
  position: relative;
  display: block;
  height: 12px;
  width: 12px;
  border-top: 2px solid;
  transform: scale(var(--ggs, 1));
  margin-top: -4px;
}
.gg-align-top::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 4px;
  background: currentColor;
  top: 2px;
}
.gg-align-top::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 4px;
  background: currentColor;
  top: 2px;
  left: 1px;
  height: 12px;
}
.gg-align-top::after {
  height: 6px;
  right: 1px;
  opacity: 0.5;
}

.gg-anchor {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 2px;
  height: 14px;
  background: currentColor;
  border-radius: 8px;
}
.gg-anchor::after, .gg-anchor::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  height: 6px;
  border: 2px solid;
}
.gg-anchor::after {
  width: 12px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  border-top-color: transparent;
  left: -5px;
  bottom: 2px;
}
.gg-anchor::before {
  width: 6px;
  border-radius: 8px;
  top: -4px;
  left: -2px;
}

.gg-apple-watch {
  background: linear-gradient(to left, currentColor 5px, transparent 0) no-repeat 13px 4px/2px 4px;
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 24px;
  height: 24px;
  border: 5px solid transparent;
  transform: scale(var(--ggs, 1));
  border-radius: 9px;
  box-shadow: inset 0 0 0 2px;
}
.gg-apple-watch::after, .gg-apple-watch::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border-radius: 3px;
  width: 10px;
  height: 5px;
  border: 2px solid;
  border-bottom: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  left: 2px;
}
.gg-apple-watch::after {
  top: -3px;
}
.gg-apple-watch::before {
  transform: scaleY(-1);
  top: 12px;
}

.gg-arrange-back {
  box-sizing: border-box;
  position: relative;
  transform: scale(var(--ggs, 1));
  display: block;
  width: 18px;
  height: 18px;
}
.gg-arrange-back::after, .gg-arrange-back::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
}
.gg-arrange-back::after {
  width: 10px;
  height: 10px;
  border: 2px solid;
  left: 4px;
  top: 4px;
}
.gg-arrange-back::before {
  width: 8px;
  height: 8px;
  background: currentColor;
  box-shadow: 10px 10px 0;
}

.gg-arrange-front {
  box-sizing: border-box;
  position: relative;
  transform: scale(var(--ggs, 1));
  display: block;
  width: 18px;
  height: 18px;
}
.gg-arrange-front::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 10px;
  height: 10px;
  border: 2px solid;
  left: 4px;
  top: 4px;
  box-shadow: -5px -5px 0 -1px, 5px 5px 0 -1px;
}

.gg-arrow-align-h {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 20px;
  height: 10px;
  background: linear-gradient(to left, currentColor 15px, transparent 0) no-repeat 2px 4px/6px 2px, linear-gradient(to left, currentColor 15px, transparent 0) no-repeat 12px 4px/6px 2px, linear-gradient(to left, currentColor 15px, transparent 0) no-repeat center/2px 10px;
}
.gg-arrow-align-h::after, .gg-arrow-align-h::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 6px;
  height: 6px;
  transform: rotate(45deg);
  top: 2px;
}
.gg-arrow-align-h::after {
  border-bottom: 2px solid;
  border-left: 2px solid;
  left: 1px;
}
.gg-arrow-align-h::before {
  border-top: 2px solid;
  border-right: 2px solid;
  right: 1px;
}

.gg-arrow-align-v {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 10px;
  height: 20px;
  background: linear-gradient(to left, currentColor 15px, transparent 0) no-repeat 4px 2px/2px 6px, linear-gradient(to left, currentColor 15px, transparent 0) no-repeat 4px 12px/2px 6px, linear-gradient(to left, currentColor 15px, transparent 0) no-repeat center/10px 2px;
}
.gg-arrow-align-v::after, .gg-arrow-align-v::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 6px;
  height: 6px;
  transform: rotate(-45deg);
  left: 2px;
}
.gg-arrow-align-v::after {
  border-bottom: 2px solid;
  border-left: 2px solid;
  bottom: 1px;
}
.gg-arrow-align-v::before {
  border-top: 2px solid;
  border-right: 2px solid;
  top: 1px;
}

.gg-arrow-bottom-left-o {
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 22px;
  height: 22px;
  border: 2px solid;
  transform: scale(var(--ggs, 1));
  border-radius: 20px;
}
.gg-arrow-bottom-left-o::after, .gg-arrow-bottom-left-o::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  left: 4px;
}
.gg-arrow-bottom-left-o::after {
  width: 10px;
  height: 2px;
  background: currentColor;
  transform: rotate(-45deg);
  bottom: 8px;
}
.gg-arrow-bottom-left-o::before {
  width: 6px;
  height: 6px;
  bottom: 4px;
  border-bottom: 2px solid;
  border-left: 2px solid;
}

.gg-arrow-bottom-left-r {
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 22px;
  height: 22px;
  border: 2px solid;
  transform: scale(var(--ggs, 1));
  border-radius: 4px;
}
.gg-arrow-bottom-left-r::after, .gg-arrow-bottom-left-r::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  left: 4px;
}
.gg-arrow-bottom-left-r::after {
  width: 10px;
  height: 2px;
  background: currentColor;
  transform: rotate(-45deg);
  bottom: 8px;
}
.gg-arrow-bottom-left-r::before {
  width: 6px;
  height: 6px;
  bottom: 4px;
  border-bottom: 2px solid;
  border-left: 2px solid;
}

.gg-arrow-bottom-left {
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 8px;
  height: 8px;
  border-bottom: 2px solid;
  transform: scale(var(--ggs, 1));
  border-left: 2px solid;
}
.gg-arrow-bottom-left::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 14px;
  height: 2px;
  background: currentColor;
  transform: rotate(-45deg);
  bottom: 3px;
  left: -3px;
}

.gg-arrow-bottom-right-o {
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 22px;
  height: 22px;
  border: 2px solid;
  transform: scale(var(--ggs, 1));
  border-radius: 20px;
}
.gg-arrow-bottom-right-o::after, .gg-arrow-bottom-right-o::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  right: 4px;
}
.gg-arrow-bottom-right-o::after {
  width: 10px;
  height: 2px;
  background: currentColor;
  transform: rotate(45deg);
  bottom: 8px;
}
.gg-arrow-bottom-right-o::before {
  width: 6px;
  height: 6px;
  bottom: 4px;
  border-bottom: 2px solid;
  border-right: 2px solid;
}

.gg-arrow-bottom-right-r {
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 22px;
  height: 22px;
  border: 2px solid;
  transform: scale(var(--ggs, 1));
  border-radius: 4px;
}
.gg-arrow-bottom-right-r::after, .gg-arrow-bottom-right-r::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  right: 4px;
}
.gg-arrow-bottom-right-r::after {
  width: 10px;
  height: 2px;
  background: currentColor;
  transform: rotate(45deg);
  bottom: 8px;
}
.gg-arrow-bottom-right-r::before {
  width: 6px;
  height: 6px;
  bottom: 4px;
  border-bottom: 2px solid;
  border-right: 2px solid;
}

.gg-arrow-bottom-right {
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 8px;
  height: 8px;
  border-bottom: 2px solid;
  transform: scale(var(--ggs, 1));
  border-right: 2px solid;
}
.gg-arrow-bottom-right::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 14px;
  height: 2px;
  background: currentColor;
  transform: rotate(45deg);
  bottom: 3px;
  right: -3px;
}

.gg-arrow-down-o {
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 22px;
  height: 22px;
  border: 2px solid;
  transform: scale(var(--ggs, 1));
  border-radius: 20px;
}
.gg-arrow-down-o::after, .gg-arrow-down-o::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  bottom: 4px;
}
.gg-arrow-down-o::after {
  width: 6px;
  height: 6px;
  border-bottom: 2px solid;
  border-left: 2px solid;
  transform: rotate(-45deg);
  left: 6px;
}
.gg-arrow-down-o::before {
  width: 2px;
  height: 10px;
  left: 8px;
  background: currentColor;
}

.gg-arrow-down-r {
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 22px;
  height: 22px;
  border: 2px solid;
  transform: scale(var(--ggs, 1));
  border-radius: 4px;
}
.gg-arrow-down-r::after, .gg-arrow-down-r::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  bottom: 4px;
}
.gg-arrow-down-r::after {
  width: 6px;
  height: 6px;
  border-bottom: 2px solid;
  border-left: 2px solid;
  transform: rotate(-45deg);
  left: 6px;
}
.gg-arrow-down-r::before {
  width: 2px;
  height: 10px;
  left: 8px;
  background: currentColor;
}

.gg-arrow-down {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 22px;
}
.gg-arrow-down::after, .gg-arrow-down::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  bottom: 4px;
}
.gg-arrow-down::after {
  width: 8px;
  height: 8px;
  border-bottom: 2px solid;
  border-left: 2px solid;
  transform: rotate(-45deg);
  left: 7px;
}
.gg-arrow-down::before {
  width: 2px;
  height: 16px;
  left: 10px;
  background: currentColor;
}

.gg-arrow-left-o {
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 22px;
  height: 22px;
  border: 2px solid;
  transform: scale(var(--ggs, 1));
  border-radius: 22px;
}
.gg-arrow-left-o::after, .gg-arrow-left-o::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  left: 4px;
}
.gg-arrow-left-o::after {
  width: 6px;
  height: 6px;
  border-bottom: 2px solid;
  border-left: 2px solid;
  transform: rotate(45deg);
  bottom: 6px;
}
.gg-arrow-left-o::before {
  width: 10px;
  height: 2px;
  bottom: 8px;
  background: currentColor;
}

.gg-arrow-left-r {
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 22px;
  height: 22px;
  border: 2px solid;
  transform: scale(var(--ggs, 1));
  border-radius: 4px;
}
.gg-arrow-left-r::after, .gg-arrow-left-r::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  left: 4px;
}
.gg-arrow-left-r::after {
  width: 6px;
  height: 6px;
  border-bottom: 2px solid;
  border-left: 2px solid;
  transform: rotate(45deg);
  bottom: 6px;
}
.gg-arrow-left-r::before {
  width: 10px;
  height: 2px;
  bottom: 8px;
  background: currentColor;
}

.gg-arrow-left {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 22px;
}
.gg-arrow-left::after, .gg-arrow-left::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  left: 3px;
}
.gg-arrow-left::after {
  width: 8px;
  height: 8px;
  border-bottom: 2px solid;
  border-left: 2px solid;
  transform: rotate(45deg);
  bottom: 7px;
}
.gg-arrow-left::before {
  width: 16px;
  height: 2px;
  bottom: 10px;
  background: currentColor;
}

.gg-arrow-long-down-c {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  border-right: 2px solid transparent;
  border-left: 2px solid transparent;
  border-top: 4px solid transparent;
  box-shadow: inset 0 0 0 2px;
  height: 24px;
  width: 6px;
}
.gg-arrow-long-down-c::after, .gg-arrow-long-down-c::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
}
.gg-arrow-long-down-c::after {
  width: 6px;
  height: 6px;
  border-bottom: 2px solid;
  border-right: 2px solid;
  transform: rotate(45deg);
  bottom: 0;
  left: -2px;
}
.gg-arrow-long-down-c::before {
  width: 6px;
  height: 6px;
  border: 2px solid;
  border-radius: 8px;
  top: -4px;
  left: -2px;
}

.gg-arrow-long-down-e {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  border-right: 2px solid transparent;
  border-left: 2px solid transparent;
  border-top: 4px solid transparent;
  box-shadow: inset 0 0 0 2px;
  height: 24px;
  width: 6px;
}
.gg-arrow-long-down-e::after, .gg-arrow-long-down-e::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
}
.gg-arrow-long-down-e::after {
  width: 6px;
  height: 6px;
  border-bottom: 2px solid;
  border-right: 2px solid;
  transform: rotate(45deg);
  bottom: 0;
  left: -2px;
}
.gg-arrow-long-down-e::before {
  width: 6px;
  height: 6px;
  border: 2px solid;
  top: -5px;
  left: -2px;
}

.gg-arrow-long-down-l {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  border-right: 2px solid transparent;
  border-left: 2px solid transparent;
  box-shadow: inset 0 0 0 2px;
  height: 24px;
  width: 6px;
}
.gg-arrow-long-down-l::after, .gg-arrow-long-down-l::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
}
.gg-arrow-long-down-l::after {
  width: 6px;
  height: 6px;
  border-bottom: 2px solid;
  border-right: 2px solid;
  transform: rotate(45deg);
  bottom: 0;
  left: -2px;
}
.gg-arrow-long-down-l::before {
  width: 6px;
  height: 2px;
  background: currentColor;
  top: -1px;
  left: -2px;
}

.gg-arrow-long-down-r {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  border-right: 2px solid transparent;
  border-left: 2px solid transparent;
  border-top: 4px solid transparent;
  box-shadow: inset 0 0 0 2px;
  height: 24px;
  width: 6px;
}
.gg-arrow-long-down-r::after, .gg-arrow-long-down-r::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
}
.gg-arrow-long-down-r::after {
  width: 6px;
  height: 6px;
  border-bottom: 2px solid;
  border-right: 2px solid;
  transform: rotate(45deg);
  bottom: 0;
  left: -2px;
}
.gg-arrow-long-down-r::before {
  width: 6px;
  height: 6px;
  border: 2px solid;
  transform: rotate(45deg);
  top: -5px;
  left: -2px;
}

.gg-arrow-long-down {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  border-right: 2px solid transparent;
  border-left: 2px solid transparent;
  box-shadow: inset 0 0 0 2px;
  height: 24px;
  width: 6px;
}
.gg-arrow-long-down::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 6px;
  height: 6px;
  border-bottom: 2px solid;
  border-right: 2px solid;
  transform: rotate(45deg);
  bottom: 0;
  left: -2px;
}

.gg-arrow-long-left-c {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  border-top: 2px solid transparent;
  border-bottom: 2px solid transparent;
  border-right: 4px solid transparent;
  box-shadow: inset 0 0 0 2px;
  width: 24px;
  height: 6px;
}
.gg-arrow-long-left-c::after, .gg-arrow-long-left-c::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
}
.gg-arrow-long-left-c::after {
  width: 6px;
  height: 6px;
  border-bottom: 2px solid;
  border-left: 2px solid;
  transform: rotate(45deg);
  left: 0;
  bottom: -2px;
}
.gg-arrow-long-left-c::before {
  width: 6px;
  height: 6px;
  border: 2px solid;
  border-radius: 8px;
  right: -4px;
  bottom: -2px;
}

.gg-arrow-long-left-e {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  border-top: 2px solid transparent;
  border-bottom: 2px solid transparent;
  border-right: 4px solid transparent;
  box-shadow: inset 0 0 0 2px;
  width: 24px;
  height: 6px;
}
.gg-arrow-long-left-e::after, .gg-arrow-long-left-e::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
}
.gg-arrow-long-left-e::after {
  width: 6px;
  height: 6px;
  border-bottom: 2px solid;
  border-left: 2px solid;
  transform: rotate(45deg);
  left: 0;
  bottom: -2px;
}
.gg-arrow-long-left-e::before {
  width: 6px;
  height: 6px;
  border: 2px solid;
  right: -4px;
  bottom: -2px;
}

.gg-arrow-long-left-l {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  border-top: 2px solid transparent;
  border-bottom: 2px solid transparent;
  box-shadow: inset 0 0 0 2px;
  width: 24px;
  height: 6px;
}
.gg-arrow-long-left-l::after, .gg-arrow-long-left-l::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
}
.gg-arrow-long-left-l::after {
  width: 6px;
  height: 6px;
  border-bottom: 2px solid;
  border-left: 2px solid;
  transform: rotate(45deg);
  left: 0;
  bottom: -2px;
}
.gg-arrow-long-left-l::before {
  width: 2px;
  height: 6px;
  background: currentColor;
  right: -1px;
  bottom: -2px;
}

.gg-arrow-long-left-r {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  border-top: 2px solid transparent;
  border-bottom: 2px solid transparent;
  border-right: 4px solid transparent;
  box-shadow: inset 0 0 0 2px;
  width: 24px;
  height: 6px;
}
.gg-arrow-long-left-r::after, .gg-arrow-long-left-r::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
}
.gg-arrow-long-left-r::after {
  width: 6px;
  height: 6px;
  border-bottom: 2px solid;
  border-left: 2px solid;
  transform: rotate(45deg);
  left: 0;
  bottom: -2px;
}
.gg-arrow-long-left-r::before {
  width: 6px;
  height: 6px;
  border: 2px solid;
  transform: rotate(45deg);
  right: -5px;
  bottom: -2px;
}

.gg-arrow-long-left {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  border-top: 2px solid transparent;
  border-bottom: 2px solid transparent;
  box-shadow: inset 0 0 0 2px;
  width: 24px;
  height: 6px;
}
.gg-arrow-long-left::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 6px;
  height: 6px;
  border-bottom: 2px solid;
  border-left: 2px solid;
  transform: rotate(45deg);
  left: 0;
  bottom: -2px;
}

.gg-arrow-long-right-c {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  border-top: 2px solid transparent;
  border-bottom: 2px solid transparent;
  border-left: 4px solid transparent;
  box-shadow: inset 0 0 0 2px;
  width: 24px;
  height: 6px;
}
.gg-arrow-long-right-c::after, .gg-arrow-long-right-c::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
}
.gg-arrow-long-right-c::after {
  width: 6px;
  height: 6px;
  border-top: 2px solid;
  border-right: 2px solid;
  transform: rotate(45deg);
  right: 0;
  bottom: -2px;
}
.gg-arrow-long-right-c::before {
  width: 6px;
  height: 6px;
  border: 2px solid;
  border-radius: 8px;
  left: -4px;
  bottom: -2px;
}

.gg-arrow-long-right-e {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  border-top: 2px solid transparent;
  border-bottom: 2px solid transparent;
  border-left: 4px solid transparent;
  box-shadow: inset 0 0 0 2px;
  width: 24px;
  height: 6px;
}
.gg-arrow-long-right-e::after, .gg-arrow-long-right-e::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
}
.gg-arrow-long-right-e::after {
  width: 6px;
  height: 6px;
  border-top: 2px solid;
  border-right: 2px solid;
  transform: rotate(45deg);
  right: 0;
  bottom: -2px;
}
.gg-arrow-long-right-e::before {
  width: 6px;
  height: 6px;
  border: 2px solid;
  left: -4px;
  bottom: -2px;
}

.gg-arrow-long-right-l {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  border-top: 2px solid transparent;
  border-bottom: 2px solid transparent;
  box-shadow: inset 0 0 0 2px;
  width: 24px;
  height: 6px;
}
.gg-arrow-long-right-l::after, .gg-arrow-long-right-l::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
}
.gg-arrow-long-right-l::after {
  width: 6px;
  height: 6px;
  border-top: 2px solid;
  border-right: 2px solid;
  transform: rotate(45deg);
  right: 0;
  bottom: -2px;
}
.gg-arrow-long-right-l::before {
  width: 2px;
  height: 6px;
  background: currentColor;
  left: -1px;
  bottom: -2px;
}

.gg-arrow-long-right-r {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  border-top: 2px solid transparent;
  border-bottom: 2px solid transparent;
  border-left: 4px solid transparent;
  box-shadow: inset 0 0 0 2px;
  width: 24px;
  height: 6px;
}
.gg-arrow-long-right-r::after, .gg-arrow-long-right-r::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
}
.gg-arrow-long-right-r::after {
  width: 6px;
  height: 6px;
  border-top: 2px solid;
  border-right: 2px solid;
  transform: rotate(45deg);
  right: 0;
  bottom: -2px;
}
.gg-arrow-long-right-r::before {
  width: 6px;
  height: 6px;
  border: 2px solid;
  transform: rotate(45deg);
  left: -5px;
  bottom: -2px;
}

.gg-arrow-long-right {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  border-top: 2px solid transparent;
  border-bottom: 2px solid transparent;
  box-shadow: inset 0 0 0 2px;
  width: 24px;
  height: 6px;
}
.gg-arrow-long-right::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 6px;
  height: 6px;
  border-top: 2px solid;
  border-right: 2px solid;
  transform: rotate(45deg);
  right: 0;
  bottom: -2px;
}

.gg-arrow-long-up-c {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  border-right: 2px solid transparent;
  border-left: 2px solid transparent;
  border-bottom: 4px solid transparent;
  box-shadow: inset 0 0 0 2px;
  height: 24px;
  width: 6px;
}
.gg-arrow-long-up-c::after, .gg-arrow-long-up-c::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
}
.gg-arrow-long-up-c::after {
  width: 6px;
  height: 6px;
  border-top: 2px solid;
  border-left: 2px solid;
  transform: rotate(45deg);
  top: 0;
  left: -2px;
}
.gg-arrow-long-up-c::before {
  width: 6px;
  height: 6px;
  border: 2px solid;
  border-radius: 8px;
  bottom: -4px;
  left: -2px;
}

.gg-arrow-long-up-e {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  border-right: 2px solid transparent;
  border-left: 2px solid transparent;
  border-bottom: 4px solid transparent;
  box-shadow: inset 0 0 0 2px;
  height: 24px;
  width: 6px;
}
.gg-arrow-long-up-e::after, .gg-arrow-long-up-e::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
}
.gg-arrow-long-up-e::after {
  width: 6px;
  height: 6px;
  border-top: 2px solid;
  border-left: 2px solid;
  transform: rotate(45deg);
  top: 0;
  left: -2px;
}
.gg-arrow-long-up-e::before {
  width: 6px;
  height: 6px;
  border: 2px solid;
  bottom: -5px;
  left: -2px;
}

.gg-arrow-long-up-l {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  border-right: 2px solid transparent;
  border-left: 2px solid transparent;
  box-shadow: inset 0 0 0 2px;
  height: 24px;
  width: 6px;
}
.gg-arrow-long-up-l::after, .gg-arrow-long-up-l::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
}
.gg-arrow-long-up-l::after {
  width: 6px;
  height: 6px;
  border-top: 2px solid;
  border-left: 2px solid;
  transform: rotate(45deg);
  top: 0;
  left: -2px;
}
.gg-arrow-long-up-l::before {
  width: 6px;
  height: 2px;
  background: currentColor;
  bottom: -1px;
  left: -2px;
}

.gg-arrow-long-up-r {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  border-right: 2px solid transparent;
  border-left: 2px solid transparent;
  border-bottom: 4px solid transparent;
  box-shadow: inset 0 0 0 2px;
  height: 24px;
  width: 6px;
}
.gg-arrow-long-up-r::after, .gg-arrow-long-up-r::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
}
.gg-arrow-long-up-r::after {
  width: 6px;
  height: 6px;
  border-top: 2px solid;
  border-left: 2px solid;
  transform: rotate(45deg);
  top: 0;
  left: -2px;
}
.gg-arrow-long-up-r::before {
  width: 6px;
  height: 6px;
  border: 2px solid;
  transform: rotate(45deg);
  bottom: -5px;
  left: -2px;
}

.gg-arrow-long-up {
  display: block;
  box-sizing: border-box;
  width: 6px;
  position: relative;
  transform: scale(var(--ggs, 1));
  border-right: 2px solid transparent;
  border-left: 2px solid transparent;
  box-shadow: inset 0 0 0 2px;
  height: 24px;
}
.gg-arrow-long-up::after {
  display: block;
  box-sizing: border-box;
  width: 6px;
  content: "";
  position: absolute;
  height: 6px;
  border-top: 2px solid;
  border-left: 2px solid;
  transform: rotate(45deg);
  top: 0;
  left: -2px;
}

.gg-arrow-right-o {
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 22px;
  height: 22px;
  border: 2px solid;
  transform: scale(var(--ggs, 1));
  border-radius: 20px;
}
.gg-arrow-right-o::after, .gg-arrow-right-o::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  right: 4px;
}
.gg-arrow-right-o::after {
  width: 6px;
  height: 6px;
  border-top: 2px solid;
  border-right: 2px solid;
  transform: rotate(45deg);
  bottom: 6px;
}
.gg-arrow-right-o::before {
  width: 10px;
  height: 2px;
  bottom: 8px;
  background: currentColor;
}

.gg-arrow-right-r {
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 22px;
  height: 22px;
  border: 2px solid;
  transform: scale(var(--ggs, 1));
  border-radius: 4px;
}
.gg-arrow-right-r::after, .gg-arrow-right-r::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  right: 4px;
}
.gg-arrow-right-r::after {
  width: 6px;
  height: 6px;
  border-top: 2px solid;
  border-right: 2px solid;
  transform: rotate(45deg);
  bottom: 6px;
}
.gg-arrow-right-r::before {
  width: 10px;
  height: 2px;
  bottom: 8px;
  background: currentColor;
}

.gg-arrow-right {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 22px;
}
.gg-arrow-right::after, .gg-arrow-right::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  right: 3px;
}
.gg-arrow-right::after {
  width: 8px;
  height: 8px;
  border-top: 2px solid;
  border-right: 2px solid;
  transform: rotate(45deg);
  bottom: 7px;
}
.gg-arrow-right::before {
  width: 16px;
  height: 2px;
  bottom: 10px;
  background: currentColor;
}

.gg-arrow-top-left-o {
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 22px;
  height: 22px;
  border: 2px solid;
  transform: scale(var(--ggs, 1));
  border-radius: 20px;
}
.gg-arrow-top-left-o::after, .gg-arrow-top-left-o::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
}
.gg-arrow-top-left-o::after {
  width: 10px;
  height: 2px;
  background: currentColor;
  transform: rotate(45deg);
  bottom: 8px;
  right: 4px;
}
.gg-arrow-top-left-o::before {
  width: 6px;
  height: 6px;
  left: 4px;
  top: 4px;
  border-top: 2px solid;
  border-left: 2px solid;
}

.gg-arrow-top-left-r {
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 22px;
  height: 22px;
  border: 2px solid;
  transform: scale(var(--ggs, 1));
  border-radius: 4px;
}
.gg-arrow-top-left-r::after, .gg-arrow-top-left-r::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  left: 4px;
}
.gg-arrow-top-left-r::after {
  width: 10px;
  height: 2px;
  background: currentColor;
  transform: rotate(45deg);
  bottom: 8px;
}
.gg-arrow-top-left-r::before {
  width: 6px;
  height: 6px;
  top: 4px;
  border-top: 2px solid;
  border-left: 2px solid;
}

.gg-arrow-top-left {
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 8px;
  height: 8px;
  transform: scale(var(--ggs, 1));
  border-top: 2px solid;
  border-left: 2px solid;
}
.gg-arrow-top-left::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 14px;
  height: 2px;
  background: currentColor;
  transform: rotate(45deg);
  top: 3px;
  left: -3px;
}

.gg-arrow-top-right-o {
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 22px;
  height: 22px;
  border: 2px solid;
  transform: scale(var(--ggs, 1));
  border-radius: 20px;
}
.gg-arrow-top-right-o::after, .gg-arrow-top-right-o::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  right: 4px;
}
.gg-arrow-top-right-o::after {
  width: 10px;
  height: 2px;
  background: currentColor;
  transform: rotate(-45deg);
  bottom: 8px;
}
.gg-arrow-top-right-o::before {
  width: 6px;
  height: 6px;
  top: 4px;
  border-top: 2px solid;
  border-right: 2px solid;
}

.gg-arrow-top-right-r {
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 22px;
  height: 22px;
  border: 2px solid;
  transform: scale(var(--ggs, 1));
  border-radius: 4px;
}
.gg-arrow-top-right-r::after, .gg-arrow-top-right-r::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  right: 4px;
}
.gg-arrow-top-right-r::after {
  width: 10px;
  height: 2px;
  background: currentColor;
  transform: rotate(-45deg);
  bottom: 8px;
}
.gg-arrow-top-right-r::before {
  width: 6px;
  height: 6px;
  top: 4px;
  border-top: 2px solid;
  border-right: 2px solid;
}

.gg-arrow-top-right {
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 8px;
  height: 8px;
  border-top: 2px solid;
  transform: scale(var(--ggs, 1));
  border-right: 2px solid;
}
.gg-arrow-top-right::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 14px;
  height: 2px;
  background: currentColor;
  transform: rotate(-45deg);
  top: 3px;
  right: -3px;
}

.gg-arrow-up-o {
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 22px;
  height: 22px;
  border: 2px solid;
  transform: scale(var(--ggs, 1));
  border-radius: 20px;
}
.gg-arrow-up-o::after, .gg-arrow-up-o::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  top: 4px;
}
.gg-arrow-up-o::after {
  width: 6px;
  height: 6px;
  border-top: 2px solid;
  border-left: 2px solid;
  transform: rotate(45deg);
  left: 6px;
}
.gg-arrow-up-o::before {
  width: 2px;
  height: 10px;
  left: 8px;
  background: currentColor;
}

.gg-arrow-up-r {
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 22px;
  height: 22px;
  border: 2px solid;
  transform: scale(var(--ggs, 1));
  border-radius: 4px;
}
.gg-arrow-up-r::after, .gg-arrow-up-r::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  top: 4px;
}
.gg-arrow-up-r::after {
  width: 6px;
  height: 6px;
  border-top: 2px solid;
  border-left: 2px solid;
  transform: rotate(45deg);
  left: 6px;
}
.gg-arrow-up-r::before {
  width: 2px;
  height: 10px;
  left: 8px;
  background: currentColor;
}

.gg-arrow-up {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 22px;
}
.gg-arrow-up::after, .gg-arrow-up::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  top: 4px;
}
.gg-arrow-up::after {
  width: 8px;
  height: 8px;
  border-top: 2px solid;
  border-left: 2px solid;
  transform: rotate(45deg);
  left: 7px;
}
.gg-arrow-up::before {
  width: 2px;
  height: 16px;
  left: 10px;
  background: currentColor;
}

.gg-arrows-breake-h {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 20px;
  height: 10px;
  background: linear-gradient(to left, currentColor 10px, transparent 0) no-repeat center left/8px 2px, linear-gradient(to left, currentColor 10px, transparent 0) no-repeat center right/8px 2px, linear-gradient(to left, currentColor 10px, transparent 0) no-repeat 7px center/2px 10px, linear-gradient(to left, currentColor 10px, transparent 0) no-repeat 11px center/2px 10px;
}
.gg-arrows-breake-h::after, .gg-arrows-breake-h::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 6px;
  height: 6px;
  transform: rotate(45deg);
  top: 2px;
}
.gg-arrows-breake-h::after {
  border-left: 2px solid;
  border-bottom: 2px solid;
}
.gg-arrows-breake-h::before {
  border-right: 2px solid;
  border-top: 2px solid;
  right: 0;
}

.gg-arrows-breake-v {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 10px;
  height: 20px;
  background: linear-gradient(to left, currentColor 10px, transparent 0) no-repeat center top/2px 8px, linear-gradient(to left, currentColor 10px, transparent 0) no-repeat center bottom/2px 8px, linear-gradient(to left, currentColor 10px, transparent 0) no-repeat center 7px/10px 2px, linear-gradient(to left, currentColor 10px, transparent 0) no-repeat center 11px/10px 2px;
}
.gg-arrows-breake-v::after, .gg-arrows-breake-v::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 6px;
  height: 6px;
  transform: rotate(45deg);
  left: 2px;
}
.gg-arrows-breake-v::after {
  border-left: 2px solid;
  border-top: 2px solid;
}
.gg-arrows-breake-v::before {
  border-right: 2px solid;
  border-bottom: 2px solid;
  bottom: 0;
}

.gg-arrows-exchange-alt-v {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 14px;
  height: 16px;
}
.gg-arrows-exchange-alt-v::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 2px;
  background: currentColor;
  border-radius: 4px;
}
.gg-arrows-exchange-alt-v::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 2px;
  background: currentColor;
  border-radius: 4px;
  height: 6px;
  transform: rotate(-45deg);
  box-shadow: 13px -2px 0;
  top: 10px;
  left: 2px;
}
.gg-arrows-exchange-alt-v::after {
  height: 12px;
  box-shadow: -4px 4px 0;
  right: 4px;
}

.gg-arrows-exchange-alt {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 16px;
  height: 14px;
}
.gg-arrows-exchange-alt::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  height: 2px;
  background: currentColor;
  border-radius: 4px;
}
.gg-arrows-exchange-alt::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  height: 2px;
  background: currentColor;
  border-radius: 4px;
  width: 6px;
  transform: rotate(-45deg);
  box-shadow: -2px -13px 0;
  right: 0;
  top: 10px;
}
.gg-arrows-exchange-alt::after {
  width: 12px;
  box-shadow: 4px 4px 0;
  top: 4px;
}

.gg-arrows-exchange-v {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 14px;
  height: 16px;
}
.gg-arrows-exchange-v::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 2px;
  background: currentColor;
  border-radius: 4px;
}
.gg-arrows-exchange-v::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 2px;
  background: currentColor;
  border-radius: 4px;
  height: 6px;
  transform: rotate(45deg);
  box-shadow: 7px -4px 0;
  top: 4px;
  left: 2px;
}
.gg-arrows-exchange-v::after {
  height: 12px;
  box-shadow: -4px 4px 0;
  right: 4px;
  bottom: 4px;
}

.gg-arrows-exchange {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 16px;
  height: 14px;
}
.gg-arrows-exchange::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  height: 2px;
  background: currentColor;
  border-radius: 4px;
}
.gg-arrows-exchange::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  height: 2px;
  background: currentColor;
  border-radius: 4px;
  width: 6px;
  transform: rotate(45deg);
  box-shadow: 2px -13px 0;
  top: 10px;
}
.gg-arrows-exchange::after {
  width: 12px;
  box-shadow: -4px 4px 0;
  left: 4px;
  top: 4px;
}

.gg-arrows-expand-down-left {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 16px;
  height: 16px;
  box-shadow: -6px 6px 0 -4px;
}
.gg-arrows-expand-down-left::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 8px;
  height: 8px;
  border: 2px solid;
  border-radius: 1px;
  right: 0;
}
.gg-arrows-expand-down-left::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  background: currentColor;
  width: 2px;
  height: 10px;
  transform: rotate(45deg);
  top: 8px;
  left: 2px;
  border-radius: 4px;
}

.gg-arrows-expand-down-right {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 16px;
  height: 16px;
  box-shadow: 6px 6px 0 -4px;
}
.gg-arrows-expand-down-right::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 8px;
  height: 8px;
  border: 2px solid;
  border-radius: 1px;
  left: 0;
}
.gg-arrows-expand-down-right::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  background: currentColor;
  width: 2px;
  height: 10px;
  transform: rotate(-45deg);
  top: 8px;
  right: 2px;
  border-radius: 4px;
}

.gg-arrows-expand-left-alt {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 14px;
  height: 14px;
  margin: 2px;
  box-shadow: 6px 6px 0 -4px, -6px -6px 0 -4px;
}
.gg-arrows-expand-left-alt::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  transform: rotate(-45deg);
}
.gg-arrows-expand-left-alt::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  transform: rotate(-45deg);
  width: 2px;
  height: 22px;
  top: -4px;
  left: 6px;
  border-top: 9px solid;
  border-bottom: 9px solid;
}
.gg-arrows-expand-left-alt::after {
  width: 8px;
  height: 2px;
  background: currentColor;
  top: 6px;
  left: 3px;
}

.gg-arrows-expand-left {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 14px;
  height: 14px;
  box-shadow: 6px 6px 0 -4px, -6px -6px 0 -4px;
}
.gg-arrows-expand-left::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 2px;
  height: 22px;
  top: -4px;
  left: 6px;
  transform: rotate(-45deg);
  border-top: 9px solid;
  border-bottom: 9px solid;
}

.gg-arrows-expand-right-alt {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 14px;
  height: 14px;
  margin: 2px;
  box-shadow: -6px 6px 0 -4px, 6px -6px 0 -4px;
}
.gg-arrows-expand-right-alt::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  transform: rotate(45deg);
}
.gg-arrows-expand-right-alt::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  transform: rotate(45deg);
  width: 2px;
  height: 22px;
  top: -4px;
  left: 6px;
  border-top: 9px solid;
  border-bottom: 9px solid;
}
.gg-arrows-expand-right-alt::after {
  width: 8px;
  height: 2px;
  background: currentColor;
  top: 6px;
  left: 3px;
}

.gg-arrows-expand-right {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 14px;
  height: 14px;
  margin: 2px;
  box-shadow: -6px 6px 0 -4px, 6px -6px 0 -4px;
}
.gg-arrows-expand-right::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 2px;
  height: 22px;
  top: -4px;
  left: 6px;
  transform: rotate(45deg);
  border-top: 9px solid;
  border-bottom: 9px solid;
}

.gg-arrows-expand-up-left {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 16px;
  height: 16px;
  box-shadow: -6px -6px 0 -4px;
}
.gg-arrows-expand-up-left::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 8px;
  height: 8px;
  border: 2px solid;
  border-radius: 1px;
  right: 0;
  bottom: 0;
}
.gg-arrows-expand-up-left::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  background: currentColor;
  width: 2px;
  height: 10px;
  transform: rotate(-45deg);
  bottom: 8px;
  left: 2px;
  border-radius: 4px;
}

.gg-arrows-expand-up-right {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 16px;
  height: 16px;
  box-shadow: 6px -6px 0 -4px;
}
.gg-arrows-expand-up-right::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 8px;
  height: 8px;
  border: 2px solid;
  border-radius: 1px;
  left: 0;
  bottom: 0;
}
.gg-arrows-expand-up-right::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  background: currentColor;
  width: 2px;
  height: 10px;
  transform: rotate(45deg);
  bottom: 8px;
  right: 2px;
  border-radius: 4px;
}

.gg-arrows-h-alt {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 20px;
  height: 10px;
  background: linear-gradient(to left, currentColor 18px, transparent 0) no-repeat 2px 4px/16px 2px;
}
.gg-arrows-h-alt::after, .gg-arrows-h-alt::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 6px;
  height: 6px;
  transform: rotate(45deg);
  top: 2px;
}
.gg-arrows-h-alt::after {
  border-bottom: 2px solid;
  border-left: 2px solid;
  left: 1px;
}
.gg-arrows-h-alt::before {
  border-top: 2px solid;
  border-right: 2px solid;
  right: 1px;
}

.gg-arrows-h {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 20px;
  height: 10px;
  background: linear-gradient(to left, currentColor 15px, transparent 0) no-repeat 2px 4px/6px 2px, linear-gradient(to left, currentColor 15px, transparent 0) no-repeat 12px 4px/6px 2px;
}
.gg-arrows-h::after, .gg-arrows-h::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 6px;
  height: 6px;
  transform: rotate(45deg);
  top: 2px;
}
.gg-arrows-h::after {
  border-bottom: 2px solid;
  border-left: 2px solid;
  left: 1px;
}
.gg-arrows-h::before {
  border-top: 2px solid;
  border-right: 2px solid;
  right: 1px;
}

.gg-arrows-merge-alt-h {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 12px;
  background: linear-gradient(to left, currentColor 12px, transparent 0) no-repeat center left/8px 2px, linear-gradient(to left, currentColor 12px, transparent 0) no-repeat center right/8px 2px, linear-gradient(to left, currentColor 12px, transparent 0) no-repeat 20px center/2px 12px, linear-gradient(to left, currentColor 12px, transparent 0) no-repeat 0 center/2px 12px;
}
.gg-arrows-merge-alt-h::after, .gg-arrows-merge-alt-h::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 6px;
  height: 6px;
  transform: rotate(45deg);
  top: 3px;
}
.gg-arrows-merge-alt-h::after {
  border-left: 2px solid;
  border-bottom: 2px solid;
  right: 3px;
}
.gg-arrows-merge-alt-h::before {
  border-right: 2px solid;
  border-top: 2px solid;
  left: 3px;
}

.gg-arrows-merge-alt-v {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 12px;
  height: 22px;
  background: linear-gradient(to left, currentColor 10px, transparent 0) no-repeat center top/2px 8px, linear-gradient(to left, currentColor 10px, transparent 0) no-repeat center bottom/2px 8px, linear-gradient(to left, currentColor 10px, transparent 0) no-repeat center 20px/10px 2px, linear-gradient(to left, currentColor 10px, transparent 0) no-repeat center 0/10px 2px;
}
.gg-arrows-merge-alt-v::after, .gg-arrows-merge-alt-v::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 6px;
  height: 6px;
  transform: rotate(45deg);
  left: 3px;
}
.gg-arrows-merge-alt-v::after {
  border-left: 2px solid;
  border-top: 2px solid;
  bottom: 3px;
}
.gg-arrows-merge-alt-v::before {
  border-right: 2px solid;
  border-bottom: 2px solid;
  top: 3px;
}

.gg-arrows-scroll-h {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 16px;
  height: 6px;
}
.gg-arrows-scroll-h::after, .gg-arrows-scroll-h::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 6px;
  height: 6px;
  transform: rotate(45deg);
}
.gg-arrows-scroll-h::after {
  border-bottom: 2px solid;
  border-left: 2px solid;
  right: 0;
}
.gg-arrows-scroll-h::before {
  border-top: 2px solid;
  border-right: 2px solid;
  left: 0;
}

.gg-arrows-scroll-v {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 6px;
  height: 16px;
}
.gg-arrows-scroll-v::after, .gg-arrows-scroll-v::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 6px;
  height: 6px;
  transform: rotate(-45deg);
}
.gg-arrows-scroll-v::after {
  border-bottom: 2px solid;
  border-left: 2px solid;
}
.gg-arrows-scroll-v::before {
  border-top: 2px solid;
  border-right: 2px solid;
  bottom: 0;
}

.gg-arrows-shrink-h {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 20px;
  height: 10px;
  background: linear-gradient(to left, currentColor 10px, transparent 0) no-repeat center center/10px 2px, linear-gradient(to left, currentColor 10px, transparent 0) no-repeat 18px center/2px 10px, linear-gradient(to left, currentColor 10px, transparent 0) no-repeat 0px center/2px 10px;
}
.gg-arrows-shrink-h::after, .gg-arrows-shrink-h::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 6px;
  height: 6px;
  transform: rotate(45deg);
  top: 2px;
}
.gg-arrows-shrink-h::after {
  border-left: 2px solid;
  border-bottom: 2px solid;
  left: 4px;
}
.gg-arrows-shrink-h::before {
  border-right: 2px solid;
  border-top: 2px solid;
  right: 4px;
}

.gg-arrows-shrink-v {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 10px;
  height: 20px;
  background: linear-gradient(to left, currentColor 10px, transparent 0) no-repeat center center/2px 10px, linear-gradient(to left, currentColor 10px, transparent 0) no-repeat center 18px/10px 2px, linear-gradient(to left, currentColor 10px, transparent 0) no-repeat center 0/10px 2px;
}
.gg-arrows-shrink-v::after, .gg-arrows-shrink-v::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 6px;
  height: 6px;
  transform: rotate(45deg);
  left: 2px;
}
.gg-arrows-shrink-v::after {
  border-left: 2px solid;
  border-top: 2px solid;
  top: 4px;
}
.gg-arrows-shrink-v::before {
  border-right: 2px solid;
  border-bottom: 2px solid;
  bottom: 4px;
}

.gg-arrows-v-alt {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 10px;
  height: 20px;
  background: linear-gradient(to left, currentColor 16px, transparent 0) no-repeat 4px 2px/2px 16px;
}
.gg-arrows-v-alt::after, .gg-arrows-v-alt::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 6px;
  height: 6px;
  transform: rotate(-45deg);
  left: 2px;
}
.gg-arrows-v-alt::after {
  border-bottom: 2px solid;
  border-left: 2px solid;
  bottom: 1px;
}
.gg-arrows-v-alt::before {
  border-top: 2px solid;
  border-right: 2px solid;
  top: 1px;
}

.gg-arrows-v {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 10px;
  height: 20px;
  background: linear-gradient(to left, currentColor 15px, transparent 0) no-repeat 4px 2px/2px 6px, linear-gradient(to left, currentColor 15px, transparent 0) no-repeat 4px 12px/2px 6px;
}
.gg-arrows-v::after, .gg-arrows-v::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 6px;
  height: 6px;
  transform: rotate(-45deg);
  left: 2px;
}
.gg-arrows-v::after {
  border-bottom: 2px solid;
  border-left: 2px solid;
  bottom: 1px;
}
.gg-arrows-v::before {
  border-top: 2px solid;
  border-right: 2px solid;
  top: 1px;
}

.gg-assign {
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 6px;
  height: 6px;
  background: currentColor;
  transform: scale(var(--ggs, 1));
  border-radius: 100px;
}
.gg-assign::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 12px;
  height: 12px;
  box-shadow: -5px -5px 0 -3px, 5px 5px 0 -3px, 5px -5px 0 -3px, -5px 5px 0 -3px;
  left: -3px;
  top: -3px;
}

.gg-asterisk {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 12px;
  height: 12px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  box-shadow: inset 0 0 0 2px;
}
.gg-asterisk::after, .gg-asterisk::before {
  content: "";
  display: block;
  position: absolute;
  box-sizing: border-box;
  width: 2px;
  height: 12px;
  background: currentColor;
  transform: rotate(55deg);
}
.gg-asterisk::after {
  transform: rotate(-55deg);
}

.gg-atlasian {
  position: relative;
  box-sizing: border-box;
  display: block;
  width: 22px;
  height: 18px;
  transform: scale(var(--ggs, 1));
  overflow: hidden;
}
.gg-atlasian::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 6px;
  background: currentColor;
  border-radius: 4px;
}
.gg-atlasian::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 6px;
  background: currentColor;
  border-radius: 4px;
  height: 12px;
  border-top-right-radius: 10px;
  bottom: -4px;
  left: 3px;
  transform: rotate(30deg) skewY(35deg);
  opacity: 0.8;
}
.gg-atlasian::after {
  height: 24px;
  border-top-left-radius: 10px;
  bottom: -8px;
  right: 3px;
  transform: rotate(-30deg) skewY(-35deg);
}

.gg-attachment {
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 14px;
  height: 14px;
  border: 2px solid;
  border-top: 0;
  border-bottom-left-radius: 100px;
  border-bottom-right-radius: 100px;
  transform: scale(var(--ggs, 1));
  margin-top: 11px;
}
.gg-attachment::after, .gg-attachment::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border-radius: 3px;
  border: 2px solid;
}
.gg-attachment::after {
  border-bottom: 0;
  border-top-left-radius: 100px;
  border-top-right-radius: 100px;
  right: -2px;
  width: 10px;
  height: 14px;
  bottom: 8px;
}
.gg-attachment::before {
  width: 6px;
  height: 12px;
  border-top: 0;
  border-bottom-left-radius: 100px;
  border-bottom-right-radius: 100px;
  left: 2px;
  bottom: 4px;
}

.gg-attribution {
  display: block;
  box-sizing: border-box;
  transform: scale(var(--ggs, 1));
  width: 16px;
  height: 18px;
  background: linear-gradient(to left, currentColor 14px, transparent 0) no-repeat 1px 2px/8px 2px, linear-gradient(to left, currentColor 14px, transparent 0) no-repeat 6px 14px/6px 2px, radial-gradient(circle, currentColor 60%, transparent 40%) no-repeat 10px 12px/6px 6px, radial-gradient(circle, currentColor 60%, transparent 40%) no-repeat 0 0/6px 6px;
}
.gg-attribution::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 8px;
  height: 8px;
  border: 2px solid;
}
.gg-attribution::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 8px;
  height: 8px;
  border: 2px solid;
  border-right: 0;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  top: 8px;
  left: 2px;
}
.gg-attribution::after {
  border-left: 0;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  right: 2px;
  top: 2px;
}

.gg-awards {
  display: block;
  position: relative;
  transform: scale(var(--ggs, 1));
  box-sizing: border-box;
  width: 16px;
  height: 20px;
  border: 4px solid transparent;
  border-top: 12px solid transparent;
  border-bottom: 0;
  box-shadow: inset 4px 0 0 -2px, inset -4px 0 0 -2px;
}
.gg-awards::after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  display: block;
  border: 2px solid;
}
.gg-awards::before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  display: block;
  border: 2px solid;
  width: 14px;
  height: 14px;
  border-radius: 22px;
  top: -12px;
  left: -3px;
}
.gg-awards::after {
  width: 6px;
  height: 6px;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-bottom-left-radius: 1px;
  border-top-right-radius: 1px;
  transform: rotate(45deg);
  bottom: -3px;
  left: 1px;
}

.gg-backspace {
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 14px;
  height: 14px;
  transform: scale(var(--ggs, 1));
  border: 2px solid;
  border-left: 0;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}
.gg-backspace::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
}
.gg-backspace::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  background: linear-gradient(to left, currentColor 18px, transparent 0) no-repeat center center/10px 2px;
  border-right: 3px solid transparent;
  box-shadow: inset 0 0 0 2px;
  right: 2px;
  bottom: 1px;
  width: 8px;
  height: 8px;
  border-left: 3px solid transparent;
  transform: rotate(45deg);
}
.gg-backspace::after {
  width: 10px;
  height: 10px;
  border-top: 2px solid;
  border-left: 2px solid;
  border-top-left-radius: 1px;
  transform: rotate(-45deg);
  top: 0;
  left: -5px;
}

.gg-band-aid {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: rotate(-25deg) scale(var(--ggs, 1));
  width: 24px;
  height: 12px;
  border: 2px solid;
  border-radius: 22px;
}
.gg-band-aid::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
}
.gg-band-aid::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 12px;
  height: 10px;
  top: -2px;
  left: 4px;
  border-left: 2px solid;
  border-right: 2px solid;
}
.gg-band-aid::after {
  width: 2px;
  height: 2px;
  background: currentColor;
  box-shadow: 0 4px 0, 4px 0 0, 4px 4px 0;
  border-radius: 22px;
  left: 7px;
  top: 1px;
}

.gg-battery-empty {
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 20px;
  height: 12px;
  transform: scale(var(--ggs, 1));
  border: 2px solid;
  border-radius: 3px;
  margin-left: -3px;
}
.gg-battery-empty::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border-radius: 3px;
  width: 4px;
  height: 6px;
  right: -4px;
  top: 1px;
  background: currentColor;
}

.gg-battery-full {
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 20px;
  height: 12px;
  transform: scale(var(--ggs, 1));
  border: 2px solid;
  border-radius: 3px;
  margin-left: -3px;
}
.gg-battery-full::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  height: 6px;
  background: currentColor;
  top: 1px;
}
.gg-battery-full::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  height: 6px;
  background: currentColor;
  top: 1px;
  right: -4px;
  border-radius: 3px;
  width: 4px;
}
.gg-battery-full::after {
  width: 14px;
  left: 1px;
}

.gg-battery {
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 20px;
  height: 12px;
  transform: scale(var(--ggs, 1));
  border: 2px solid;
  border-radius: 3px;
  margin-left: -3px;
}
.gg-battery::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  height: 6px;
  background: currentColor;
  top: 1px;
}
.gg-battery::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  height: 6px;
  background: currentColor;
  top: 1px;
  right: -4px;
  border-radius: 3px;
  width: 4px;
}
.gg-battery::after {
  width: 8px;
  left: 1px;
}

.gg-bee {
  display: block;
  box-sizing: border-box;
  border: 2px solid;
  position: relative;
  width: 12px;
  height: 20px;
  transform: scale(var(--ggs, 1));
  border-radius: 22px;
  background: linear-gradient(to left, currentColor 12px, transparent 0) no-repeat center 6px/12px 2px, linear-gradient(to left, currentColor 12px, transparent 0) no-repeat center 10px/12px 2px;
  box-shadow: 0 4px 0 -2px, 0 7px 0 -4px;
}
.gg-bee::after, .gg-bee::before {
  display: block;
  box-sizing: border-box;
  border: 2px solid;
}
.gg-bee::after {
  content: "";
  position: absolute;
  width: 6px;
  height: 10px;
  border-top: 0;
  border-bottom-left-radius: 22px;
  border-bottom-right-radius: 22px;
  top: 4px;
}
.gg-bee::before {
  content: "";
  position: absolute;
  width: 6px;
  height: 10px;
  border-top: 0;
  border-bottom-left-radius: 22px;
  border-bottom-right-radius: 22px;
  top: 4px;
  left: -6px;
  transform: rotate(40deg);
}
.gg-bee::after {
  right: -6px;
  transform: rotate(-40deg);
}

.gg-bell {
  border-top-left-radius: 100px;
  border-top-right-radius: 100px;
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  border: 2px solid;
  border-bottom: 0;
  width: 14px;
  height: 14px;
}
.gg-bell::before {
  border-top-left-radius: 100px;
  border-top-right-radius: 100px;
}
.gg-bell::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
}
.gg-bell::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  background: currentColor;
  width: 4px;
  height: 4px;
  top: -4px;
  left: 3px;
}
.gg-bell::after {
  border-radius: 3px;
  width: 16px;
  height: 10px;
  border: 6px solid transparent;
  border-top: 1px solid transparent;
  box-shadow: inset 0 0 0 4px, 0 -2px 0 0;
  top: 14px;
  left: -3px;
  border-bottom-left-radius: 100px;
  border-bottom-right-radius: 100px;
}

.gg-bitbucket {
  position: relative;
  box-sizing: border-box;
  display: block;
  width: 15px;
  height: 15px;
  transform: scale(var(--ggs, 1));
  border-top: 5px solid;
  background: linear-gradient(to left, currentColor 10px, transparent 0) no-repeat center bottom/10px 5px;
}
.gg-bitbucket::after, .gg-bitbucket::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  background: currentColor;
  width: 5px;
  height: 15px;
  top: -5px;
  transform: skew(10deg);
  border-radius: 1px;
}
.gg-bitbucket::after {
  right: 0;
  transform: skew(-10deg);
}

.gg-block {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 16px;
  height: 16px;
  border: 2px solid;
  border-radius: 100%;
}
.gg-block::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 10px;
  height: 2px;
  background: currentColor;
  border-radius: 5px;
  transform: rotate(-45deg);
  top: 5px;
  left: 1px;
}

.gg-bmw {
  position: relative;
  box-sizing: border-box;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 18px;
  height: 18px;
  border: 2px solid;
  border-radius: 18px;
}
.gg-bmw::before {
  content: "";
  position: absolute;
  box-sizing: border-box;
  display: block;
  border: 9px solid;
  border-top-color: transparent;
  border-bottom-color: transparent;
  border-radius: 18px;
  transform: rotate(45deg);
  left: -2px;
  top: -2px;
}

.gg-board {
  display: block;
  position: relative;
  box-sizing: border-box;
  transform: scale(var(--ggs, 1));
  width: 16px;
  height: 12px;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  box-shadow: 0 0 0 2px, inset 2px 0 0, inset -2px 0 0;
  border-radius: 2px;
}

.gg-bolt {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 6px;
  height: 21px;
}
.gg-bolt::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 0;
  height: 0;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  transform: rotate(30deg);
}
.gg-bolt::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 0;
  height: 0;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  transform: rotate(30deg);
  border-bottom: 12px solid;
  left: 0;
  top: 0;
}
.gg-bolt::after {
  border-top: 12px solid;
  right: 0;
  bottom: 0;
}

.gg-bookmark {
  display: block;
  box-sizing: border-box;
  border-top-right-radius: 3px;
  border: 2px solid;
  border-bottom: 0;
  border-top-left-radius: 3px;
  overflow: hidden;
  position: relative;
  transform: scale(var(--ggs, 1));
  width: 14px;
  height: 16px;
}
.gg-bookmark::after {
  display: block;
  box-sizing: border-box;
  border-top-right-radius: 3px;
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  border-top: 2px solid;
  border-right: 2px solid;
  transform: rotate(-45deg);
  top: 9px;
  left: -1px;
}

.gg-border-all {
  transform: scale(var(--ggs, 1));
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 14px;
  height: 14px;
}
.gg-border-all::before {
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 14px;
  height: 14px;
  content: "";
  position: absolute;
  border: 3px solid;
  bottom: 0;
  left: 0;
}

.gg-border-bottom {
  transform: scale(var(--ggs, 1));
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 14px;
  height: 14px;
  border-bottom: 3px solid;
}
.gg-border-bottom::before {
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 14px;
  height: 14px;
  border-bottom: 3px solid;
  content: "";
  position: absolute;
  height: 10px;
  border: 3px solid;
  border-bottom: 0;
  bottom: 2px;
  left: 0;
  opacity: 0.3;
}

.gg-border-left {
  transform: scale(var(--ggs, 1));
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 14px;
  height: 14px;
  border-left: 3px solid;
}
.gg-border-left::before {
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 14px;
  height: 14px;
  border-left: 3px solid;
  content: "";
  position: absolute;
  width: 10px;
  border: 3px solid;
  border-left: 0;
  bottom: 0;
  left: 2px;
  opacity: 0.3;
}

.gg-border-right {
  transform: scale(var(--ggs, 1));
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 14px;
  height: 14px;
  border-right: 3px solid;
}
.gg-border-right::before {
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 14px;
  height: 14px;
  border-right: 3px solid;
  content: "";
  position: absolute;
  width: 10px;
  border: 3px solid;
  border-right: 0;
  bottom: 0;
  right: 2px;
  opacity: 0.3;
}

.gg-border-style-dashed {
  transform: scale(var(--ggs, 1));
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 26px;
  height: 17px;
}
.gg-border-style-dashed::before {
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 26px;
  height: 17px;
  content: "";
  position: absolute;
  height: 2px;
  top: 7px;
  border-top: 2px dashed;
}

.gg-border-style-dotted {
  transform: scale(var(--ggs, 1));
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 26px;
  height: 17px;
}
.gg-border-style-dotted::before {
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 26px;
  height: 17px;
  content: "";
  position: absolute;
  height: 2px;
  top: 7px;
  border-top: 2px dotted;
}

.gg-border-style-solid {
  transform: scale(var(--ggs, 1));
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 26px;
  height: 17px;
}
.gg-border-style-solid::before {
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 26px;
  height: 17px;
  content: "";
  position: absolute;
  height: 2px;
  top: 7px;
  border-top: 2px solid;
}

.gg-border-top {
  transform: scale(var(--ggs, 1));
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 14px;
  height: 14px;
  border-top: 3px solid;
}
.gg-border-top::before {
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 14px;
  height: 14px;
  border-top: 3px solid;
  content: "";
  position: absolute;
  height: 10px;
  border: 3px solid;
  border-top: 0;
  top: 2px;
  left: 0;
  opacity: 0.3;
}

.gg-bot {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 21px;
  height: 10px;
  border-right: 8px solid transparent;
  border-left: 8px solid transparent;
  border-bottom: 1px solid transparent;
  border-radius: 12px;
  box-shadow: 0 0 0 2px, -4px 0 0 -1px, 4px 0 0 -1px, inset 0 -4px 0 -2px;
}
.gg-bot::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 2px;
  height: 2px;
  box-shadow: 0 0 0 2px;
  top: 3px;
}
.gg-bot::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 2px;
  height: 2px;
  box-shadow: 0 0 0 2px;
  top: 3px;
  left: -4px;
  border-radius: 3px;
}
.gg-bot::after {
  border-radius: 100px;
  right: -4px;
}

.gg-bowl {
  display: block;
  position: relative;
  transform: scale(var(--ggs, 1));
  box-sizing: border-box;
  width: 20px;
  height: 18px;
}
.gg-bowl::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
}
.gg-bowl::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 20px;
  height: 12px;
  border: 2px solid;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  bottom: 0;
}
.gg-bowl::after {
  background: currentColor;
  width: 2px;
  height: 10px;
  border-radius: 4px;
  transform: rotate(45deg);
  right: 4px;
  top: -1px;
}

.gg-box {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 20px;
  border: 2px solid;
  border-radius: 3px;
}
.gg-box::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border-radius: 3px;
  height: 2px;
  background: currentColor;
}
.gg-box::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border-radius: 3px;
  height: 2px;
  background: currentColor;
  left: 6px;
  width: 6px;
  top: 8px;
}
.gg-box::after {
  width: 22px;
  left: -2px;
  top: 4px;
}

.gg-boy {
  display: block;
  box-sizing: border-box;
  border-radius: 42px;
  position: relative;
  width: 20px;
  height: 20px;
  transform: scale(var(--ggs, 1));
  overflow: hidden;
  box-shadow: inset 0 0 0 2px;
}
.gg-boy::after, .gg-boy::before {
  display: block;
  box-sizing: border-box;
  border-radius: 42px;
}
.gg-boy::after, .gg-boy::before {
  content: "";
  position: absolute;
  width: 2px;
  height: 2px;
  background: currentColor;
  box-shadow: 6px 0 0;
  left: 6px;
  top: 10px;
}
.gg-boy::after {
  width: 20px;
  height: 20px;
  top: -13px;
  right: -12px;
}

.gg-brackets {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 18px;
  height: 18px;
  border: 2px solid;
  border-radius: 3px;
}
.gg-brackets::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border: 2px solid;
  width: 4px;
  height: 10px;
  top: 2px;
}
.gg-brackets::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border: 2px solid;
  width: 4px;
  height: 10px;
  top: 2px;
  border-right: 0;
  left: 2px;
}
.gg-brackets::after {
  border-left: 0;
  right: 2px;
}

.gg-briefcase {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 16px;
  border: 2px solid;
  border-radius: 2px;
  margin-top: 1px;
}
.gg-briefcase::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
}
.gg-briefcase::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border: 2px solid;
  border-top-left-radius: 1px;
  border-top-right-radius: 1px;
  left: 4px;
  width: 10px;
  height: 4px;
  border-bottom: 0;
  top: -5px;
}
.gg-briefcase::after {
  width: 18px;
  height: 3px;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 1px solid transparent;
  box-shadow: 0 2px 0, inset 16px 74px 0;
  top: 4px;
}

.gg-browse {
  display: block;
  position: relative;
  transform: scale(var(--ggs, 1));
  box-sizing: border-box;
  width: 16px;
  height: 22px;
  border: 2px solid;
  border-radius: 3px;
  background: linear-gradient(to left, currentcolor 10px, transparent 0) no-repeat center 2px/8px 2px, linear-gradient(to left, currentcolor 10px, transparent 0) no-repeat center 6px/8px 2px;
}
.gg-browse::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border-radius: 22px;
}
.gg-browse::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border-radius: 22px;
  width: 6px;
  height: 6px;
  border: 2px solid;
  left: 3px;
  top: 9px;
}
.gg-browse::after {
  width: 2px;
  height: 4px;
  background: currentColor;
  bottom: 1px;
  right: 2px;
  transform: rotate(-45deg);
}

.gg-browser {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 24px;
  height: 18px;
  border: 2px solid;
  border-radius: 3px;
}
.gg-browser::after, .gg-browser::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 22px;
  height: 2px;
  background: currentColor;
  top: 4px;
}
.gg-browser::after {
  border-radius: 3px;
  width: 2px;
  box-shadow: 3px 0 0, 6px 0 0;
  top: 1px;
  left: 1px;
}

.gg-brush {
  display: block;
  position: relative;
  transform: scale(var(--ggs, 1));
  box-sizing: border-box;
  width: 14px;
  height: 18px;
  background: linear-gradient(to left, currentColor 10px, transparent 0) no-repeat 4px bottom/2px 5px, linear-gradient(to left, currentColor 10px, transparent 0) no-repeat 8px bottom/2px 5px;
}
.gg-brush::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border: 2px solid;
}
.gg-brush::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border: 2px solid;
  width: 6px;
  height: 8px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  border-bottom: 0;
  left: 4px;
  top: 0;
}
.gg-brush::after {
  width: 14px;
  height: 10px;
  border-top: 0;
  border-radius: 3px;
  border-top-left-radius: 1px;
  border-top-right-radius: 1px;
  background: linear-gradient(to left, currentColor 10px, transparent 0) no-repeat left top/4px 2px, linear-gradient(to left, currentColor 10px, transparent 0) no-repeat right top/4px 2px;
  bottom: 0;
}

.gg-bulb {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 16px;
  height: 16px;
  border: 2px solid;
  border-bottom-color: transparent;
  border-radius: 100px;
}
.gg-bulb::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
}
.gg-bulb::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border-top: 0;
  border-bottom-left-radius: 18px;
  border-bottom-right-radius: 18px;
  top: 10px;
  border-bottom: 2px solid transparent;
  box-shadow: 0 5px 0 -2px, inset 2px 0 0 0, inset -2px 0 0 0, inset 0 -4px 0 -2px;
  width: 8px;
  height: 8px;
  left: 2px;
}
.gg-bulb::after {
  width: 12px;
  height: 2px;
  border-left: 3px solid;
  border-right: 3px solid;
  border-radius: 2px;
  bottom: 0;
  left: 0;
}

.gg-c-plus-plus {
  position: relative;
  display: block;
  width: 16px;
  height: 16px;
  transform: scale(var(--ggs, 1));
  border: 3px solid currentColor;
  box-sizing: border-box;
  border-radius: 100px;
  border-right-color: transparent;
}
.gg-c-plus-plus::after, .gg-c-plus-plus::before {
  content: "";
  position: absolute;
  box-sizing: border-box;
  display: block;
  width: 9px;
  height: 6px;
  border-left: 2px solid;
  border-right: 2px solid;
  right: -9px;
  top: 2px;
}
.gg-c-plus-plus::after {
  width: 13px;
  height: 2px;
  border-left: 6px solid;
  border-right: 6px solid;
  right: -11px;
  top: 4px;
}

.gg-calculator {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 18px;
  height: 22px;
  border: 2px solid;
  border-radius: 2px;
}
.gg-calculator::after, .gg-calculator::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 10px;
  height: 2px;
  background: currentColor;
  top: 2px;
  left: 2px;
}
.gg-calculator::after {
  width: 2px;
  box-shadow: 4px 0 0, 8px 0 0, 0 4px 0, 4px 4px 0, 8px 4px 0, 0 8px 0, 4px 8px 0, 8px 8px 0, 8px 6px 0;
  top: 6px;
}

.gg-calendar-dates {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 18px;
  height: 18px;
  border: 2px solid;
  border-radius: 3px;
}
.gg-calendar-dates::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border-radius: 3px;
  height: 2px;
  left: 2px;
}
.gg-calendar-dates::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border-radius: 3px;
  height: 2px;
  left: 2px;
  background: currentColor;
  width: 2px;
  box-shadow: 4px 0 0, 8px 0 0, 0 4px 0, 4px 4px 0, 8px 4px 0;
  top: 6px;
}
.gg-calendar-dates::after {
  width: 10px;
  top: -4px;
  box-shadow: 0 6px 0 0;
}

.gg-calendar-due {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 18px;
  height: 18px;
  border: 2px solid;
  border-radius: 3px;
}
.gg-calendar-due::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border-radius: 6px;
  background: currentColor;
}
.gg-calendar-due::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border-radius: 6px;
  background: currentColor;
  top: 2px;
  width: 10px;
  height: 2px;
  left: 2px;
}
.gg-calendar-due::after {
  width: 4px;
  height: 4px;
  bottom: 3px;
  left: 5px;
}

.gg-calendar-next {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 14px;
  height: 14px;
  border: 2px solid transparent;
  border-bottom: 4px solid transparent;
  border-right: 4px solid transparent;
  border-left: 3px solid transparent;
  box-shadow: 0 0 0 2px, inset 0 -2px 0;
  border-radius: 1px;
}
.gg-calendar-next::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
}
.gg-calendar-next::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  background: currentColor;
  border-radius: 2px;
  top: 0;
  left: -1px;
  width: 10px;
  height: 2px;
}
.gg-calendar-next::after {
  width: 0;
  height: 0;
  border-top: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-left: 5px solid;
  bottom: -2px;
  right: -2px;
}

.gg-calendar-today {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 18px;
  height: 18px;
  border: 2px solid;
  border-top: 4px solid;
  border-radius: 3px;
}
.gg-calendar-today::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  background: currentColor;
  height: 4px;
  width: 4px;
  border-radius: 2px;
  right: 2px;
  bottom: 2px;
}

.gg-calendar-two {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 18px;
  height: 18px;
  border: 2px solid;
  border-radius: 3px;
}
.gg-calendar-two::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 10px;
  height: 2px;
  background: currentColor;
  border-radius: 3px;
  top: 2px;
  left: 2px;
}

.gg-calendar {
  display: block;
  box-sizing: border-box;
  position: relative;
  transform: scale(var(--ggs, 1));
  width: 18px;
  height: 18px;
  border: 2px solid;
  border-top: 4px solid;
  border-radius: 3px;
}
.gg-calendar::before {
  display: block;
  box-sizing: border-box;
  content: "";
  position: absolute;
  width: 10px;
  border-radius: 3px;
  left: 2px;
  background: currentColor;
  height: 2px;
  top: 2px;
}

.gg-calibrate {
  display: block;
  box-sizing: border-box;
  position: relative;
  transform: scale(var(--ggs, 1));
  width: 18px;
  height: 18px;
  overflow: hidden;
}
.gg-calibrate::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border-radius: 22px;
}
.gg-calibrate::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border-radius: 22px;
  width: 6px;
  height: 6px;
  background: currentColor;
  top: 10px;
  left: 6px;
}
.gg-calibrate::after {
  width: 22px;
  height: 22px;
  border: 6px solid transparent;
  border-top: 6px solid;
  left: -2px;
  top: 1px;
}

.gg-camera {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  border: 2px solid;
  border-radius: 3px;
  width: 18px;
  height: 12px;
  perspective: 24px;
}
.gg-camera::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
}
.gg-camera::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border: 2px solid;
  border-left-color: transparent;
  transform: rotateY(-70deg);
  width: 8px;
  height: 8px;
  right: -7px;
  top: 0;
}
.gg-camera::after {
  width: 10px;
  height: 5px;
  border-top: 2px solid;
  border-right: 2px solid;
  top: -5px;
  right: 2px;
  border-top-right-radius: 2px;
}

.gg-cap {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 18px;
  height: 12px;
  border: 2px solid;
  border-bottom-color: transparent;
  border-top-left-radius: 100px;
  border-top-right-radius: 100px;
}
.gg-cap::after, .gg-cap::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
}
.gg-cap::after {
  border-top: 2px solid transparent;
  border-bottom: 2px solid transparent;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  box-shadow: 0 0 0 2px, inset 12px 0 0 0;
  bottom: -8px;
  width: 18px;
  height: 6px;
  border-radius: 1px;
  left: -2px;
}
.gg-cap::before {
  width: 4px;
  height: 4px;
  background: currentColor;
  border-radius: 2px;
  left: 5px;
  top: -4px;
}

.gg-captions {
  border-radius: 1px;
  transform: scale(var(--ggs, 1));
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 20px;
  height: 16px;
  border: 2px solid;
}
.gg-captions::after, .gg-captions::before {
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 20px;
  height: 16px;
  border: 2px solid;
}
.gg-captions::after {
  content: "";
  position: absolute;
  width: 5px;
  height: 8px;
  border-right: transparent;
  top: 2px;
  left: 2px;
}
.gg-captions::before {
  content: "";
  position: absolute;
  width: 5px;
  height: 8px;
  border-right: transparent;
  top: 2px;
  left: 2px;
  left: 9px;
}

.gg-card-clubs {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 18px;
  height: 22px;
  border: 2px solid;
  border-radius: 3px;
}
.gg-card-clubs::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  background: currentColor;
  border-radius: 4px;
  width: 4px;
  height: 4px;
  left: 5px;
  top: 4px;
  box-shadow: -2.5px 4px 0, 2.5px 4px 0;
}

.gg-card-diamonds {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 18px;
  height: 22px;
  border: 2px solid;
  border-radius: 3px;
}
.gg-card-diamonds::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 6px;
  height: 6px;
  background: currentColor;
  transform: rotate(45deg);
  top: 6px;
  left: 4px;
}

.gg-card-hearts {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 18px;
  height: 22px;
  border: 2px solid;
  border-radius: 3px;
}
.gg-card-hearts::after, .gg-card-hearts::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 4px;
  height: 6px;
  background: currentColor;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  transform: rotate(45deg);
  left: 6px;
  top: 6px;
}
.gg-card-hearts::after {
  transform: rotate(-45deg);
  left: 4px;
}

.gg-card-spades {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 18px;
  height: 22px;
  border: 2px solid;
  border-radius: 3px;
}
.gg-card-spades::after, .gg-card-spades::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 4px;
  height: 6px;
  background: currentColor;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  transform: rotate(-45deg);
  left: 6px;
  top: 6px;
}
.gg-card-spades::after {
  transform: rotate(45deg);
  left: 4px;
}

.gg-carousel {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 18px;
}
.gg-carousel::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border-radius: 3px;
}
.gg-carousel::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border-radius: 3px;
  width: 22px;
  height: 10px;
  border: 2px solid;
}
.gg-carousel::after {
  width: 10px;
  height: 2px;
  background: currentColor;
  top: 16px;
  left: 6px;
  box-shadow: -3px -4px 0, 3px -4px 0;
}

.gg-cast {
  background: linear-gradient(to left, currentColor 5px, transparent 0) no-repeat bottom right/6px 2px, linear-gradient(to left, currentColor 5px, transparent 0) no-repeat top left/2px 2px;
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 20px;
  height: 16px;
  border-top: 2px solid;
  border-right: 2px solid;
  border-radius: 2px;
  border-left: 0;
}
.gg-cast::after, .gg-cast::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 20px;
  height: 20px;
  border: 6px double;
  border-top-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent;
  border-radius: 50%;
  transform: rotate(-45deg);
  bottom: -10px;
  left: -10px;
}
.gg-cast::after {
  width: 4px;
  height: 4px;
  border: 2px solid;
  border-top-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent;
  bottom: -2px;
  left: -2px;
}

.gg-chanel {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 21px;
  height: 18px;
}
.gg-chanel::after {
  content: "";
  display: block;
  position: absolute;
  box-sizing: border-box;
  border: 3px solid;
  width: 18px;
  height: 18px;
  border-radius: 22px;
}
.gg-chanel::before {
  content: "";
  display: block;
  position: absolute;
  box-sizing: border-box;
  border: 3px solid;
  width: 18px;
  height: 18px;
  border-radius: 22px;
  border-left-color: transparent;
  left: -3px;
}
.gg-chanel::after {
  border-right-color: transparent;
  right: -3px;
}

.gg-chart {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 10px;
  height: 10px;
  border-bottom: 2px solid;
  border-left: 2px solid;
}
.gg-chart::after {
  content: "";
  display: block;
  position: absolute;
  border: 2px solid;
  box-sizing: border-box;
}
.gg-chart::before {
  content: "";
  display: block;
  position: absolute;
  border: 2px solid;
  box-sizing: border-box;
  border-top-right-radius: 100px;
  right: -2px;
  top: -2px;
  width: 8px;
  height: 8px;
}
.gg-chart::after {
  width: 18px;
  height: 18px;
  border-radius: 100px;
  border-right-color: transparent;
  left: -10px;
  transform: rotate(-45deg);
}

.gg-check-o {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 22px;
  border: 2px solid;
  border-radius: 100px;
}
.gg-check-o::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  left: 3px;
  top: -1px;
  width: 6px;
  height: 10px;
  border-color: currentColor;
  border-width: 0 2px 2px 0;
  border-style: solid;
  transform-origin: bottom left;
  transform: rotate(45deg);
}

.gg-check-r {
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 22px;
  height: 22px;
  border: 2px solid;
  transform: scale(var(--ggs, 1));
  border-radius: 4px;
}
.gg-check-r::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  left: 3px;
  top: -1px;
  width: 6px;
  height: 10px;
  border-width: 0 2px 2px 0;
  border-style: solid;
  transform-origin: bottom left;
  transform: rotate(45deg);
}

.gg-check {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 22px;
  border: 2px solid transparent;
  border-radius: 100px;
}
.gg-check::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  left: 3px;
  top: -1px;
  width: 6px;
  height: 10px;
  border-width: 0 2px 2px 0;
  border-style: solid;
  transform-origin: bottom left;
  transform: rotate(45deg);
}

.gg-chevron-double-down-o {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  border: 2px solid;
  border-radius: 40px;
  width: 22px;
  height: 22px;
}
.gg-chevron-double-down-o::after, .gg-chevron-double-down-o::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 6px;
  height: 6px;
  border-bottom: 2px solid;
  border-right: 2px solid;
  transform: rotate(45deg);
  left: 6px;
  top: 3px;
}
.gg-chevron-double-down-o::after {
  top: 7px;
}

.gg-chevron-double-down-r {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  border: 2px solid;
  border-radius: 4px;
  width: 22px;
  height: 22px;
}
.gg-chevron-double-down-r::after, .gg-chevron-double-down-r::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 6px;
  height: 6px;
  border-bottom: 2px solid;
  border-right: 2px solid;
  transform: rotate(45deg);
  left: 6px;
  top: 3px;
}
.gg-chevron-double-down-r::after {
  top: 7px;
}

.gg-chevron-double-down {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 22px;
}
.gg-chevron-double-down::after, .gg-chevron-double-down::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 8px;
  height: 8px;
  border-bottom: 2px solid;
  border-right: 2px solid;
  transform: rotate(45deg);
  left: 7px;
  top: 3px;
}
.gg-chevron-double-down::after {
  top: 8px;
}

.gg-chevron-double-left-o {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  border: 2px solid;
  border-radius: 40px;
  width: 22px;
  height: 22px;
}
.gg-chevron-double-left-o::after, .gg-chevron-double-left-o::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 6px;
  height: 6px;
  border-left: 2px solid;
  border-bottom: 2px solid;
  transform: rotate(45deg);
  top: 6px;
  left: 5px;
}
.gg-chevron-double-left-o::after {
  left: 9px;
}

.gg-chevron-double-left-r {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  border: 2px solid;
  border-radius: 4px;
  width: 22px;
  height: 22px;
}
.gg-chevron-double-left-r::after, .gg-chevron-double-left-r::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 6px;
  height: 6px;
  border-left: 2px solid;
  border-bottom: 2px solid;
  transform: rotate(45deg);
  top: 6px;
  left: 5px;
}
.gg-chevron-double-left-r::after {
  left: 9px;
}

.gg-chevron-double-left {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 22px;
}
.gg-chevron-double-left::after, .gg-chevron-double-left::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 8px;
  height: 8px;
  border-left: 2px solid;
  border-bottom: 2px solid;
  transform: rotate(45deg);
  top: 7px;
  left: 6px;
}
.gg-chevron-double-left::after {
  left: 11px;
}

.gg-chevron-double-right-o {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  border: 2px solid;
  border-radius: 40px;
  width: 22px;
  height: 22px;
}
.gg-chevron-double-right-o::after, .gg-chevron-double-right-o::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 6px;
  height: 6px;
  border-right: 2px solid;
  border-top: 2px solid;
  transform: rotate(45deg);
  top: 6px;
  right: 5px;
}
.gg-chevron-double-right-o::after {
  right: 9px;
}

.gg-chevron-double-right-r {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  border: 2px solid;
  border-radius: 4px;
  width: 22px;
  height: 22px;
}
.gg-chevron-double-right-r::after, .gg-chevron-double-right-r::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 6px;
  height: 6px;
  border-right: 2px solid;
  border-top: 2px solid;
  transform: rotate(45deg);
  top: 6px;
  right: 5px;
}
.gg-chevron-double-right-r::after {
  right: 9px;
}

.gg-chevron-double-right {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 22px;
}
.gg-chevron-double-right::after, .gg-chevron-double-right::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 8px;
  height: 8px;
  border-right: 2px solid;
  border-top: 2px solid;
  transform: rotate(45deg);
  top: 7px;
  right: 6px;
}
.gg-chevron-double-right::after {
  right: 11px;
}

.gg-chevron-double-up-o {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  border: 2px solid;
  border-radius: 40px;
  width: 22px;
  height: 22px;
}
.gg-chevron-double-up-o::after, .gg-chevron-double-up-o::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 6px;
  height: 6px;
  border-top: 2px solid;
  border-left: 2px solid;
  transform: rotate(45deg);
  left: 6px;
  bottom: 3px;
}
.gg-chevron-double-up-o::after {
  bottom: 7px;
}

.gg-chevron-double-up-r {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  border: 2px solid;
  border-radius: 4px;
  width: 22px;
  height: 22px;
}
.gg-chevron-double-up-r::after, .gg-chevron-double-up-r::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 6px;
  height: 6px;
  border-top: 2px solid;
  border-left: 2px solid;
  transform: rotate(45deg);
  left: 6px;
  bottom: 3px;
}
.gg-chevron-double-up-r::after {
  bottom: 7px;
}

.gg-chevron-double-up {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 22px;
}
.gg-chevron-double-up::after, .gg-chevron-double-up::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 8px;
  height: 8px;
  border-top: 2px solid;
  border-left: 2px solid;
  transform: rotate(45deg);
  left: 7px;
  bottom: 3px;
}
.gg-chevron-double-up::after {
  bottom: 8px;
}

.gg-chevron-down-o {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 22px;
  border: 2px solid;
  border-radius: 100px;
}
.gg-chevron-down-o::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 6px;
  height: 6px;
  border-bottom: 2px solid;
  border-right: 2px solid;
  transform: rotate(45deg);
  left: 6px;
  top: 5px;
}

.gg-chevron-down-r {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 22px;
  border: 2px solid;
  border-radius: 4px;
}
.gg-chevron-down-r::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 6px;
  height: 6px;
  border-bottom: 2px solid;
  border-right: 2px solid;
  transform: rotate(45deg);
  left: 6px;
  top: 5px;
}

.gg-chevron-down {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 22px;
  border: 2px solid transparent;
  border-radius: 100px;
}
.gg-chevron-down::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 10px;
  height: 10px;
  border-bottom: 2px solid;
  border-right: 2px solid;
  transform: rotate(45deg);
  left: 4px;
  top: 2px;
}

.gg-chevron-left-o {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 22px;
  border: 2px solid;
  border-radius: 100px;
}
.gg-chevron-left-o::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 6px;
  height: 6px;
  border-bottom: 2px solid;
  border-left: 2px solid;
  transform: rotate(45deg);
  left: 7px;
  top: 6px;
}

.gg-chevron-left-r {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 22px;
  border: 2px solid;
  border-radius: 4px;
}
.gg-chevron-left-r::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 6px;
  height: 6px;
  border-bottom: 2px solid;
  border-left: 2px solid;
  transform: rotate(45deg);
  left: 7px;
  top: 6px;
}

.gg-chevron-left {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 22px;
  border: 2px solid transparent;
  border-radius: 100px;
}
.gg-chevron-left::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 10px;
  height: 10px;
  border-bottom: 2px solid;
  border-left: 2px solid;
  transform: rotate(45deg);
  left: 6px;
  top: 4px;
}

.gg-chevron-right-o {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 22px;
  border: 2px solid;
  border-radius: 100px;
}
.gg-chevron-right-o::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 6px;
  height: 6px;
  border-bottom: 2px solid;
  border-right: 2px solid;
  transform: rotate(-45deg);
  left: 5px;
  top: 6px;
}

.gg-chevron-right-r {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 22px;
  border: 2px solid;
  border-radius: 4px;
}
.gg-chevron-right-r::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 6px;
  height: 6px;
  border-bottom: 2px solid;
  border-right: 2px solid;
  transform: rotate(-45deg);
  left: 5px;
  top: 6px;
}

.gg-chevron-right {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 22px;
  border: 2px solid transparent;
  border-radius: 100px;
}
.gg-chevron-right::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 10px;
  height: 10px;
  border-bottom: 2px solid;
  border-right: 2px solid;
  transform: rotate(-45deg);
  right: 6px;
  top: 4px;
}

.gg-chevron-up-o {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 22px;
  border: 2px solid;
  border-radius: 100px;
}
.gg-chevron-up-o::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 6px;
  height: 6px;
  border-top: 2px solid;
  border-right: 2px solid;
  transform: rotate(-45deg);
  left: 6px;
  bottom: 5px;
}

.gg-chevron-up-r {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 22px;
  border: 2px solid;
  border-radius: 4px;
}
.gg-chevron-up-r::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 6px;
  height: 6px;
  border-top: 2px solid;
  border-right: 2px solid;
  transform: rotate(-45deg);
  left: 6px;
  bottom: 5px;
}

.gg-chevron-up {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 22px;
  border: 2px solid transparent;
  border-radius: 100px;
}
.gg-chevron-up::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 10px;
  height: 10px;
  border-top: 2px solid;
  border-right: 2px solid;
  transform: rotate(-45deg);
  left: 4px;
  bottom: 2px;
}

.gg-circleci {
  display: block;
  box-sizing: border-box;
  border-radius: 18px;
  position: relative;
  transform: scale(var(--ggs, 1));
  width: 24px;
  height: 24px;
  border: 4px solid;
  background: linear-gradient(to left, currentColor 8px, transparent 0) no-repeat -2px center/8px 3px;
}
.gg-circleci::before {
  display: block;
  box-sizing: border-box;
  border-radius: 18px;
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  border: 3px solid;
  top: 3px;
  right: 3px;
}

.gg-clapper-board {
  display: block;
  position: relative;
  transform: scale(var(--ggs, 1));
  box-sizing: border-box;
  width: 22px;
  height: 18px;
  border: 2px solid;
  border-radius: 3px;
}
.gg-clapper-board::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
}
.gg-clapper-board::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 10px;
  height: 8px;
  border-left: 2px solid;
  border-right: 2px solid;
  left: 4px;
  top: -2px;
  transform: skewX(-40deg);
}
.gg-clapper-board::after {
  width: 22px;
  height: 2px;
  background: currentColor;
  top: 4px;
  left: -2px;
}

.gg-clipboard {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 18px;
  height: 18px;
  border: 2px solid;
  border-radius: 2px;
}
.gg-clipboard::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border-radius: 2px;
  width: 10px;
  left: 2px;
}
.gg-clipboard::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border-radius: 2px;
  width: 10px;
  left: 2px;
  border: 2px solid;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  top: -2px;
  height: 6px;
}
.gg-clipboard::after {
  height: 2px;
  background: currentColor;
  box-shadow: 0 -4px 0 0;
  bottom: 2px;
}

.gg-close-o {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 22px;
  border: 2px solid;
  border-radius: 40px;
}
.gg-close-o::after, .gg-close-o::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 12px;
  height: 2px;
  background: currentColor;
  transform: rotate(45deg);
  border-radius: 5px;
  top: 8px;
  left: 3px;
}
.gg-close-o::after {
  transform: rotate(-45deg);
}

.gg-close-r {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 22px;
  border: 2px solid;
  border-radius: 4px;
}
.gg-close-r::after, .gg-close-r::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 12px;
  height: 2px;
  background: currentColor;
  transform: rotate(45deg);
  border-radius: 5px;
  top: 8px;
  left: 3px;
}
.gg-close-r::after {
  transform: rotate(-45deg);
}

.gg-close {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 22px;
  border: 2px solid transparent;
  border-radius: 40px;
}
.gg-close::after, .gg-close::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 16px;
  height: 2px;
  background: currentColor;
  transform: rotate(45deg);
  border-radius: 5px;
  top: 8px;
  left: 1px;
}
.gg-close::after {
  transform: rotate(-45deg);
}

.gg-cloud {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 8px;
  height: 2px;
  background: currentColor;
  margin-bottom: -20px;
}
.gg-cloud::after {
  content: "";
  display: block;
  position: absolute;
  border: 2px solid;
  box-sizing: border-box;
  bottom: 0;
}
.gg-cloud::before {
  content: "";
  display: block;
  position: absolute;
  border: 2px solid;
  box-sizing: border-box;
  bottom: 0;
  border-top-left-radius: 100px;
  border-bottom-left-radius: 100px;
  border-right: 0;
  left: -6px;
  width: 7px;
  height: 12px;
}
.gg-cloud::after {
  width: 16px;
  height: 16px;
  border-radius: 100px;
  border-left-color: transparent;
  right: -8px;
  transform: rotate(-45deg);
}

.gg-code-climate {
  display: block;
  box-sizing: border-box;
  position: relative;
  transform: scale(var(--ggs, 1));
  width: 24px;
  height: 16px;
}
.gg-code-climate::after {
  content: "";
  display: block;
  position: absolute;
  box-sizing: border-box;
  width: 12px;
  border-left: 4px solid;
  border-top: 4px solid;
  transform: rotate(45deg);
}
.gg-code-climate::before {
  content: "";
  display: block;
  position: absolute;
  box-sizing: border-box;
  width: 12px;
  border-left: 4px solid;
  border-top: 4px solid;
  transform: rotate(45deg);
  height: 12px;
  left: 2px;
  bottom: -2px;
}
.gg-code-climate::after {
  height: 6px;
  right: 0;
  bottom: 6px;
}

.gg-code-slash {
  display: block;
  position: relative;
  box-sizing: border-box;
  transform: rotate(15deg) scale(var(--ggs, 1));
  width: 2px;
  height: 16px;
  background: currentColor;
}
.gg-code-slash::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 8px;
  height: 8px;
  transform: rotate(-60deg);
}
.gg-code-slash::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 8px;
  height: 8px;
  transform: rotate(-60deg);
  border-left: 2px solid;
  border-top: 2px solid;
  left: -8px;
  top: 5px;
}
.gg-code-slash::after {
  border-right: 2px solid;
  border-bottom: 2px solid;
  right: -8px;
  top: 3px;
}

.gg-code {
  transform: scale(var(--ggs, 1));
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 8px;
  height: 20px;
}
.gg-code::after, .gg-code::before {
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 8px;
  height: 20px;
}
.gg-code::after, .gg-code::before {
  content: "";
  position: absolute;
  height: 8px;
  border-left: 2px solid;
  border-bottom: 2px solid;
  transform: rotate(45deg) scaleY(1);
  left: -4px;
  top: 6px;
}
.gg-code::after {
  transform: rotate(-45deg) scaleX(-1);
  left: 4px;
}

.gg-coffee {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 18px;
  height: 14px;
  border: 2px solid;
  border-radius: 6px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  margin-left: -4px;
  margin-top: 3px;
}
.gg-coffee::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
}
.gg-coffee::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  left: 2px;
  background: currentColor;
  box-shadow: 4px 0 0, 8px 0 0;
  border-radius: 3px;
  width: 2px;
  height: 4px;
  top: -7px;
}
.gg-coffee::after {
  width: 6px;
  height: 8px;
  border: 2px solid;
  border-radius: 100px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  right: -6px;
  top: -1px;
}

.gg-collage {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 16px;
  height: 20px;
  border: 2px solid;
  border-radius: 3px;
}
.gg-collage::after, .gg-collage::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 2px;
  height: 20px;
  background: currentColor;
  left: 5px;
  top: -2px;
}
.gg-collage::after {
  width: 9px;
  height: 2px;
  left: -2px;
  top: 4px;
  box-shadow: 7px 5px 0 0;
}

.gg-color-bucket {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 6px;
  height: 10px;
  border: 2px solid;
  border-bottom: 0;
  border-top-left-radius: 100px;
  border-top-right-radius: 100px;
  margin-top: -18px;
}
.gg-color-bucket::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  transform: rotate(45deg);
}
.gg-color-bucket::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  transform: rotate(45deg);
  border: 2px solid;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  top: 4px;
  left: -4px;
  width: 14px;
  height: 16px;
}
.gg-color-bucket::after {
  width: 6px;
  height: 6px;
  border-radius: 0 100% 100% 100%;
  background: currentColor;
  right: -15px;
  bottom: -12px;
}

.gg-color-picker {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: rotate(-45deg) scale(var(--ggs, 1));
  width: 18px;
  height: 10px;
  border-left: 0;
  border-top-left-radius: 100px;
  border-bottom-left-radius: 100px;
  border-top: 2px solid transparent;
  border-bottom: 2px solid transparent;
  border-right: 2px solid transparent;
  box-shadow: inset 0 0 0 2px;
}
.gg-color-picker::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  background: currentColor;
}
.gg-color-picker::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  background: currentColor;
  top: -2px;
  border-radius: 3px;
  width: 2px;
  height: 10px;
  right: -3px;
}
.gg-color-picker::after {
  width: 8px;
  height: 6px;
  right: -9px;
  box-shadow: -21px 0 0 -2px;
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
}

.gg-comedy-central {
  box-sizing: border-box;
  display: block;
  border: 3px solid;
  border-radius: 100px;
  position: relative;
  border-left-color: transparent;
  transform: scale(var(--ggs, 1));
  width: 20px;
  height: 20px;
}
.gg-comedy-central::before {
  box-sizing: border-box;
  display: block;
  border: 3px solid;
  border-radius: 100px;
  content: "";
  position: absolute;
  border-right-color: transparent;
  width: 10px;
  height: 10px;
  bottom: 2px;
  right: 2px;
}

.gg-comment {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 20px;
  height: 16px;
  border: 2px solid;
  border-bottom: 0;
  box-shadow: -6px 8px 0 -6px, 6px 8px 0 -6px;
}
.gg-comment::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 8px;
}
.gg-comment::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 8px;
  border: 2px solid;
  border-top-color: transparent;
  border-bottom-left-radius: 20px;
  right: 4px;
  bottom: -6px;
  height: 6px;
}
.gg-comment::after {
  height: 2px;
  background: currentColor;
  box-shadow: 0 4px 0 0;
  left: 4px;
  top: 4px;
}

.gg-community {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 21px;
  height: 14px;
}
.gg-community::after, .gg-community::before {
  content: "";
  display: block;
  position: absolute;
  border: 2px solid;
  width: 10px;
  height: 10px;
  border-radius: 22px;
}
.gg-community::after {
  right: 0;
}

.gg-components {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: rotate(45deg) scale(var(--ggs, 1));
  width: 14px;
  height: 14px;
  background: linear-gradient(to left, currentColor 10px, transparent 0) no-repeat 0 0/2px 6px, linear-gradient(to left, currentColor 10px, transparent 0) no-repeat 4px 0/2px 6px, linear-gradient(to left, currentColor 10px, transparent 0) no-repeat 8px 0/2px 6px, linear-gradient(to left, currentColor 10px, transparent 0) no-repeat 12px 0/2px 6px, linear-gradient(to left, currentColor 10px, transparent 0) no-repeat 0 8px/2px 6px, linear-gradient(to left, currentColor 10px, transparent 0) no-repeat 4px 8px/2px 6px, linear-gradient(to left, currentColor 10px, transparent 0) no-repeat 8px 8px/2px 6px, linear-gradient(to left, currentColor 10px, transparent 0) no-repeat 12px 8px/2px 6px;
}
.gg-components::after, .gg-components::before {
  content: "";
  display: block;
  position: absolute;
  width: 6px;
  height: 2px;
  border-top: 6px double;
  border-bottom: 6px double;
}
.gg-components::after {
  right: 0;
}

.gg-compress-left {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: rotate(-45deg) scale(var(--ggs, 1));
  width: 2px;
  height: 26px;
  border-top: 10px solid;
  border-bottom: 10px solid;
}
.gg-compress-left::after, .gg-compress-left::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 6px;
  height: 6px;
  transform: rotate(45deg);
  left: -2px;
}
.gg-compress-left::after {
  border-bottom: 2px solid;
  border-right: 2px solid;
  top: -5px;
}
.gg-compress-left::before {
  border-top: 2px solid;
  border-left: 2px solid;
  bottom: -5px;
}

.gg-compress-right {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: rotate(45deg) scale(var(--ggs, 1));
  width: 2px;
  height: 26px;
  border-top: 10px solid;
  border-bottom: 10px solid;
}
.gg-compress-right::after, .gg-compress-right::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 6px;
  height: 6px;
  transform: rotate(45deg);
  left: -2px;
}
.gg-compress-right::after {
  border-bottom: 2px solid;
  border-right: 2px solid;
  top: -5px;
}
.gg-compress-right::before {
  border-top: 2px solid;
  border-left: 2px solid;
  bottom: -5px;
}

.gg-compress-v {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 2px;
  height: 26px;
  border-top: 10px solid;
  border-bottom: 10px solid;
}
.gg-compress-v::after, .gg-compress-v::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 6px;
  height: 6px;
  transform: rotate(45deg);
  left: -2px;
}
.gg-compress-v::after {
  border-bottom: 2px solid;
  border-right: 2px solid;
  top: -5px;
}
.gg-compress-v::before {
  border-top: 2px solid;
  border-left: 2px solid;
  bottom: -5px;
}

.gg-compress {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 26px;
  height: 2px;
  border-left: 10px solid;
  border-right: 10px solid;
}
.gg-compress::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 6px;
  height: 6px;
  border-top: 2px solid;
  top: -2px;
}
.gg-compress::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 6px;
  height: 6px;
  border-top: 2px solid;
  top: -2px;
  border-right: 2px solid;
  left: -5px;
  transform: rotate(45deg);
}
.gg-compress::after {
  border-left: 2px solid;
  right: -5px;
  transform: rotate(-45deg);
}

.gg-controller {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 8px;
  height: 8px;
  border: 2px solid;
  border-radius: 100px;
}
.gg-controller::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 14px;
  height: 14px;
  box-shadow: -6px -6px 0 -4px, 6px 6px 0 -4px, 6px -6px 0 -4px, -6px 6px 0 -4px;
  left: -5px;
  top: -5px;
  transform: rotate(45deg);
}

.gg-copy {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 14px;
  height: 18px;
  border: 2px solid;
  margin-left: -5px;
  margin-top: -4px;
}
.gg-copy::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
}
.gg-copy::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  background: linear-gradient(to left, currentColor 5px, transparent 0) no-repeat right top/5px 2px, linear-gradient(to left, currentColor 5px, transparent 0) no-repeat left bottom/2px 5px;
  box-shadow: inset -4px -4px 0 -2px;
  bottom: -6px;
  right: -6px;
  width: 14px;
  height: 18px;
}
.gg-copy::after {
  width: 6px;
  height: 2px;
  background: currentColor;
  left: 2px;
  top: 2px;
  box-shadow: 0 4px 0, 0 8px 0;
}

.gg-copyright {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 18px;
  height: 18px;
  border: 2px solid;
  border-radius: 40px;
}
.gg-copyright::after {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 18px;
  height: 18px;
  border: 2px solid;
  border-radius: 40px;
  content: "";
  position: absolute;
  border-right-color: transparent;
  width: 8px;
  height: 8px;
  top: 3px;
  left: 3px;
  transform: none;
}

.gg-corner-double-down-left {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 12px;
  height: 12px;
  border-bottom-right-radius: 4px;
  border-bottom: 2px solid;
  border-right: 2px solid;
}
.gg-corner-double-down-left::after, .gg-corner-double-down-left::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 6px;
  height: 6px;
  border-left: 2px solid;
  transform: rotate(45deg);
}
.gg-corner-double-down-left::after {
  border-bottom: 2px solid;
  bottom: -4px;
}
.gg-corner-double-down-left::before {
  border-top: 2px solid;
  right: -4px;
}

.gg-corner-double-down-right {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 12px;
  height: 12px;
  border-bottom-left-radius: 4px;
  border-bottom: 2px solid;
  border-left: 2px solid;
}
.gg-corner-double-down-right::after, .gg-corner-double-down-right::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 6px;
  height: 6px;
  border-top: 2px solid;
  transform: rotate(45deg);
}
.gg-corner-double-down-right::after {
  border-right: 2px solid;
  bottom: -4px;
  right: 0;
}
.gg-corner-double-down-right::before {
  border-left: 2px solid;
  left: -4px;
}

.gg-corner-double-left-down {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 12px;
  height: 12px;
  border-top-left-radius: 4px;
  border-top: 2px solid;
  border-left: 2px solid;
}
.gg-corner-double-left-down::after, .gg-corner-double-left-down::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 6px;
  height: 6px;
  border-right: 2px solid;
  transform: rotate(45deg);
}
.gg-corner-double-left-down::after {
  border-top: 2px solid;
  top: -4px;
  right: 0;
}
.gg-corner-double-left-down::before {
  border-bottom: 2px solid;
  left: -4px;
  bottom: 0;
}

.gg-corner-double-left-up {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 8px;
  height: 10px;
  border-bottom-left-radius: 4px;
  border-bottom: 2px solid;
  border-left: 2px solid;
}
.gg-corner-double-left-up::after, .gg-corner-double-left-up::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 6px;
  height: 6px;
  border-top: 2px solid;
  left: -4px;
  transform: rotate(45deg);
}
.gg-corner-double-left-up::after {
  border-left: 2px solid;
  top: -4px;
}
.gg-corner-double-left-up::before {
  border-left: 2px solid;
  top: 0;
}

.gg-corner-double-right-down {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 12px;
  height: 12px;
  border-top-right-radius: 4px;
  border-top: 2px solid;
  border-right: 2px solid;
}
.gg-corner-double-right-down::after, .gg-corner-double-right-down::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 6px;
  height: 6px;
  border-bottom: 2px solid;
  transform: rotate(45deg);
}
.gg-corner-double-right-down::after {
  border-left: 2px solid;
  top: -4px;
  left: 0;
}
.gg-corner-double-right-down::before {
  border-right: 2px solid;
  right: -4px;
  bottom: 0;
}

.gg-corner-double-right-up {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 8px;
  height: 10px;
  border-bottom-right-radius: 4px;
  border-bottom: 2px solid;
  border-right: 2px solid;
}
.gg-corner-double-right-up::after, .gg-corner-double-right-up::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 6px;
  height: 6px;
  border-left: 2px solid;
  right: -4px;
  transform: rotate(45deg);
}
.gg-corner-double-right-up::after {
  border-top: 2px solid;
  top: -4px;
}
.gg-corner-double-right-up::before {
  border-top: 2px solid;
  top: 0;
}

.gg-corner-double-up-left {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 10px;
  height: 8px;
  border-top-right-radius: 4px;
  border-top: 2px solid;
  border-right: 2px solid;
}
.gg-corner-double-up-left::after, .gg-corner-double-up-left::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 6px;
  height: 6px;
  border-bottom: 2px solid;
  top: -4px;
  transform: rotate(45deg);
}
.gg-corner-double-up-left::after {
  border-left: 2px solid;
}
.gg-corner-double-up-left::before {
  border-left: 2px solid;
  left: -4px;
}

.gg-corner-double-up-right {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 10px;
  height: 8px;
  border-top-left-radius: 4px;
  border-top: 2px solid;
  border-left: 2px solid;
}
.gg-corner-double-up-right::after, .gg-corner-double-up-right::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 6px;
  height: 6px;
  border-right: 2px solid;
  top: -4px;
  transform: rotate(45deg);
}
.gg-corner-double-up-right::after {
  border-top: 2px solid;
  right: 0;
}
.gg-corner-double-up-right::before {
  border-top: 2px solid;
  right: -4px;
}

.gg-corner-down-left {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 22px;
}
.gg-corner-down-left::after, .gg-corner-down-left::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  left: 3px;
}
.gg-corner-down-left::after {
  width: 8px;
  height: 8px;
  border-bottom: 2px solid;
  border-left: 2px solid;
  transform: rotate(45deg);
  bottom: 3px;
}
.gg-corner-down-left::before {
  width: 16px;
  height: 12px;
  border-bottom-right-radius: 4px;
  border-bottom: 2px solid;
  border-right: 2px solid;
  bottom: 6px;
}

.gg-corner-down-right {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 22px;
}
.gg-corner-down-right::after, .gg-corner-down-right::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  right: 3px;
}
.gg-corner-down-right::after {
  width: 8px;
  height: 8px;
  border-top: 2px solid;
  border-right: 2px solid;
  transform: rotate(45deg);
  bottom: 3px;
}
.gg-corner-down-right::before {
  width: 16px;
  height: 12px;
  border-bottom-left-radius: 4px;
  border-bottom: 2px solid;
  border-left: 2px solid;
  bottom: 6px;
}

.gg-corner-left-down {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 22px;
}
.gg-corner-left-down::after, .gg-corner-left-down::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  bottom: 3px;
}
.gg-corner-left-down::after {
  width: 8px;
  height: 8px;
  border-bottom: 2px solid;
  border-right: 2px solid;
  transform: rotate(45deg);
  left: 3px;
}
.gg-corner-left-down::before {
  width: 12px;
  height: 16px;
  border-top-left-radius: 4px;
  border-top: 2px solid;
  border-left: 2px solid;
  left: 6px;
}

.gg-corner-left-up {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 22px;
}
.gg-corner-left-up::after, .gg-corner-left-up::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  top: 3px;
}
.gg-corner-left-up::after {
  width: 8px;
  height: 8px;
  border-top: 2px solid;
  border-left: 2px solid;
  transform: rotate(45deg);
  left: 3px;
}
.gg-corner-left-up::before {
  width: 12px;
  height: 16px;
  border-bottom-left-radius: 4px;
  border-bottom: 2px solid;
  border-left: 2px solid;
  left: 6px;
}

.gg-corner-right-down {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 22px;
}
.gg-corner-right-down::after, .gg-corner-right-down::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  bottom: 3px;
}
.gg-corner-right-down::after {
  width: 8px;
  height: 8px;
  border-bottom: 2px solid;
  border-right: 2px solid;
  transform: rotate(45deg);
  right: 3px;
}
.gg-corner-right-down::before {
  width: 12px;
  height: 16px;
  border-top-right-radius: 4px;
  border-top: 2px solid;
  border-right: 2px solid;
  right: 6px;
}

.gg-corner-right-up {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 22px;
}
.gg-corner-right-up::after, .gg-corner-right-up::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  top: 3px;
}
.gg-corner-right-up::after {
  width: 8px;
  height: 8px;
  border-top: 2px solid;
  border-left: 2px solid;
  transform: rotate(45deg);
  right: 3px;
}
.gg-corner-right-up::before {
  width: 12px;
  height: 16px;
  border-bottom-right-radius: 4px;
  border-bottom: 2px solid;
  border-right: 2px solid;
  right: 6px;
}

.gg-corner-up-left {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 22px;
}
.gg-corner-up-left::after, .gg-corner-up-left::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  left: 3px;
}
.gg-corner-up-left::after {
  width: 8px;
  height: 8px;
  border-bottom: 2px solid;
  border-left: 2px solid;
  transform: rotate(45deg);
  top: 3px;
}
.gg-corner-up-left::before {
  width: 16px;
  height: 12px;
  border-top-right-radius: 4px;
  border-top: 2px solid;
  border-right: 2px solid;
  top: 6px;
}

.gg-corner-up-right {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 22px;
}
.gg-corner-up-right::after, .gg-corner-up-right::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  right: 3px;
}
.gg-corner-up-right::after {
  width: 8px;
  height: 8px;
  border-top: 2px solid;
  border-right: 2px solid;
  transform: rotate(45deg);
  top: 3px;
}
.gg-corner-up-right::before {
  width: 16px;
  height: 12px;
  border-top-left-radius: 4px;
  border-top: 2px solid;
  border-left: 2px solid;
  top: 6px;
}

.gg-credit-card {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 24px;
  height: 18px;
  border: 2px solid;
  border-radius: 4px;
}
.gg-credit-card::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border-radius: 3px;
  width: 24px;
  left: -2px;
  height: 2px;
  background: currentColor;
  top: 9px;
}
.gg-credit-card::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border-radius: 3px;
  width: 24px;
  left: -2px;
  height: 2px;
  background: currentColor;
  top: 9px;
  width: 6px;
  left: 2px;
  top: 3px;
}

.gg-crop {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 20px;
  height: 20px;
}
.gg-crop::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 14px;
  height: 14px;
}
.gg-crop::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 14px;
  height: 14px;
  border-right: 2px solid;
  border-top: 2px solid;
  left: 1px;
  top: 5px;
}
.gg-crop::after {
  border-left: 2px solid;
  border-bottom: 2px solid;
  top: 1px;
  right: 1px;
}

.gg-cross {
  transform: scale(var(--ggs, 1));
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 10px;
  height: 2px;
  border-radius: 3px;
  background: currentColor;
}
.gg-cross::after {
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 10px;
  height: 2px;
  border-radius: 3px;
  background: currentColor;
  content: "";
  position: absolute;
  width: 2px;
  height: 12px;
  top: -4px;
  left: 4px;
}

.gg-crowdfire {
  display: block;
  box-sizing: border-box;
  position: relative;
  transform: scale(var(--ggs, 1));
  width: 20px;
  height: 20px;
}
.gg-crowdfire::after {
  content: "";
  display: block;
  position: absolute;
  box-sizing: border-box;
}
.gg-crowdfire::before {
  content: "";
  display: block;
  position: absolute;
  box-sizing: border-box;
  width: 20px;
  height: 20px;
  border: 4px solid;
  border-radius: 100px;
  border-right-color: transparent;
  transform: rotate(-45deg);
}
.gg-crowdfire::after {
  width: 8px;
  height: 8px;
  border-top: 4px solid;
  border-right: 4px solid;
  right: 2px;
  top: 2px;
}

.gg-crown {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 19px;
  height: 12px;
  border: 2px solid;
  border-top-color: transparent;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  padding-top: 4px;
}
.gg-crown::after {
  content: "";
  display: block;
  position: absolute;
  box-sizing: border-box;
  width: 8px;
  height: 8px;
  border-bottom: 2px solid;
  border-left: 2px solid;
  transform: rotate(-45deg);
  top: -4px;
}
.gg-crown::before {
  content: "";
  display: block;
  position: absolute;
  box-sizing: border-box;
  width: 8px;
  height: 8px;
  border-bottom: 2px solid;
  border-left: 2px solid;
  transform: rotate(-45deg);
  top: -4px;
  border-top-left-radius: 2px;
  left: -1px;
}
.gg-crown::after {
  border-bottom-right-radius: 2px;
  right: -1px;
}

.gg-danger {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 20px;
  height: 20px;
  border: 2px solid;
  border-radius: 40px;
}
.gg-danger::after, .gg-danger::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border-radius: 3px;
  width: 2px;
  background: currentColor;
  left: 7px;
}
.gg-danger::after {
  top: 2px;
  height: 8px;
}
.gg-danger::before {
  height: 2px;
  bottom: 2px;
}

.gg-dark-mode {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  border: 2px solid;
  border-radius: 100px;
  width: 20px;
  height: 20px;
}
.gg-dark-mode::after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  display: block;
}
.gg-dark-mode::before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  display: block;
  border: 5px solid;
  border-top-left-radius: 100px;
  border-bottom-left-radius: 100px;
  border-right: 0;
  width: 9px;
  height: 18px;
  top: -1px;
  left: -1px;
}
.gg-dark-mode::after {
  border: 4px solid;
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
  border-left: 0;
  width: 4px;
  height: 8px;
  right: 4px;
  top: 4px;
}

.gg-data {
  transform: scale(var(--ggs, 1));
  box-sizing: border-box;
  position: relative;
  display: block;
  border: 2px solid;
  border-radius: 50%;
  width: 14px;
  height: 14px;
  border-top-color: transparent;
  border-bottom-color: transparent;
}
.gg-data::after, .gg-data::before {
  box-sizing: border-box;
  position: relative;
  display: block;
  border: 2px solid;
  border-radius: 50%;
  width: 14px;
  height: 14px;
}
.gg-data::after, .gg-data::before {
  content: "";
  position: absolute;
  width: 6px;
  height: 6px;
  top: 2px;
  left: 2px;
}
.gg-data::after {
  background: linear-gradient(to left, currentColor 8px, transparent 0) no-repeat bottom center/2px 8px;
  width: 22px;
  height: 22px;
  top: -6px;
  left: -6px;
  border-top-color: transparent;
  border-bottom-color: transparent;
}

.gg-database {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 18px;
  height: 14px;
  border: 2px solid transparent;
  border-top: 2px solid transparent;
  border-bottom: 2px solid transparent;
  border-left: 6px solid transparent;
  box-shadow: 0 0 0 2px, inset 0 2px 0 0, inset 0 -2px 0 0;
  border-radius: 1px;
}
.gg-database::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
}
.gg-database::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  background: currentColor;
  width: 20px;
  height: 2px;
  top: 4px;
  left: -8px;
}
.gg-database::after {
  width: 2px;
  height: 10px;
  border-bottom: 2px solid;
  border-top: 2px solid;
  top: 0;
  left: -4px;
}

.gg-debug {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 12px;
  height: 18px;
  border: 2px solid;
  border-radius: 22px;
}
.gg-debug::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
}
.gg-debug::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 8px;
  height: 4px;
  border: 2px solid;
  top: -4px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top: 0;
}
.gg-debug::after {
  background: currentColor;
  width: 4px;
  height: 2px;
  border-radius: 5px;
  top: 4px;
  left: 2px;
  box-shadow: 0 4px 0, -6px -2px 0, -6px 2px 0, -6px 6px 0, 6px -2px 0, 6px 2px 0, 6px 6px 0;
}

.gg-designmodo {
  display: block;
  position: relative;
  box-sizing: border-box;
  transform: scale(var(--ggs, 1));
  width: 20px;
  height: 16px;
  background: linear-gradient(to left, currentColor 5px, transparent 0) no-repeat right 3px/8px 3px;
}
.gg-designmodo::after, .gg-designmodo::before {
  content: "";
  display: block;
  position: absolute;
  box-sizing: border-box;
  width: 10px;
  height: 10px;
  border: 3px solid;
  border-radius: 10px;
}
.gg-designmodo::after {
  width: 14px;
  height: 14px;
  border: 3px solid transparent;
  border-bottom: 3px solid;
  left: 3px;
  bottom: 0;
}

.gg-desktop {
  display: block;
  position: relative;
  box-sizing: border-box;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 18px;
  border: 2px solid;
  border-radius: 3px;
  overflow: hidden;
}
.gg-desktop::before {
  content: "";
  display: block;
  position: absolute;
  box-sizing: border-box;
  width: 22px;
  height: 2px;
  background: currentColor;
  top: 2px;
  left: -2px;
}
.gg-desktop::after {
  content: "";
  display: block;
  position: absolute;
  box-sizing: border-box;
  background: currentColor;
  width: 2px;
  height: 2px;
  border-radius: 4px;
  bottom: 2px;
  left: 4px;
  box-shadow: 4px 0 0, 8px 0 0;
}

.gg-details-less {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 20px;
  height: 14px;
  border: 2px solid transparent;
}
.gg-details-less::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  height: 2px;
  border-radius: 3px;
  background: currentColor;
}
.gg-details-less::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  height: 2px;
  border-radius: 3px;
  background: currentColor;
  top: 2px;
  width: 16px;
}
.gg-details-less::after {
  width: 10px;
  bottom: 2px;
}

.gg-details-more {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 20px;
  height: 14px;
  border: 2px solid transparent;
}
.gg-details-more::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  height: 2px;
  border-radius: 3px;
  background: currentColor;
}
.gg-details-more::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  height: 2px;
  border-radius: 3px;
  background: currentColor;
  box-shadow: 0 4px 0 0;
  top: 0;
  width: 16px;
}
.gg-details-more::after {
  width: 10px;
  bottom: 0;
}

.gg-dialpad {
  transform: scale(var(--ggs, 1));
  box-shadow: -5px 0 0, 5px 0 0;
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 3px;
  height: 3px;
  background: currentColor;
}
.gg-dialpad::before {
  box-shadow: -5px 0 0, 5px 0 0;
}
.gg-dialpad::after, .gg-dialpad::before {
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 3px;
  height: 3px;
  background: currentColor;
}
.gg-dialpad::after {
  content: "";
  position: absolute;
  left: 0;
}
.gg-dialpad::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 5px;
}
.gg-dialpad::after {
  box-shadow: -5px 0 0, 5px 0 0, 0 5px 0;
  top: 5px;
}

.gg-dice-1 {
  display: block;
  transform: scale(var(--ggs, 1));
  position: relative;
  box-sizing: border-box;
  width: 22px;
  height: 22px;
  border: 2px solid;
  border-radius: 3px;
}
.gg-dice-1::before {
  content: "";
  display: block;
  box-sizing: border-box;
  background: currentColor;
  position: absolute;
  width: 4px;
  height: 4px;
  border-radius: 4px;
  left: 7px;
  top: 7px;
}

.gg-dice-2 {
  display: block;
  transform: scale(var(--ggs, 1));
  position: relative;
  box-sizing: border-box;
  width: 22px;
  height: 22px;
  border: 2px solid;
  border-radius: 3px;
}
.gg-dice-2::before {
  content: "";
  display: block;
  box-sizing: border-box;
  background: currentColor;
  position: absolute;
  width: 4px;
  height: 4px;
  border-radius: 4px;
  left: 2px;
  top: 2px;
  box-shadow: 10px 10px 0;
}

.gg-dice-3 {
  display: block;
  transform: scale(var(--ggs, 1));
  position: relative;
  box-sizing: border-box;
  width: 22px;
  height: 22px;
  border: 2px solid;
  border-radius: 3px;
}
.gg-dice-3::before {
  content: "";
  display: block;
  box-sizing: border-box;
  background: currentColor;
  position: absolute;
  width: 4px;
  height: 4px;
  border-radius: 4px;
  left: 2px;
  top: 2px;
  box-shadow: 5px 5px 0, 10px 10px 0;
}

.gg-dice-4 {
  display: block;
  transform: scale(var(--ggs, 1));
  position: relative;
  box-sizing: border-box;
  width: 22px;
  height: 22px;
  border: 2px solid;
  border-radius: 3px;
}
.gg-dice-4::before {
  content: "";
  display: block;
  box-sizing: border-box;
  background: currentColor;
  position: absolute;
  width: 4px;
  height: 4px;
  border-radius: 4px;
  left: 2px;
  top: 2px;
  box-shadow: 0 10px 0, 10px 0 0, 10px 10px 0;
}

.gg-dice-5 {
  display: block;
  transform: scale(var(--ggs, 1));
  position: relative;
  box-sizing: border-box;
  width: 22px;
  height: 22px;
  border: 2px solid;
  border-radius: 3px;
}
.gg-dice-5::before {
  content: "";
  display: block;
  box-sizing: border-box;
  background: currentColor;
  position: absolute;
  width: 4px;
  height: 4px;
  border-radius: 4px;
  left: 2px;
  top: 2px;
  box-shadow: 0 10px 0, 10px 0 0, 10px 10px 0, 5px 5px 0;
}

.gg-dice-6 {
  display: block;
  transform: scale(var(--ggs, 1));
  position: relative;
  box-sizing: border-box;
  width: 22px;
  height: 22px;
  border: 2px solid;
  border-radius: 3px;
}
.gg-dice-6::before {
  content: "";
  display: block;
  box-sizing: border-box;
  background: currentColor;
  position: absolute;
  width: 4px;
  height: 4px;
  border-radius: 4px;
  left: 2px;
  top: 2px;
  box-shadow: 0 5px 0, 0 10px 0, 10px 0 0, 10px 5px 0, 10px 10px 0;
}

.gg-digitalocean {
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 20px;
  height: 20px;
  background: linear-gradient(to left, currentColor 4px, transparent 0) no-repeat 1px 14px/2px 2px, linear-gradient(to left, currentColor 4px, transparent 0) no-repeat 3px 16px/3px 3px;
}
.gg-digitalocean::after, .gg-digitalocean::before {
  content: "";
  display: block;
  position: absolute;
  box-sizing: border-box;
}
.gg-digitalocean::after {
  width: 4px;
  height: 4px;
  background: currentColor;
  bottom: 4px;
  left: 6px;
}
.gg-digitalocean::before {
  width: 20px;
  height: 20px;
  border: 4px solid;
  transform: rotate(-45deg);
  border-radius: 100px;
  border-left-color: transparent;
}

.gg-disc {
  display: block;
  box-sizing: border-box;
  border: 2px solid;
  border-radius: 50%;
  border-top-color: transparent;
  border-bottom-color: transparent;
  transform: rotate(45deg) scale(var(--ggs, 1));
  position: relative;
  width: 14px;
  height: 14px;
}
.gg-disc::after, .gg-disc::before {
  display: block;
  box-sizing: border-box;
  border: 2px solid;
  border-radius: 50%;
}
.gg-disc::after, .gg-disc::before {
  content: "";
  position: absolute;
  width: 6px;
  height: 6px;
  top: 2px;
  left: 2px;
}
.gg-disc::after {
  width: 22px;
  height: 22px;
  border-radius: 100%;
  top: -6px;
  left: -6px;
}

.gg-display-flex {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  height: 14px;
  width: 16px;
  border: 2px solid transparent;
  box-shadow: 0 0 0 2px, inset -2px 0 0 0, inset 2px 0 0 0;
}

.gg-display-fullwidth {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  height: 14px;
  width: 16px;
  box-shadow: inset 0 0 0 2px, 0 2px 0 0, 0 -2px 0 0;
  border-top: 2px solid transparent;
  border-bottom: 2px solid transparent;
}

.gg-display-grid {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  height: 14px;
  width: 14px;
  border: 2px solid transparent;
  box-shadow: 0 0 0 2px;
}
.gg-display-grid::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 10px;
  height: 4px;
  border-right: 4px solid;
  border-left: 4px solid;
  top: 0;
  left: 0;
}
.gg-display-grid::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 10px;
  height: 4px;
  border-right: 4px solid;
  border-left: 4px solid;
  top: 0;
  left: 0;
  top: 6px;
}

.gg-display-spacing {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  height: 14px;
  width: 14px;
  box-shadow: inset 0 0 0 2px, 2px 0 0 0, -2px 0 0 0;
  border-left: 2px solid transparent;
  border-right: 2px solid transparent;
}

.gg-distribute-horizontal {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  height: 14px;
  width: 14px;
  border-left: 2px solid;
  border-right: 2px solid;
}
.gg-distribute-horizontal::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  height: 8px;
  width: 4px;
  background: currentColor;
  right: 3px;
  top: 3px;
  opacity: 0.5;
}

.gg-distribute-vertical {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  height: 14px;
  width: 14px;
  border-top: 2px solid;
  border-bottom: 2px solid;
}
.gg-distribute-vertical::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  height: 4px;
  width: 8px;
  background: currentColor;
  right: 3px;
  top: 3px;
  opacity: 0.5;
}

.gg-dock-bottom {
  display: block;
  transform: scale(var(--ggs, 1));
  position: relative;
  box-sizing: border-box;
  width: 20px;
  height: 16px;
  border: 2px solid;
  border-bottom-width: 6px;
}

.gg-dock-left {
  display: block;
  transform: scale(var(--ggs, 1));
  position: relative;
  box-sizing: border-box;
  width: 20px;
  height: 16px;
  border: 2px solid;
  border-left-width: 6px;
}

.gg-dock-right {
  display: block;
  transform: scale(var(--ggs, 1));
  position: relative;
  box-sizing: border-box;
  width: 20px;
  height: 16px;
  border: 2px solid;
  border-right-width: 6px;
}

.gg-dock-window {
  display: block;
  transform: scale(var(--ggs, 1));
  position: relative;
  box-sizing: border-box;
  width: 20px;
  height: 16px;
  border: 2px solid;
  border-left-width: 4px;
  border-bottom-width: 4px;
}

.gg-dolby {
  display: block;
  box-sizing: border-box;
  height: 16px;
  border: 4px solid;
  position: relative;
  transform: scale(var(--ggs, 1));
  width: 24px;
}
.gg-dolby::after, .gg-dolby::before {
  display: block;
  box-sizing: border-box;
  height: 16px;
  border: 4px solid;
}
.gg-dolby::after {
  content: "";
  position: absolute;
  width: 14px;
  top: -4px;
}
.gg-dolby::before {
  content: "";
  position: absolute;
  width: 14px;
  top: -4px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  left: -4px;
}
.gg-dolby::after {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  right: -4px;
}

.gg-dollar {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 2px;
  height: 20px;
  background: currentColor;
}
.gg-dollar::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 10px;
  height: 8px;
  border: 2px solid;
}
.gg-dollar::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 10px;
  height: 8px;
  border: 2px solid;
  border-right: 0;
  border-top-left-radius: 100px;
  border-bottom-left-radius: 100px;
  top: 3px;
  left: -6px;
  box-shadow: 4px -2px 0 -2px;
}
.gg-dollar::after {
  border-left: 0;
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
  bottom: 3px;
  right: -6px;
  box-shadow: -4px 2px 0 -2px;
}

.gg-dribbble {
  background: radial-gradient(circle at 50%, transparent 0, transparent 9px, currentColor 9.2px, currentColor 11px, transparent 11.2px) no-repeat -8px center;
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: rotate(-25deg) scale(var(--ggs, 1));
  width: 22px;
  height: 22px;
  box-shadow: inset 0 0 0 2px;
  border-radius: 50%;
  overflow: hidden;
}
.gg-dribbble::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border-radius: 50%;
  border: 2px solid;
  height: 26px;
}
.gg-dribbble::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border-radius: 50%;
  border: 2px solid;
  height: 26px;
  width: 36px;
  left: -6px;
  top: -15px;
}
.gg-dribbble::after {
  top: 13px;
  left: -7px;
  width: 31px;
}

.gg-drive {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 26px;
  height: 10px;
  border: 2px solid;
  border-radius: 2px;
}
.gg-drive::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 2px;
  height: 2px;
  border-radius: 10px;
  background: currentColor;
  top: 2px;
  right: 2px;
}
.gg-drive::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 2px;
  height: 2px;
  border-radius: 10px;
  background: currentColor;
  top: 2px;
  right: 2px;
  right: 6px;
}

.gg-drop-invert {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: rotate(45deg) scale(var(--ggs, 1));
  width: 16px;
  height: 16px;
  border-radius: 0 100% 100% 100%;
  box-shadow: inset 0 0 0 2px;
  overflow: hidden;
}
.gg-drop-invert::before {
  content: "";
  display: block;
  position: absolute;
  box-sizing: border-box;
  width: 24px;
  height: 24px;
  background: currentColor;
  transform: rotate(-45deg);
  right: -15px;
  top: -10px;
}

.gg-drop-opacity {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: rotate(45deg) scale(var(--ggs, 1));
  width: 16px;
  height: 16px;
  border-radius: 0 100% 100% 100%;
  box-shadow: inset 0 0 0 2px;
  overflow: hidden;
}
.gg-drop-opacity::before {
  content: "";
  display: block;
  position: absolute;
  box-sizing: border-box;
  width: 24px;
  height: 24px;
  background: currentColor;
  transform: rotate(-45deg);
  right: -10px;
  bottom: -16px;
}

.gg-drop {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: rotate(45deg) scale(var(--ggs, 1));
  width: 16px;
  height: 16px;
  border-radius: 0 100% 100% 100%;
  box-shadow: inset 0 0 0 2px;
}

.gg-duplicate {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 10px;
  height: 10px;
  border: 2px solid transparent;
  border-bottom: 4px solid transparent;
  box-shadow: 0 0 0 2px, inset 0 -2px 0 0;
  margin-left: -4px;
  margin-top: 4px;
}
.gg-duplicate::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
}
.gg-duplicate::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  background: currentColor;
  left: 2px;
  bottom: -2px;
  width: 2px;
  height: 6px;
}
.gg-duplicate::after {
  width: 14px;
  height: 14px;
  border-right: 2px solid;
  border-top: 2px solid;
  right: -8px;
  top: -8px;
}

.gg-edit-black-point {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 16px;
  height: 16px;
  border: 2px solid transparent;
  box-shadow: 0 0 0 2px;
  border-radius: 100px;
  overflow: hidden;
}
.gg-edit-black-point::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 8px;
  height: 8px;
  border: 3px solid;
  border-radius: 6px;
  left: 2px;
  top: 2px;
}

.gg-edit-contrast {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 16px;
  height: 16px;
  border: 2px solid transparent;
  box-shadow: 0 0 0 2px;
  border-radius: 100px;
  overflow: hidden;
}
.gg-edit-contrast::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 6px;
  height: 12px;
  background: currentColor;
}

.gg-edit-exposure {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 16px;
  height: 16px;
  border: 2px solid transparent;
  box-shadow: 0 0 0 2px;
  border-radius: 100px;
  overflow: hidden;
}
.gg-edit-exposure::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 6px;
  height: 8px;
  border-top: 2px solid;
  border-bottom: 2px solid;
  left: 3px;
  top: 3px;
}
.gg-edit-exposure::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 2px;
  height: 6px;
  background: currentColor;
  left: 5px;
  top: 1px;
}

.gg-edit-fade {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 16px;
  height: 16px;
  border: 2px solid transparent;
  box-shadow: 0 0 0 2px;
  border-radius: 100px;
  overflow: hidden;
}
.gg-edit-fade::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 8px;
  height: 20px;
  left: -2px;
  background: currentColor;
  opacity: 0.6;
}
.gg-edit-fade::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 8px;
  height: 20px;
  left: -2px;
  background: currentColor;
  opacity: 0.6;
  left: 6px;
  opacity: 0.1;
}

.gg-edit-flip-h {
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 18px;
  height: 18px;
  transform: scale(var(--ggs, 1));
  background: linear-gradient(to left, currentColor 22px, transparent 0) no-repeat center/2px 22px;
}
.gg-edit-flip-h::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 6px;
  height: 14px;
  border: 2px solid;
  top: 2px;
}
.gg-edit-flip-h::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 6px;
  height: 14px;
  border: 2px solid;
  top: 2px;
  border-right: 0;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
.gg-edit-flip-h::after {
  opacity: 0.5;
  border-left: 0;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  right: 0;
}

.gg-edit-flip-v {
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 18px;
  height: 18px;
  transform: scale(var(--ggs, 1));
  background: linear-gradient(to left, currentColor 22px, transparent 0) no-repeat center/22px 2px;
}
.gg-edit-flip-v::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 14px;
  height: 6px;
  border: 2px solid;
  left: 2px;
}
.gg-edit-flip-v::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 14px;
  height: 6px;
  border: 2px solid;
  left: 2px;
  border-bottom: 0;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
.gg-edit-flip-v::after {
  opacity: 0.5;
  border-top: 0;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  bottom: 0;
}

.gg-edit-highlight {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 16px;
  height: 16px;
  border: 2px solid transparent;
  box-shadow: 0 0 0 2px;
  border-radius: 100px;
  overflow: hidden;
}
.gg-edit-highlight::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 6px;
  height: 14px;
  top: -1px;
  background: repeating-linear-gradient(to bottom, currentColor, currentColor 2px, transparent 0px, transparent 3px);
}
.gg-edit-highlight::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 6px;
  height: 14px;
  right: 0;
  background: currentColor;
}

.gg-edit-markup {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 22px;
  border: 2px solid;
  border-radius: 22px;
  overflow: hidden;
  perspective: 20px;
}
.gg-edit-markup::after {
  content: "";
  display: block;
  position: absolute;
  box-sizing: border-box;
}
.gg-edit-markup::before {
  content: "";
  display: block;
  position: absolute;
  box-sizing: border-box;
  width: 0;
  height: 6px;
  border-bottom: 6px solid;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  bottom: 9px;
  left: 6px;
}
.gg-edit-markup::after {
  width: 10px;
  height: 12px;
  border: 2px solid;
  border-top: 4px solid;
  border-bottom: 0;
  bottom: 0;
  left: 4px;
  transform: rotateX(60deg);
}

.gg-edit-mask {
  display: block;
  box-sizing: border-box;
  border-radius: 22px;
  position: relative;
  transform: scale(var(--ggs, 1));
  width: 20px;
  height: 20px;
  border: 2px solid;
  overflow: hidden;
}
.gg-edit-mask::after {
  display: block;
  box-sizing: border-box;
  border-radius: 22px;
  content: "";
  position: absolute;
  width: 4px;
  height: 4px;
  background: currentColor;
  top: 6px;
  left: 6px;
  box-shadow: 0 7px 0 1px, 0 -7px 0 1px, -7px 0 0 1px, 7px 0 0 1px;
}

.gg-edit-noise {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 16px;
  height: 16px;
  border: 2px solid transparent;
  box-shadow: 0 0 0 2px;
  border-radius: 100px;
  overflow: hidden;
}
.gg-edit-noise::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 12px;
  height: 12px;
  background-image: radial-gradient(circle, currentColor 25%, transparent 25%), radial-gradient(circle, currentColor 25%, transparent 25%);
  background-position: 4px 4px, 2px 2px;
  background-size: 4px 4px;
}

.gg-edit-shadows {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 16px;
  height: 16px;
  border: 2px solid transparent;
  box-shadow: 0 0 0 2px;
  border-radius: 100px;
  overflow: hidden;
}
.gg-edit-shadows::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 6px;
  height: 14px;
  right: 0;
  top: -1px;
  background: repeating-linear-gradient(to bottom, currentColor, currentColor 2px, transparent 0px, transparent 3px);
}
.gg-edit-shadows::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 6px;
  height: 14px;
  left: 0;
  background: currentColor;
}

.gg-edit-straight {
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 22px;
  height: 16px;
  transform: scale(var(--ggs, 1));
  background: linear-gradient(to left, currentColor 22px, transparent 0) no-repeat left center/4px 2px, linear-gradient(to left, currentColor 22px, transparent 0) no-repeat right center/4px 2px;
}
.gg-edit-straight::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 14px;
  height: 7px;
  background: currentColor;
  left: 4px;
}
.gg-edit-straight::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 14px;
  height: 7px;
  background: currentColor;
  left: 4px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.gg-edit-straight::after {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  bottom: 0;
}

.gg-edit-unmask {
  display: block;
  box-sizing: border-box;
  border-radius: 22px;
  position: relative;
  transform: scale(var(--ggs, 1));
  width: 20px;
  height: 20px;
  border: 2px solid transparent;
}
.gg-edit-unmask::after {
  display: block;
  box-sizing: border-box;
  border-radius: 22px;
  content: "";
  position: absolute;
  width: 4px;
  height: 4px;
  background: currentColor;
  top: 6px;
  left: 6px;
  box-shadow: 0 7px 0 1px, 0 -7px 0 1px, -7px 0 0 1px, 7px 0 0 1px;
}

.gg-eject {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 22px;
  border: 2px solid transparent;
  border-radius: 100px;
}
.gg-eject::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
}
.gg-eject::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border-bottom: 2px solid;
  width: 12px;
  height: 2px;
  bottom: 0;
  left: 3px;
}
.gg-eject::after {
  width: 8px;
  height: 8px;
  border-top: 2px solid;
  border-left: 2px solid;
  transform: rotate(45deg);
  left: 5px;
  bottom: 1px;
}

.gg-enter {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 16px;
  height: 18px;
  border: 2px solid;
  border-left: 0;
  box-shadow: -8px -6px 0 -6px, -8px 6px 0 -6px;
}
.gg-enter::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  right: 4px;
}
.gg-enter::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  right: 4px;
  background: currentColor;
  width: 16px;
  height: 2px;
  top: 6px;
}
.gg-enter::after {
  width: 6px;
  height: 6px;
  border-right: 2px solid;
  border-top: 2px solid;
  top: 4px;
  transform: rotate(45deg);
}

.gg-erase {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 18px;
}
.gg-erase::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
}
.gg-erase::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 6px;
  height: 14px;
  border-bottom: 4px solid transparent;
  border-radius: 1px;
  box-shadow: 0 0 0 2px, inset 0 -2px 0 0;
  left: 7px;
  top: 2px;
  transform: rotate(45deg);
}
.gg-erase::after {
  background: currentColor;
  width: 22px;
  height: 2px;
  bottom: 0;
  border-radius: 20px;
}

.gg-ereader {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 24px;
  height: 18px;
  border: 2px solid;
  border-radius: 3px;
}
.gg-ereader::after, .gg-ereader::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border-radius: 3px;
  width: 2px;
  height: 18px;
  right: 9px;
  top: -2px;
  background: currentColor;
}
.gg-ereader::after {
  width: 5px;
  height: 2px;
  box-shadow: 0 4px 0, 0 8px 0;
  top: 2px;
  right: 2px;
}

.gg-ericsson {
  display: block;
  box-sizing: border-box;
  position: relative;
  transform: scale(var(--ggs, 1));
  width: 14px;
  height: 16px;
}
.gg-ericsson::before {
  display: block;
  box-sizing: border-box;
  content: "";
  position: absolute;
  background: currentColor;
  transform: rotate(-25deg);
  width: 16px;
  height: 4px;
  border-radius: 4px;
  box-shadow: 3px -6px 0, -3px 6px 0;
  top: 7px;
  left: 2px;
}

.gg-ethernet {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 16px;
  height: 20px;
  border: 2px solid;
  box-shadow: 0 10px 0 -7px;
}
.gg-ethernet::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  top: 6px;
}
.gg-ethernet::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  top: 6px;
  width: 8px;
  height: 8px;
  border: 2px solid;
  border-top: 0;
  left: 2px;
}
.gg-ethernet::after {
  width: 16px;
  height: 2px;
  border-left: 6px solid;
  border-right: 6px solid;
  left: -2px;
}

.gg-euro {
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 14px;
  height: 14px;
  border: 2px solid;
  box-sizing: border-box;
  border-radius: 100px;
  border-right-color: transparent;
}
.gg-euro::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 9px;
  height: 5px;
  border-top: 2px solid;
  border-bottom: 2px solid;
  transform: skewX(-20deg);
  left: -4px;
  top: 3px;
}

.gg-eventbrite {
  display: block;
  position: relative;
  box-sizing: border-box;
  transform: rotate(-25deg) scale(var(--ggs, 1));
  width: 20px;
  height: 20px;
}
.gg-eventbrite::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 18px;
  height: 11px;
  border: 4px solid;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
}
.gg-eventbrite::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 18px;
  height: 18px;
  border: 4px solid;
  border-top-color: transparent;
  border-right-color: transparent;
  border-radius: 20px;
}

.gg-expand {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 6px;
  height: 6px;
  border-bottom: 2px solid;
  border-left: 2px solid;
}
.gg-expand::after, .gg-expand::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
}
.gg-expand::after {
  background: currentColor;
  bottom: 4px;
  transform: rotate(-44deg);
  width: 14px;
  height: 2px;
  left: -2px;
}
.gg-expand::before {
  width: 6px;
  height: 6px;
  border-top: 2px solid;
  border-right: 2px solid;
  left: 5px;
  top: -7px;
}

.gg-export {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 18px;
  height: 14px;
  border: 2px solid;
  border-top: 0;
  box-shadow: -6px -8px 0 -6px, 6px -8px 0 -6px;
}
.gg-export::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
}
.gg-export::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  background: currentColor;
  width: 2px;
  height: 14px;
  right: 6px;
  bottom: 5px;
}
.gg-export::after {
  width: 6px;
  height: 6px;
  border-left: 2px solid;
  border-top: 2px solid;
  right: 4px;
  bottom: 14px;
  transform: rotate(45deg);
}

.gg-extension-add {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  background: linear-gradient(to left, currentColor 10px, transparent 0) no-repeat 10px 2px/6px 2px, linear-gradient(to left, currentColor 10px, transparent 0) no-repeat 12px 0/2px 6px;
  width: 16px;
  height: 16px;
}
.gg-extension-add::after, .gg-extension-add::before {
  content: "";
  display: block;
  position: absolute;
  box-sizing: border-box;
  width: 8px;
  height: 14px;
  border: 2px solid;
  bottom: 0;
}
.gg-extension-add::after {
  width: 14px;
  height: 8px;
}

.gg-extension-alt {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 14px;
  height: 14px;
}
.gg-extension-alt::after, .gg-extension-alt::before {
  content: "";
  display: block;
  position: absolute;
  box-sizing: border-box;
  width: 8px;
  height: 14px;
  border: 2px solid;
}
.gg-extension-alt::after {
  width: 14px;
  height: 8px;
}

.gg-extension-remove {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  background: linear-gradient(to left, currentColor 10px, transparent 0) no-repeat 10px 4px/6px 2px;
  width: 16px;
  height: 16px;
}
.gg-extension-remove::after, .gg-extension-remove::before {
  content: "";
  display: block;
  position: absolute;
  box-sizing: border-box;
  width: 8px;
  height: 14px;
  border: 2px solid;
  bottom: 0;
}
.gg-extension-remove::after {
  width: 14px;
  height: 8px;
}

.gg-extension {
  box-sizing: border-box;
  display: block;
  border: 2px solid;
  position: relative;
  transform: translateX(-10px) scale(var(--ggs, 1));
  width: 8px;
  height: 14px;
}
.gg-extension::after, .gg-extension::before {
  box-sizing: border-box;
  display: block;
  border: 2px solid;
}
.gg-extension::after {
  content: "";
  position: absolute;
  height: 8px;
}
.gg-extension::before {
  content: "";
  position: absolute;
  height: 8px;
  bottom: -2px;
  left: -2px;
  width: 14px;
}
.gg-extension::after {
  top: -6px;
  right: -12px;
  width: 8px;
}

.gg-external {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 12px;
  height: 12px;
  box-shadow: -2px 2px 0 0, -4px -4px 0 -2px, 4px 4px 0 -2px;
  margin-left: -2px;
  margin-top: 1px;
}
.gg-external::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  right: -4px;
}
.gg-external::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  right: -4px;
  background: currentColor;
  transform: rotate(-45deg);
  width: 12px;
  height: 2px;
  top: 1px;
}
.gg-external::after {
  width: 8px;
  height: 8px;
  border-right: 2px solid;
  border-top: 2px solid;
  top: -4px;
}

.gg-eye-alt {
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 24px;
  height: 18px;
  border-bottom-right-radius: 100px;
  border-bottom-left-radius: 100px;
  overflow: hidden;
  box-sizing: border-box;
}
.gg-eye-alt::after, .gg-eye-alt::before {
  content: "";
  display: block;
  border-radius: 100px;
  position: absolute;
  box-sizing: border-box;
}
.gg-eye-alt::after {
  top: 2px;
  box-shadow: inset 0 -8px 0 2px, inset 0 0 0 2px;
  width: 24px;
  height: 24px;
}
.gg-eye-alt::before {
  width: 8px;
  height: 8px;
  border: 2px solid transparent;
  box-shadow: inset 0 0 0 6px, 0 0 0 4px, 6px 0 0 0, -6px 0 0 0;
  bottom: 4px;
  left: 8px;
}

.gg-eye {
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 24px;
  height: 18px;
  border-bottom-right-radius: 100px;
  border-bottom-left-radius: 100px;
  overflow: hidden;
  box-sizing: border-box;
}
.gg-eye::after, .gg-eye::before {
  content: "";
  display: block;
  border-radius: 100px;
  position: absolute;
  box-sizing: border-box;
}
.gg-eye::after {
  top: 2px;
  box-shadow: inset 0 -8px 0 2px, inset 0 0 0 2px;
  width: 24px;
  height: 24px;
}
.gg-eye::before {
  width: 8px;
  height: 8px;
  border: 2px solid;
  bottom: 4px;
  left: 8px;
}

.gg-facebook {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 20px;
  height: 20px;
}
.gg-facebook::after {
  content: "";
  display: block;
  position: absolute;
  box-sizing: border-box;
}
.gg-facebook::before {
  content: "";
  display: block;
  position: absolute;
  box-sizing: border-box;
  width: 8px;
  height: 20px;
  border-left: 4px solid;
  border-top: 4px solid;
  border-top-left-radius: 5px;
  left: 6px;
}
.gg-facebook::after {
  width: 10px;
  height: 4px;
  background: currentColor;
  top: 7px;
  left: 4px;
  transform: skew(-5deg);
}

.gg-feed {
  display: block;
  box-sizing: border-box;
  background: currentColor;
  box-shadow: 0 10px 0 0;
  margin-left: -8px;
  margin-top: -8px;
  position: relative;
  transform: scale(var(--ggs, 1));
  width: 7px;
  height: 7px;
  border-radius: 1px;
}
.gg-feed::after, .gg-feed::before {
  display: block;
  box-sizing: border-box;
  background: currentColor;
  box-shadow: 0 10px 0 0;
}
.gg-feed::after, .gg-feed::before {
  content: "";
  position: absolute;
  border-radius: 3px;
  width: 12px;
  height: 2px;
  top: 1px;
  left: 9px;
  opacity: 0.8;
}
.gg-feed::after {
  width: 8px;
  top: 4px;
  opacity: 0.5;
}

.gg-figma {
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 14px;
  height: 20px;
  transform: scale(var(--ggs, 1));
  background: linear-gradient(to left, currentColor 3px, transparent 0) no-repeat 3px 7px/3px 6px, linear-gradient(to left, currentColor 3px, transparent 0) no-repeat 3px 14px/3px 3px;
}
.gg-figma::after, .gg-figma::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 13px;
  height: 6px;
  background: currentColor;
  border-radius: 22px;
}
.gg-figma::after {
  width: 6px;
  top: 7px;
  box-shadow: 7px 0 0, 0 7px 0;
}

.gg-file-add {
  background: linear-gradient(to bottom, currentColor 5px, transparent 0) no-repeat 2px 9px/6px 2px;
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 14px;
  height: 16px;
  border: 2px solid transparent;
  border-right: 0;
  border-top: 0;
  box-shadow: 0 0 0 2px;
  border-radius: 1px;
  border-top-right-radius: 4px;
  overflow: hidden;
}
.gg-file-add::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  height: 6px;
}
.gg-file-add::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  height: 6px;
  background: currentColor;
  left: 4px;
  width: 2px;
  top: 7px;
}
.gg-file-add::after {
  width: 6px;
  border-left: 2px solid;
  border-bottom: 2px solid;
  right: -1px;
  top: -1px;
}

.gg-file-document {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 14px;
  height: 16px;
  border: 2px solid transparent;
  border-right: 0;
  border-top: 0;
  box-shadow: 0 0 0 2px;
  border-radius: 1px;
  border-top-right-radius: 4px;
  overflow: hidden;
}
.gg-file-document::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
}
.gg-file-document::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  background: currentColor;
  box-shadow: 0 4px 0, -6px -4px 0;
  left: 0;
  width: 10px;
  height: 2px;
  top: 8px;
}
.gg-file-document::after {
  width: 6px;
  height: 6px;
  border-left: 2px solid;
  border-bottom: 2px solid;
  right: -1px;
  top: -1px;
}

.gg-file-remove {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 14px;
  height: 16px;
  border: 2px solid transparent;
  border-right: 0;
  border-top: 0;
  box-shadow: 0 0 0 2px;
  border-radius: 1px;
  border-top-right-radius: 4px;
  overflow: hidden;
}
.gg-file-remove::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 6px;
}
.gg-file-remove::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 6px;
  background: currentColor;
  left: 2px;
  height: 2px;
  top: 9px;
}
.gg-file-remove::after {
  height: 6px;
  border-left: 2px solid;
  border-bottom: 2px solid;
  right: -1px;
  top: -1px;
}

.gg-file {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 14px;
  height: 16px;
  border: 2px solid transparent;
  border-right: 0;
  border-top: 0;
  box-shadow: 0 0 0 2px;
  border-radius: 1px;
  border-top-right-radius: 4px;
  overflow: hidden;
}
.gg-file::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 6px;
  height: 6px;
  border-left: 2px solid;
  border-bottom: 2px solid;
  right: -1px;
  top: -1px;
}

.gg-film {
  display: block;
  box-sizing: border-box;
  border-radius: 3px;
  border: 2px solid;
  position: relative;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 18px;
}
.gg-film::after {
  display: block;
  box-sizing: border-box;
  border-radius: 3px;
  content: "";
  position: absolute;
  width: 2px;
  height: 2px;
  background: currentColor;
  left: 2px;
  top: 2px;
  box-shadow: 0 4px 0, 0 8px 0, 12px 0 0, 12px 4px 0, 12px 8px 0;
}

.gg-filters {
  display: block;
  box-sizing: border-box;
  position: relative;
  transform: scale(var(--ggs, 1));
  width: 19px;
  height: 19px;
  background: radial-gradient(circle, currentColor 26%, transparent 26%);
}
.gg-filters::after, .gg-filters::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border: 2px solid;
  border-radius: 100%;
  width: 14px;
  height: 14px;
}
.gg-filters::after {
  bottom: 0;
  right: 0;
}

.gg-flag-alt {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 14px;
  height: 20px;
  border-left: 2px solid;
}
.gg-flag-alt::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border-bottom: 2px solid;
}
.gg-flag-alt::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border-bottom: 2px solid;
  width: 12px;
  height: 10px;
  border-top: 2px solid;
  border-right: 2px solid transparent;
}
.gg-flag-alt::after {
  width: 8px;
  height: 8px;
  border-left: 2px solid;
  border-top-left-radius: 2px;
  border-bottom-right-radius: 2px;
  transform: rotate(45deg);
  top: 1px;
  left: 6px;
}

.gg-flag {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 18px;
  border-left: 2px solid;
}
.gg-flag::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 8px;
  height: 8px;
}
.gg-flag::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 8px;
  height: 8px;
  border-top: 2px solid;
  border-bottom: 2px solid;
}
.gg-flag::after {
  border: 2px solid;
  border-left: 0;
  top: 2px;
  left: 7px;
}

.gg-folder-add {
  background: linear-gradient(to left, currentColor 10px, transparent 0) no-repeat center center/2px 6px;
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 16px;
  border: 2px solid;
  border-radius: 3px;
}
.gg-folder-add::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
}
.gg-folder-add::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  background: currentColor;
  left: 6px;
  width: 6px;
  height: 2px;
  top: 5px;
}
.gg-folder-add::after {
  width: 10px;
  height: 4px;
  border: 2px solid;
  border-bottom: 0;
  border-top-left-radius: 2px;
  border-top-right-radius: 4px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  top: -5px;
}

.gg-folder-remove {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 16px;
  border: 2px solid;
  border-radius: 3px;
}
.gg-folder-remove::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border-radius: 3px;
}
.gg-folder-remove::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border-radius: 3px;
  background: currentColor;
  left: 5px;
  width: 8px;
  height: 2px;
  top: 5px;
}
.gg-folder-remove::after {
  width: 10px;
  height: 4px;
  border: 2px solid;
  border-bottom: 0;
  border-top-left-radius: 2px;
  border-top-right-radius: 4px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  top: -5px;
}

.gg-folder {
  transform: scale(var(--ggs, 1));
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 22px;
  height: 16px;
  border: 2px solid;
  border-radius: 3px;
}
.gg-folder::after {
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 22px;
  height: 16px;
  border: 2px solid;
  border-radius: 3px;
  content: "";
  position: absolute;
  width: 10px;
  height: 4px;
  border-bottom: 0;
  border-top-left-radius: 2px;
  border-top-right-radius: 4px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  top: -5px;
}

.gg-font-height {
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 18px;
  height: 18px;
  transform: scale(var(--ggs, 1));
  border-top: 2px solid;
  border-bottom: 2px solid;
  background: linear-gradient(to left, currentColor 10px, transparent 0) no-repeat center 8px/6px 2px;
}
.gg-font-height::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border-radius: 3px;
  width: 2px;
  height: 10px;
  background: currentColor;
  top: 2px;
}
.gg-font-height::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border-radius: 3px;
  width: 2px;
  height: 10px;
  background: currentColor;
  top: 2px;
  transform: rotate(25deg);
  left: 6px;
}
.gg-font-height::after {
  transform: rotate(-25deg);
  right: 6px;
}

.gg-font-spacing {
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 18px;
  height: 16px;
  transform: scale(var(--ggs, 1));
  border-left: 2px solid;
  border-right: 2px solid;
  background: linear-gradient(to left, currentColor 10px, transparent 0) no-repeat center 8px/6px 2px;
}
.gg-font-spacing::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border-radius: 3px;
  width: 2px;
  height: 10px;
  background: currentColor;
  top: 2px;
}
.gg-font-spacing::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border-radius: 3px;
  width: 2px;
  height: 10px;
  background: currentColor;
  top: 2px;
  transform: rotate(25deg);
  left: 4px;
}
.gg-font-spacing::after {
  transform: rotate(-25deg);
  right: 4px;
}

.gg-format-bold {
  transform: scale(var(--ggs, 1));
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 8px;
  height: 8px;
  border: 2px solid;
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
}
.gg-format-bold::after {
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 8px;
  height: 8px;
  border: 2px solid;
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
  content: "";
  position: absolute;
  width: 10px;
  left: -2px;
  top: 4px;
}

.gg-format-center {
  display: block;
  box-sizing: border-box;
  height: 2px;
  background: currentColor;
  border-radius: 3px;
  box-shadow: 0 8px 0;
  margin-top: -12px;
  position: relative;
  transform: scale(var(--ggs, 1));
  width: 16px;
}
.gg-format-center::before {
  display: block;
  box-sizing: border-box;
  height: 2px;
  background: currentColor;
  border-radius: 3px;
  box-shadow: 0 8px 0;
  content: "";
  position: absolute;
  width: 10px;
  top: 4px;
  left: 3px;
}

.gg-format-color {
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 9px;
  height: 2px;
  background: currentColor;
  transform: scale(var(--ggs, 1));
  border-radius: 3px;
  box-shadow: -2px 8px 0 0, 2px 8px 0 0;
}
.gg-format-color::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border-radius: 3px;
  width: 2px;
  height: 14px;
  background: currentColor;
  top: -8px;
}
.gg-format-color::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border-radius: 3px;
  width: 2px;
  height: 14px;
  background: currentColor;
  top: -8px;
  transform: rotate(25deg);
  left: 1px;
}
.gg-format-color::after {
  transform: rotate(-25deg);
  right: 1px;
}

.gg-format-heading {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 12px;
  height: 14px;
  border-left: 2px solid;
  border-right: 2px solid;
}
.gg-format-heading::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 12px;
  height: 2px;
  background: currentColor;
  top: 6px;
  left: -2px;
}

.gg-format-indent-decrease {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 16px;
  height: 14px;
  border-top: 2px solid;
  border-bottom: 2px solid;
}
.gg-format-indent-decrease::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  top: 2px;
}
.gg-format-indent-decrease::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  top: 2px;
  border-right: 4px solid;
  left: 1px;
  width: 0;
  height: 0;
  border-top: 3px solid transparent;
  border-bottom: 3px solid transparent;
}
.gg-format-indent-decrease::after {
  width: 8px;
  height: 6px;
  border-top: 2px solid;
  border-bottom: 2px solid;
  right: 0;
}

.gg-format-indent-increase {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 16px;
  height: 14px;
  border-top: 2px solid;
  border-bottom: 2px solid;
}
.gg-format-indent-increase::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  top: 2px;
}
.gg-format-indent-increase::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  top: 2px;
  border-left: 4px solid;
  left: 1px;
  width: 0;
  height: 0;
  border-top: 3px solid transparent;
  border-bottom: 3px solid transparent;
}
.gg-format-indent-increase::after {
  width: 8px;
  height: 6px;
  border-top: 2px solid;
  border-bottom: 2px solid;
  right: 0;
}

.gg-format-italic {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: skewX(-20deg) scale(var(--ggs, 1));
  width: 6px;
  height: 12px;
  border-top: 2px solid;
  border-bottom: 2px solid;
}
.gg-format-italic::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  height: 10px;
  width: 2px;
  background: currentColor;
  left: 2px;
  top: -2px;
}

.gg-format-justify {
  display: block;
  box-sizing: border-box;
  width: 16px;
  height: 2px;
  border-radius: 3px;
  background: currentColor;
  box-shadow: 0 8px 0;
  margin-top: -11px;
  transform: scale(var(--ggs, 1));
  position: relative;
}
.gg-format-justify::before {
  display: block;
  box-sizing: border-box;
  width: 16px;
  height: 2px;
  border-radius: 3px;
  background: currentColor;
  box-shadow: 0 8px 0;
  content: "";
  position: absolute;
  top: 4px;
  left: 0;
}

.gg-format-left {
  display: block;
  box-sizing: border-box;
  height: 2px;
  background: currentColor;
  border-radius: 3px;
  box-shadow: 0 8px 0;
  margin-top: -11px;
  position: relative;
  transform: scale(var(--ggs, 1));
  width: 16px;
}
.gg-format-left::before {
  display: block;
  box-sizing: border-box;
  height: 2px;
  background: currentColor;
  border-radius: 3px;
  box-shadow: 0 8px 0;
  content: "";
  position: absolute;
  width: 10px;
  top: 4px;
  left: 0;
}

.gg-format-line-height {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 14px;
  height: 10px;
  border-top: 2px solid transparent;
  border-bottom: 2px solid transparent;
  border-left: 4px solid transparent;
  box-shadow: -2px 0 0 0, 2px -4px 0 -2px, 2px 4px 0 -2px, inset 0 2px 0 0, inset 0 -2px 0 0;
}
.gg-format-line-height::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 0;
  height: 0;
  border-right: 3px solid transparent;
  border-left: 3px solid transparent;
  left: -8px;
}
.gg-format-line-height::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 0;
  height: 0;
  border-right: 3px solid transparent;
  border-left: 3px solid transparent;
  left: -8px;
  border-top: 4px solid;
  bottom: -5px;
}
.gg-format-line-height::after {
  border-bottom: 4px solid;
  top: -5px;
}

.gg-format-right {
  display: block;
  box-sizing: border-box;
  height: 2px;
  background: currentColor;
  border-radius: 3px;
  box-shadow: 0 8px 0;
  margin-top: -11px;
  position: relative;
  transform: scale(var(--ggs, 1));
  width: 16px;
}
.gg-format-right::before {
  display: block;
  box-sizing: border-box;
  height: 2px;
  background: currentColor;
  border-radius: 3px;
  box-shadow: 0 8px 0;
  content: "";
  position: absolute;
  width: 10px;
  top: 4px;
  left: 6px;
}

.gg-format-separator {
  transform: scale(var(--ggs, 1));
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 18px;
  height: 2px;
  border-radius: 3px;
  background: currentColor;
}
.gg-format-separator::after {
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 18px;
  height: 2px;
  border-radius: 3px;
  background: currentColor;
  content: "";
  position: absolute;
  top: -12px;
  left: 3px;
  opacity: 0.5;
  width: 12px;
  box-shadow: 0 4px 0 0, 0 8px 0 0, 0 16px 0 0;
}

.gg-format-slash {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: rotate(25deg) scale(var(--ggs, 1));
  width: 2px;
  height: 14px;
  background: currentColor;
  border-radius: 3px;
}

.gg-format-strike {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 10px;
  height: 3px;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  box-shadow: 0 -2px 0 0, inset 0 0 0 2px;
}
.gg-format-strike::after, .gg-format-strike::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 14px;
  height: 2px;
  background: currentColor;
  left: -6px;
  top: 4px;
}
.gg-format-strike::after {
  width: 2px;
  height: 4px;
  left: 0;
  top: 7px;
}

.gg-format-text {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 10px;
  height: 8px;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  box-shadow: 0 -2px 0 0, inset 0 0 0 2px;
}
.gg-format-text::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 18px;
  height: 18px;
  border: 1px dashed;
  top: -6px;
  left: -8px;
}

.gg-format-underline {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 12px;
  height: 12px;
  border: 2px solid;
  border-top: 0;
  border-bottom-left-radius: 100px;
  border-bottom-right-radius: 100px;
}
.gg-format-underline::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border-radius: 3px;
  width: 12px;
  height: 2px;
  background: currentColor;
  left: -2px;
  bottom: -6px;
}

.gg-format-uppercase {
  transform: scale(var(--ggs, 1));
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 8px;
  height: 8px;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  box-shadow: 0 -2px 0 0, inset 0 0 0 2px;
}
.gg-format-uppercase::after {
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 8px;
  height: 8px;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  box-shadow: 0 -2px 0 0, inset 0 0 0 2px;
  content: "";
  position: absolute;
  width: 6px;
  height: 4px;
  border-left: 2px solid transparent;
  border-right: 2px solid transparent;
  bottom: 0;
  right: -8px;
}

.gg-framer {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 12px;
  height: 20px;
  overflow: hidden;
}
.gg-framer::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  background: currentColor;
}
.gg-framer::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  background: currentColor;
  width: 20px;
  height: 6px;
  transform: skewX(45deg);
  top: 6px;
  left: -11px;
  box-shadow: 20px -6px 0;
}
.gg-framer::after {
  width: 6px;
  height: 8px;
  transform: skewY(45deg);
  bottom: 4px;
  border-top-left-radius: 4px;
  opacity: 0.5;
}

.gg-games {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 24px;
  height: 12px;
  border: 3px solid transparent;
  border-left: 6px solid transparent;
  box-shadow: 0 0 0 2px, inset 2px 0 0;
  border-radius: 40px;
}
.gg-games::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  height: 2px;
  background: currentColor;
}
.gg-games::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  height: 2px;
  background: currentColor;
  left: -2px;
  width: 6px;
  top: 2px;
}
.gg-games::after {
  border-radius: 3px;
  width: 2px;
  box-shadow: 0 4px 0, -2px 2px 0, 2px 2px 0;
  top: 0;
  right: 2px;
}

.gg-gender-female {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 10px;
  height: 10px;
  border: 2px solid;
  border-radius: 100%;
  margin-top: -4px;
}
.gg-gender-female::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 8px;
  height: 2px;
  background: currentColor;
  bottom: -6px;
  left: -1px;
}
.gg-gender-female::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 8px;
  height: 2px;
  background: currentColor;
  bottom: -6px;
  left: -1px;
  width: 2px;
  height: 9px;
  bottom: -9px;
  left: 2px;
}

.gg-gender-male {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 10px;
  height: 10px;
  border: 2px solid;
  border-radius: 100%;
  margin-left: -4px;
  margin-top: 4px;
}
.gg-gender-male::after, .gg-gender-male::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  height: 8px;
  top: -6px;
}
.gg-gender-male::after {
  border-right: 2px solid;
  border-top: 2px solid;
  width: 7px;
  height: 7px;
  left: 5px;
}
.gg-gender-male::before {
  width: 2px;
  background: currentColor;
  left: 7px;
  transform: rotate(45deg);
}

.gg-ghost-character {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 14px;
  height: 18px;
  border: 2px solid;
  border-bottom: 0;
  border-top-left-radius: 22px;
  border-top-right-radius: 22px;
  overflow: hidden;
  background: radial-gradient(circle, currentColor 60%, transparent 40%) no-repeat 2px 4px/2px 2px, radial-gradient(circle, currentColor 60%, transparent 40%) no-repeat 6px 4px/2px 2px;
}
.gg-ghost-character::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 6px;
  height: 6px;
  border-top: 2px solid;
  border-right: 2px solid;
  bottom: -3px;
  transform: rotate(-45deg);
}
.gg-ghost-character::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 6px;
  height: 6px;
  border-top: 2px solid;
  border-right: 2px solid;
  bottom: -3px;
  transform: rotate(-45deg);
  left: -1px;
}
.gg-ghost-character::after {
  right: -1px;
}

.gg-ghost {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 18px;
  height: 16px;
  background: linear-gradient(to left, currentColor 40px, transparent 0) no-repeat -6px center/40px 4px;
  overflow: hidden;
}
.gg-ghost::after, .gg-ghost::before {
  content: "";
  background: currentColor;
  box-sizing: border-box;
  position: absolute;
  display: block;
  height: 4px;
  width: 12px;
  box-shadow: 14px 0 0;
}
.gg-ghost::after {
  width: 20px;
  left: -12px;
  box-shadow: 22px 0 0;
  bottom: 0;
}

.gg-gift {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 14px;
  margin-top: 8px;
  border: 2px solid transparent;
  box-shadow: inset 2px 0 0, inset -2px 0 0, 0 -2px 0, inset 0 2px 0, inset 0 -2px 0;
  background: linear-gradient(to left, currentColor 10px, transparent 0), linear-gradient(to left, currentColor 10px, transparent 0), linear-gradient(to left, currentColor 10px, transparent 0);
  background-repeat: no-repeat;
  background-size: 2px 10px, 2px 10px, 2px 10px;
  background-position: 8px 0, 18px -8px, -2px -8px;
}
.gg-gift::after, .gg-gift::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border-radius: 3px;
  width: 6px;
  height: 8px;
  border: 2px solid;
  top: -10px;
}
.gg-gift::after {
  left: 3px;
  transform: rotate(-45deg);
}
.gg-gift::before {
  right: 3px;
  transform: rotate(45deg);
}

.gg-girl {
  display: block;
  box-sizing: border-box;
  border-radius: 42px;
  position: relative;
  transform: scale(var(--ggs, 1));
  width: 20px;
  height: 20px;
  overflow: hidden;
  box-shadow: inset 0 0 0 2px;
}
.gg-girl::after, .gg-girl::before {
  display: block;
  box-sizing: border-box;
  border-radius: 42px;
}
.gg-girl::after, .gg-girl::before {
  content: "";
  position: absolute;
  width: 2px;
  height: 2px;
  background: currentColor;
  box-shadow: 6px 0 0;
  left: 6px;
  top: 10px;
}
.gg-girl::after {
  width: 20px;
  height: 20px;
  top: -11px;
  left: -12px;
  box-shadow: 17px -4px 0 3px;
}

.gg-git-branch {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 2px;
  height: 14px;
  background: currentColor;
}
.gg-git-branch::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
}
.gg-git-branch::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border-right: 2px solid;
  border-bottom: 2px solid;
  border-bottom-right-radius: 4px;
  bottom: 0;
  width: 8px;
  height: 6px;
  left: 0;
}
.gg-git-branch::after {
  width: 4px;
  height: 4px;
  background: currentColor;
  box-shadow: 0 12px 0 0, 6px 6px 0 0;
  border-radius: 100%;
  left: -1px;
  top: -1px;
}

.gg-git-commit {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 2px;
  height: 16px;
  border-radius: 3px;
  border-top: 6px solid;
  border-bottom: 6px solid;
}
.gg-git-commit::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 2px;
  height: 2px;
  box-shadow: 0 0 0 2px;
  border-radius: 100%;
  left: 0;
  top: 1px;
}

.gg-git-fork {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 2px;
  height: 14px;
  background: currentColor;
}
.gg-git-fork::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
}
.gg-git-fork::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border-right: 2px solid;
  border-bottom: 2px solid;
  border-bottom-right-radius: 4px;
  bottom: 4px;
  width: 8px;
  height: 6px;
  left: 0;
}
.gg-git-fork::after {
  width: 4px;
  height: 4px;
  background: currentColor;
  box-shadow: 0 12px 0 0, 6px 2px 0 0;
  border-radius: 100%;
  left: -1px;
  top: -1px;
}

.gg-git-pull {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 2px;
  height: 14px;
  background: currentColor;
}
.gg-git-pull::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
}
.gg-git-pull::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border-right: 2px solid;
  border-top: 2px solid;
  border-top-right-radius: 4px;
  width: 8px;
  height: 6px;
  left: 0;
  top: 2px;
}
.gg-git-pull::after {
  width: 4px;
  height: 4px;
  background: currentColor;
  box-shadow: 0 12px 0 0, 6px 8px 0 0;
  border-radius: 100%;
  left: -1px;
  top: -1px;
}

.gg-gitter {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 14px;
  height: 18px;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  box-shadow: inset 2px 0 0, inset -2px 0 0;
}
.gg-gitter::after {
  content: "";
  position: absolute;
  box-sizing: border-box;
  display: block;
  width: 2px;
  background: currentColor;
}
.gg-gitter::before {
  content: "";
  position: absolute;
  box-sizing: border-box;
  display: block;
  width: 2px;
  background: currentColor;
  height: 13px;
  top: -3px;
  left: -4px;
}
.gg-gitter::after {
  height: 10px;
  left: 8px;
}

.gg-glass-alt {
  display: block;
  box-sizing: border-box;
  height: 20px;
  position: relative;
  transform: scale(var(--ggs, 1));
  width: 14px;
}
.gg-glass-alt::before {
  display: block;
  box-sizing: border-box;
  height: 20px;
  content: "";
  position: absolute;
  width: 10px;
  border: 2px solid;
  border-bottom: 3px solid;
  transform: perspective(20px) rotateX(-20deg);
  top: 2px;
  left: 2px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  background: linear-gradient(to left, currentColor 10px, transparent 0) no-repeat center 2px/6px 2px;
}

.gg-glass {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 6px;
  height: 13px;
  border-top: 8px solid transparent;
  border-left: 2px solid transparent;
  border-right: 2px solid transparent;
  box-shadow: inset 0 0 0 2px, 0 2px 0 0;
}
.gg-glass::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 10px;
  height: 10px;
  border: 2px solid;
  border-bottom-left-radius: 100px;
  border-bottom-right-radius: 100px;
  left: -4px;
  bottom: 3px;
}

.gg-globe-alt {
  display: block;
  box-sizing: border-box;
  height: 18px;
  border: 2px solid;
  position: relative;
  transform: scale(var(--ggs, 1));
  width: 18px;
  border-radius: 22px;
}
.gg-globe-alt::after, .gg-globe-alt::before {
  display: block;
  box-sizing: border-box;
  height: 18px;
  border: 2px solid;
}
.gg-globe-alt::after, .gg-globe-alt::before {
  content: "";
  position: absolute;
  width: 8px;
  border-radius: 100%;
  top: -2px;
  left: 3px;
}
.gg-globe-alt::after {
  width: 24px;
  height: 20px;
  border: 2px solid transparent;
  border-bottom: 2px solid;
  top: -11px;
  left: -5px;
}

.gg-globe {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 10px;
  height: 10px;
  border: 2px solid;
  border-radius: 100px;
  margin-left: -3px;
  margin-top: -7px;
}
.gg-globe::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
}
.gg-globe::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  top: 11px;
  border-right: 2px solid transparent;
  box-shadow: 0 2px 0, inset -2px 0 0;
  left: 1px;
  width: 6px;
  height: 2px;
}
.gg-globe::after {
  width: 16px;
  height: 10px;
  border-radius: 50px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border: 2px solid;
  border-top-color: transparent;
  right: -7px;
  bottom: -5px;
  transform: rotate(-35deg);
}

.gg-google-tasks {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 18px;
  height: 18px;
}
.gg-google-tasks::after {
  content: "";
  position: absolute;
  display: block;
  background: currentColor;
}
.gg-google-tasks::before {
  content: "";
  position: absolute;
  display: block;
  background: currentColor;
  width: 6px;
  height: 6px;
  border-radius: 10px;
  bottom: 6px;
  left: 2px;
}
.gg-google-tasks::after {
  height: 18px;
  width: 4px;
  border-radius: 20px;
  transform: rotate(40deg);
  right: 2px;
  bottom: 0;
}

.gg-google {
  box-sizing: border-box;
  display: block;
  position: relative;
  transform: scale(var(--ggs, 1));
  width: 20px;
  height: 20px;
  border: 4px solid;
  border-right-color: transparent;
  border-radius: 22px;
}
.gg-google::before {
  box-sizing: border-box;
  display: block;
  content: "";
  position: absolute;
  border-top: 4px solid;
  border-right: 4px solid;
  border-bottom: 4px solid;
  border-radius: 0 0 10px 0;
  width: 10px;
  height: 12px;
  right: -4px;
  bottom: -4px;
}

.gg-gym {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: rotate(-35deg) scale(var(--ggs, 1));
  width: 8px;
  height: 20px;
  border-top: 9px solid transparent;
  border-bottom: 9px solid transparent;
  box-shadow: inset 0 0 0 2px;
}
.gg-gym::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
}
.gg-gym::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  box-shadow: -3px 0 0 -1px, 3px 0 0 -1px, inset 2px 0 0 0, inset -2px 0 0 0;
  width: 14px;
  height: 8px;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  top: -3px;
  left: -3px;
}
.gg-gym::after {
  width: 22px;
  height: 2px;
  border-left: 2px solid;
  border-right: 2px solid;
  top: 0;
  left: -7px;
}

.gg-hashtag {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 8px;
  height: 16px;
  border-left: 2px solid;
  border-right: 2px solid;
}
.gg-hashtag::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 16px;
  height: 8px;
  border-top: 2px solid;
  border-bottom: 2px solid;
  left: -6px;
  top: 4px;
}

.gg-headset {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 18px;
  height: 18px;
  border-top-left-radius: 120px;
  border-top-right-radius: 120px;
  border: 2px solid;
  border-bottom: 0;
}
.gg-headset::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border: 2px solid;
  width: 6px;
  height: 8px;
  top: 8px;
}
.gg-headset::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border: 2px solid;
  width: 6px;
  height: 8px;
  top: 8px;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  left: -2px;
}
.gg-headset::after {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  left: 10px;
}

.gg-heart {
  border: 2px solid;
  border-top-left-radius: 100px;
  border-top-right-radius: 100px;
  width: 10px;
  height: 8px;
  border-bottom: 0;
  box-sizing: border-box;
  position: relative;
  transform: translate(calc(-5px * var(--ggs, 1)), calc(-3px * var(--ggs, 1))) rotate(-45deg) scale(var(--ggs, 1));
  display: block;
}
.gg-heart::after {
  border: 2px solid;
  border-top-left-radius: 100px;
  border-top-right-radius: 100px;
  width: 10px;
  height: 8px;
  border-bottom: 0;
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
}
.gg-heart::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
}
.gg-heart::after {
  right: -9px;
  transform: rotate(90deg);
  top: 5px;
}
.gg-heart::before {
  width: 11px;
  height: 11px;
  border-left: 2px solid;
  border-bottom: 2px solid;
  left: -2px;
  top: 3px;
}

.gg-hello {
  display: block;
  position: relative;
  box-sizing: border-box;
  transform: scale(var(--ggs, 1));
  overflow: hidden;
  width: 20px;
  height: 20px;
  border-radius: 6px;
  border-bottom-left-radius: 0;
}
.gg-hello::after {
  content: "";
  display: block;
  position: absolute;
  box-sizing: border-box;
}
.gg-hello::before {
  content: "";
  display: block;
  position: absolute;
  box-sizing: border-box;
  width: 30px;
  height: 30px;
  border: 8px solid;
  left: -5px;
  top: -5px;
  border-radius: 30px;
}
.gg-hello::after {
  width: 10px;
  height: 5px;
  background: currentColor;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  bottom: 5px;
  left: 5px;
}

.gg-home-alt {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 18px;
  height: 14px;
  border: 2px solid;
  border-top: 0;
  border-radius: 2px;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  margin-bottom: -2px;
}
.gg-home-alt::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
}
.gg-home-alt::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border-top: 2px solid;
  border-left: 2px solid;
  border-top-left-radius: 4px;
  transform: rotate(45deg);
  top: -5px;
  border-radius: 3px;
  width: 14px;
  height: 14px;
  left: 0;
}
.gg-home-alt::after {
  width: 6px;
  height: 10px;
  background: currentColor;
  border-top-left-radius: 100px;
  border-top-right-radius: 100px;
  left: 4px;
  bottom: -2px;
}

.gg-home-screen {
  display: block;
  position: relative;
  box-sizing: border-box;
  transform: scale(var(--ggs, 1));
  width: 14px;
  height: 22px;
  border-radius: 3px;
  border: 2px solid;
}
.gg-home-screen::after, .gg-home-screen::before {
  content: "";
  display: block;
  position: absolute;
  box-sizing: border-box;
  border-radius: 2px;
  width: 2px;
  height: 2px;
  left: 1px;
  background: currentColor;
}
.gg-home-screen::after {
  top: 1px;
  box-shadow: 3px 0, 6px 0, 0 3px, 3px 3px, 6px 3px, 0 6px;
}
.gg-home-screen::before {
  bottom: 1px;
  box-shadow: 3px 0 0, 6px 0 0;
}

.gg-home {
  background: linear-gradient(to left, currentColor 5px, transparent 0) no-repeat 0 bottom/4px 2px, linear-gradient(to left, currentColor 5px, transparent 0) no-repeat right bottom/4px 2px;
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 18px;
  height: 14px;
  border: 2px solid;
  border-top: 0;
  border-bottom: 0;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  margin-bottom: -2px;
}
.gg-home::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
}
.gg-home::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border-top: 2px solid;
  border-left: 2px solid;
  border-top-left-radius: 4px;
  transform: rotate(45deg);
  top: -5px;
  border-radius: 3px;
  width: 14px;
  height: 14px;
  left: 0;
}
.gg-home::after {
  width: 8px;
  height: 10px;
  border: 2px solid;
  border-radius: 100px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom: 0;
  left: 3px;
  bottom: 0;
}

.gg-icecream {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 14px;
  height: 16px;
  border: 2px solid;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  margin-top: -8px;
  border-bottom: 0;
}
.gg-icecream::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
}
.gg-icecream::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border-left: 4px solid;
  border-right: 4px solid;
  width: 12px;
  height: 2px;
  bottom: 0;
  left: -2px;
}
.gg-icecream::after {
  width: 6px;
  height: 8px;
  border: 2px solid;
  bottom: -6px;
  left: 2px;
  border-bottom-left-radius: 100px;
  border-bottom-right-radius: 100px;
  border-top: 0;
}

.gg-if-design {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 14px;
  height: 14px;
  background: linear-gradient(to left, currentColor 18px, transparent 0) no-repeat center center/4px 18px, linear-gradient(to left, currentColor 5px, transparent 0) no-repeat 10px top/4px 4px, linear-gradient(to left, currentColor 5px, transparent 0) no-repeat 10px 5px/4px 4px, linear-gradient(to left, currentColor 14px, transparent 0) no-repeat 0 5px/4px 14px;
}
.gg-if-design::before {
  content: "";
  display: block;
  position: absolute;
  width: 4px;
  height: 4px;
  border-radius: 10px;
  background-color: currentColor;
}

.gg-image {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 20px;
  height: 16px;
  overflow: hidden;
  box-shadow: 0 0 0 2px;
  border-radius: 2px;
}
.gg-image::after, .gg-image::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border: 2px solid;
}
.gg-image::after {
  transform: rotate(45deg);
  border-radius: 3px;
  width: 16px;
  height: 16px;
  top: 9px;
  left: 6px;
}
.gg-image::before {
  width: 6px;
  height: 6px;
  border-radius: 100%;
  top: 2px;
  left: 2px;
}

.gg-import {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 18px;
  height: 14px;
  border: 2px solid;
  border-top: 0;
  box-shadow: -6px -8px 0 -6px, 6px -8px 0 -6px;
}
.gg-import::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
}
.gg-import::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  background: currentColor;
  width: 2px;
  height: 14px;
  right: 6px;
  bottom: 5px;
}
.gg-import::after {
  width: 6px;
  height: 6px;
  border-right: 2px solid;
  border-bottom: 2px solid;
  right: 4px;
  bottom: 4px;
  transform: rotate(45deg);
}

.gg-inbox {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 18px;
  height: 18px;
  border: 2px solid;
  box-shadow: inset 0 -2px 0 0;
  border-radius: 3px;
}
.gg-inbox::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  background: currentColor;
  height: 7px;
  width: 7px;
  border-radius: 2px;
  bottom: -2px;
}
.gg-inbox::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  background: currentColor;
  height: 7px;
  width: 7px;
  border-radius: 2px;
  bottom: -2px;
  left: -2px;
}
.gg-inbox::after {
  right: -2px;
}

.gg-indie-hackers {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 16px;
  height: 12px;
}
.gg-indie-hackers::after {
  content: "";
  position: absolute;
  box-sizing: border-box;
  display: block;
  height: 12px;
}
.gg-indie-hackers::before {
  content: "";
  position: absolute;
  box-sizing: border-box;
  display: block;
  height: 12px;
  width: 3px;
  background: currentColor;
}
.gg-indie-hackers::after {
  width: 10px;
  right: 0;
  border-left: 3px solid;
  border-right: 3px solid;
  background: linear-gradient(to left, currentColor 14px, transparent 0) no-repeat 0 center/14px 3px;
}

.gg-infinity {
  background: linear-gradient(to left, currentColor 5px, transparent 0) no-repeat -4px 4px/2px 2px, linear-gradient(to left, currentColor 5px, transparent 0) no-repeat 4px 4px/2px 2px;
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: rotate(-45deg) scale(var(--ggs, 1));
  width: 10px;
  height: 18px;
  border: 4px solid transparent;
  box-shadow: inset 0 0 0 2px;
}
.gg-infinity::after, .gg-infinity::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 10px;
  height: 10px;
  border: 2px solid;
  border-radius: 200px;
  transform: rotate(45deg);
}
.gg-infinity::after {
  border-right-color: transparent;
  left: -8px;
  top: -4px;
}
.gg-infinity::before {
  border-left-color: transparent;
  right: -8px;
  top: 4px;
}

.gg-info {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 20px;
  height: 20px;
  border: 2px solid;
  border-radius: 40px;
}
.gg-info::after, .gg-info::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border-radius: 3px;
  width: 2px;
  background: currentColor;
  left: 7px;
}
.gg-info::after {
  bottom: 2px;
  height: 8px;
}
.gg-info::before {
  height: 2px;
  top: 2px;
}

.gg-inpicture {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 16px;
  border: 2px solid;
  border-radius: 2px;
}
.gg-inpicture::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 6px;
  height: 5px;
  background: currentColor;
  right: 2px;
  bottom: 2px;
}

.gg-insert-after-o {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 16px;
  height: 16px;
  border: 2px solid;
  border-radius: 20px;
}
.gg-insert-after-o::before {
  box-shadow: -2px 12px 0, 2px 12px 0;
}
.gg-insert-after-o::after, .gg-insert-after-o::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 8px;
  height: 2px;
  background: currentColor;
  border-radius: 5px;
  top: 5px;
  left: 2px;
}
.gg-insert-after-o::after {
  width: 2px;
  height: 8px;
  top: 2px;
  left: 5px;
}

.gg-insert-after-r {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 16px;
  height: 16px;
  border: 2px solid;
  border-radius: 4px;
}
.gg-insert-after-r::before {
  box-shadow: -2px 12px 0, 2px 12px 0;
}
.gg-insert-after-r::after, .gg-insert-after-r::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 8px;
  height: 2px;
  background: currentColor;
  border-radius: 5px;
  top: 5px;
  left: 2px;
}
.gg-insert-after-r::after {
  width: 2px;
  height: 8px;
  top: 2px;
  left: 5px;
}

.gg-insert-after {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 18px;
  height: 18px;
}
.gg-insert-after::before {
  box-shadow: -2px 10px 0, 2px 10px 0;
}
.gg-insert-after::after, .gg-insert-after::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 10px;
  height: 2px;
  background: currentColor;
  border-radius: 5px;
  top: 8px;
  left: 4px;
}
.gg-insert-after::after {
  width: 2px;
  height: 10px;
  top: 4px;
  left: 8px;
}

.gg-insert-before-o {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 16px;
  height: 16px;
  border: 2px solid;
  border-radius: 20px;
}
.gg-insert-before-o::before {
  box-shadow: -2px -12px 0, 2px -12px 0;
}
.gg-insert-before-o::after, .gg-insert-before-o::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 8px;
  height: 2px;
  background: currentColor;
  border-radius: 5px;
  top: 5px;
  left: 2px;
}
.gg-insert-before-o::after {
  width: 2px;
  height: 8px;
  top: 2px;
  left: 5px;
}

.gg-insert-before-r {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 16px;
  height: 16px;
  border: 2px solid;
  border-radius: 4px;
}
.gg-insert-before-r::before {
  box-shadow: -2px -12px 0, 2px -12px 0;
}
.gg-insert-before-r::after, .gg-insert-before-r::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 8px;
  height: 2px;
  background: currentColor;
  border-radius: 5px;
  top: 5px;
  left: 2px;
}
.gg-insert-before-r::after {
  width: 2px;
  height: 8px;
  top: 2px;
  left: 5px;
}

.gg-insert-before {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 18px;
  height: 18px;
}
.gg-insert-before::before {
  box-shadow: -2px -10px 0, 2px -10px 0;
}
.gg-insert-before::after, .gg-insert-before::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 10px;
  height: 2px;
  background: currentColor;
  border-radius: 5px;
  top: 8px;
  left: 4px;
}
.gg-insert-before::after {
  width: 2px;
  height: 10px;
  top: 4px;
  left: 8px;
}

.gg-insights {
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 14px;
  height: 16px;
  transform: scale(var(--ggs, 1));
  border-left: 8px solid transparent;
  border-top: 4px solid transparent;
  box-shadow: inset 0 0 0 2px;
}
.gg-insights::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 6px;
  height: 16px;
  border: 2px solid;
  right: 4px;
  bottom: 0;
}
.gg-insights::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 6px;
  height: 16px;
  border: 2px solid;
  right: 4px;
  bottom: 0;
  height: 8px;
  right: 8px;
}

.gg-instagram {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  border: 2px solid transparent;
  box-shadow: 0 0 0 2px;
  border-radius: 4px;
  width: 20px;
  height: 20px;
}
.gg-instagram::after, .gg-instagram::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
}
.gg-instagram::after {
  border: 2px solid;
  left: 3px;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  top: 3px;
}
.gg-instagram::before {
  border-radius: 3px;
  width: 2px;
  height: 2px;
  background: currentColor;
  right: 1px;
  top: 1px;
}

.gg-internal {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 12px;
  height: 12px;
  box-shadow: -2px 2px 0 0, -4px -4px 0 -2px, 4px 4px 0 -2px;
  margin-left: -2px;
  margin-top: 1px;
}
.gg-internal::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  left: 2px;
}
.gg-internal::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  left: 2px;
  background: currentColor;
  transform: rotate(-45deg);
  width: 14px;
  height: 2px;
  bottom: 8px;
}
.gg-internal::after {
  width: 7px;
  height: 7px;
  border-left: 2px solid;
  border-bottom: 2px solid;
  bottom: 2px;
}

.gg-key {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 6px;
  height: 8px;
  border: 2px solid;
  border-radius: 100px;
  margin-left: -12px;
}
.gg-key::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  right: -12px;
}
.gg-key::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  right: -12px;
  background: currentColor;
  width: 12px;
  height: 2px;
  top: 1px;
}
.gg-key::after {
  width: 5px;
  height: 3px;
  top: 2px;
  border-left: 2px solid;
  border-right: 2px solid;
}

.gg-keyboard {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 18px;
  height: 18px;
  border: 2px solid;
  border-radius: 3px;
}
.gg-keyboard::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  height: 2px;
  border-radius: 4px;
  background: currentColor;
}
.gg-keyboard::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  height: 2px;
  border-radius: 4px;
  background: currentColor;
  top: 2px;
  box-shadow: 4px 0 0, 8px 0 0, 0 4px 0, 4px 4px 0, 8px 4px 0;
  width: 2px;
  left: 2px;
}
.gg-keyboard::after {
  width: 8px;
  bottom: 2px;
  left: 3px;
}

.gg-keyhole {
  display: block;
  box-sizing: border-box;
  border-radius: 20px;
  position: relative;
  transform: scale(var(--ggs, 1));
  width: 20px;
  height: 20px;
  border: 2px solid;
}
.gg-keyhole::after, .gg-keyhole::before {
  display: block;
  box-sizing: border-box;
  border-radius: 20px;
}
.gg-keyhole::after {
  content: "";
  position: absolute;
}
.gg-keyhole::before {
  content: "";
  position: absolute;
  width: 6px;
  height: 6px;
  border: 2px solid;
  left: 5px;
  top: 3px;
}
.gg-keyhole::after {
  background: currentColor;
  width: 2px;
  height: 5px;
  left: 7px;
  bottom: 3px;
}

.gg-laptop {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 18px;
  height: 12px;
  border: 2px solid;
  border-radius: 2px;
}
.gg-laptop::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 22px;
  height: 2px;
  background: currentColor;
  left: -4px;
  bottom: -5px;
  border-radius: 100px;
}

.gg-lastpass {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 20px;
  height: 12px;
}
.gg-lastpass::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  background: currentColor;
  border-radius: 22px;
}
.gg-lastpass::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  background: currentColor;
  border-radius: 22px;
  width: 4px;
  height: 4px;
  box-shadow: 6px 0 0, 12px 0 0;
  top: 4px;
}
.gg-lastpass::after {
  width: 2px;
  height: 12px;
  right: 0;
}

.gg-layout-grid-small {
  transform: scale(var(--ggs, 1));
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 10px;
  height: 2px;
  border-left: 6px double;
  border-right: 2px solid;
}
.gg-layout-grid-small::after, .gg-layout-grid-small::before {
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 10px;
  height: 2px;
  border-left: 6px double;
  border-right: 2px solid;
}
.gg-layout-grid-small::after, .gg-layout-grid-small::before {
  content: "";
  position: absolute;
  left: -6px;
}
.gg-layout-grid-small::after {
  bottom: 4px;
}
.gg-layout-grid-small::before {
  top: 4px;
}

.gg-layout-grid {
  transform: scale(var(--ggs, 1));
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 10px;
  height: 10px;
}
.gg-layout-grid::after, .gg-layout-grid::before {
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 10px;
  height: 10px;
}
.gg-layout-grid::after {
  content: "";
  position: absolute;
  height: 4px;
  border-left: 4px solid;
  border-right: 4px solid;
}
.gg-layout-grid::before {
  content: "";
  position: absolute;
  height: 4px;
  border-left: 4px solid;
  border-right: 4px solid;
  top: 0;
}
.gg-layout-grid::after {
  bottom: 0;
}

.gg-layout-list {
  transform: scale(var(--ggs, 1));
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 10px;
  height: 2px;
  border-left: 6px double;
  border-right: 4px solid;
}
.gg-layout-list::after, .gg-layout-list::before {
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 10px;
  height: 2px;
  border-left: 6px double;
  border-right: 4px solid;
}
.gg-layout-list::after, .gg-layout-list::before {
  content: "";
  position: absolute;
  left: -6px;
}
.gg-layout-list::after {
  bottom: 4px;
}
.gg-layout-list::before {
  top: 4px;
}

.gg-layout-pin {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 16px;
  height: 16px;
  border: 2px solid;
  border-radius: 3px;
}
.gg-layout-pin::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 6px;
  height: 6px;
  border-radius: 4px;
  top: 3px;
  left: 3px;
  box-shadow: -7px 0 0, 7px 0 0, 0 -7px 0, 0 7px 0;
}

.gg-linear {
  display: block;
  position: relative;
  box-sizing: border-box;
  transform: scale(var(--ggs, 1));
  width: 18px;
  height: 18px;
  overflow: hidden;
  border-radius: 100px;
}
.gg-linear::after, .gg-linear::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 25px;
  height: 25px;
  transform: rotate(-45deg);
  background: currentColor;
  top: -14px;
  left: 4px;
}
.gg-linear::after {
  width: 2px;
  box-shadow: -3px 4px 0 0, -6px -2px 0 0;
  top: -3px;
  left: 6px;
}

.gg-link {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: rotate(-45deg) scale(var(--ggs, 1));
  width: 8px;
  height: 2px;
  background: currentColor;
  border-radius: 4px;
}
.gg-link::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border-radius: 3px;
  width: 8px;
  height: 10px;
  border: 2px solid;
  top: -4px;
}
.gg-link::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border-radius: 3px;
  width: 8px;
  height: 10px;
  border: 2px solid;
  top: -4px;
  border-right: 0;
  border-top-left-radius: 40px;
  border-bottom-left-radius: 40px;
  left: -6px;
}
.gg-link::after {
  border-left: 0;
  border-top-right-radius: 40px;
  border-bottom-right-radius: 40px;
  right: -6px;
}

.gg-list-tree {
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 22px;
  height: 22px;
  transform: scale(var(--ggs, 1));
  background: linear-gradient(to left, currentcolor 8px, transparent 0) no-repeat left top/8px 8px, linear-gradient(to left, currentcolor 8px, transparent 0) no-repeat center 3px/8px 2px, linear-gradient(to left, currentcolor 8px, transparent 0) no-repeat 10px 17px/6px 2px, linear-gradient(to left, currentcolor 8px, transparent 0) no-repeat 10px 3px/2px 16px;
}
.gg-list-tree::after, .gg-list-tree::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 8px;
  height: 8px;
  border: 2px solid;
  right: 0;
}
.gg-list-tree::after {
  bottom: 0;
}

.gg-list {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 20px;
  border: 2px solid;
  border-radius: 3px;
}
.gg-list::after, .gg-list::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 2px;
  height: 2px;
  background: currentColor;
  top: 3px;
  left: 3px;
  box-shadow: 0 4px 0, 0 8px 0;
}
.gg-list::after {
  border-radius: 3px;
  width: 8px;
  left: 7px;
}

.gg-live-photo {
  transform: scale(var(--ggs, 1));
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 20px;
  height: 20px;
  border-radius: 100px;
  border: 2px dotted currentColor;
}
.gg-live-photo::after {
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 20px;
  height: 20px;
  border-radius: 100px;
  border: 2px dotted currentColor;
  content: "";
  position: absolute;
  width: 8px;
  height: 8px;
  border: 1px solid transparent;
  top: 4px;
  left: 4px;
  box-shadow: 0 0 0 2px, inset 0 0 0 2px currentColor;
}

@keyframes loadbaralt {
  0%, to {
    left: 0;
    right: 80%;
  }
  25%, 75% {
    left: 0;
    right: 0;
  }
  50% {
    left: 80%;
    right: 0;
  }
}
.gg-loadbar-alt {
  display: block;
  box-sizing: border-box;
  height: 4px;
  border-radius: 4px;
  position: relative;
  transform: scale(var(--ggs, 1));
  width: 18px;
}
.gg-loadbar-alt::before {
  display: block;
  box-sizing: border-box;
  height: 4px;
  border-radius: 4px;
}
.gg-loadbar-alt::after {
  display: block;
  box-sizing: border-box;
  height: 4px;
  border-radius: 4px;
  background: currentColor;
  content: "";
  position: absolute;
}
.gg-loadbar-alt::before {
  background: currentColor;
  content: "";
  position: absolute;
  animation: loadbaralt 2s cubic-bezier(0, 0, 0.58, 1) infinite;
}
.gg-loadbar-alt::after {
  width: 18px;
  opacity: 0.3;
}

@keyframes gg-doc {
  0% {
    left: -16px;
  }
  60%, to {
    left: 0;
  }
}
.gg-loadbar-doc {
  display: block;
  position: relative;
  box-sizing: border-box;
  transform: scale(var(--ggs, 1));
  border: 2px solid transparent;
  box-shadow: 0 0 0 2px;
  border-radius: 1px;
  width: 12px;
  height: 14px;
  overflow: hidden;
}
.gg-loadbar-doc::after {
  content: "";
  display: block;
  position: absolute;
  box-sizing: border-box;
  height: 2px;
  background: currentColor;
  animation: gg-doc 1s linear infinite alternate;
}
.gg-loadbar-doc::before {
  content: "";
  display: block;
  position: absolute;
  box-sizing: border-box;
  height: 2px;
  background: currentColor;
  animation: gg-doc 1s linear infinite alternate;
  width: 14px;
  box-shadow: -4px 4px 0;
}
.gg-loadbar-doc::after {
  width: 5px;
  top: 8px;
}

@keyframes gg-bar {
  10% {
    box-shadow: inset 0 -4px 0;
  }
  30% {
    box-shadow: inset 0 -10px 0;
  }
  60% {
    box-shadow: inset 0 -6px 0;
  }
  80% {
    box-shadow: inset 0 -8px 0;
  }
  to {
    box-shadow: inset 0 -2px 0;
  }
}
.gg-loadbar-sound {
  display: block;
  box-sizing: border-box;
  width: 2px;
  height: 12px;
  box-shadow: inset 0 -12px 0;
  animation: gg-bar 1.3s ease infinite alternate;
  position: relative;
  transform: scale(var(--ggs, 1));
}
.gg-loadbar-sound::after, .gg-loadbar-sound::before {
  display: block;
  box-sizing: border-box;
  width: 2px;
  height: 12px;
  box-shadow: inset 0 -12px 0;
  animation: gg-bar 1.3s ease infinite alternate;
}
.gg-loadbar-sound::after {
  content: "";
  position: absolute;
  bottom: 0;
}
.gg-loadbar-sound::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: -4px;
  animation-delay: -2.4s;
}
.gg-loadbar-sound::after {
  right: -4px;
  animation-delay: -3.7s;
}

@keyframes loadbar {
  0%, to {
    left: 0;
    right: 80%;
  }
  25%, 75% {
    left: 0;
    right: 0;
  }
  50% {
    left: 80%;
    right: 0;
  }
}
.gg-loadbar {
  display: block;
  box-sizing: border-box;
  height: 4px;
  position: relative;
  transform: scale(var(--ggs, 1));
  width: 18px;
}
.gg-loadbar::before {
  display: block;
  box-sizing: border-box;
  height: 4px;
  content: "";
  position: absolute;
  border-radius: 4px;
  background: currentColor;
  animation: loadbar 2s cubic-bezier(0, 0, 0.58, 1) infinite;
}

.gg-lock-unlock {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 12px;
  height: 6px;
  border: 2px solid;
  border-top-right-radius: 100px;
  border-top-left-radius: 100px;
  border-bottom: transparent;
  margin-top: -18px;
  margin-left: -2px;
}
.gg-lock-unlock::after, .gg-lock-unlock::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
}
.gg-lock-unlock::after {
  border: 2px solid transparent;
  box-shadow: 0 0 0 2px;
  width: 16px;
  height: 10px;
  border-radius: 2px;
  left: -4px;
  top: 10px;
}
.gg-lock-unlock::before {
  width: 2px;
  height: 6px;
  background: currentColor;
  left: -2px;
  top: 4px;
}

.gg-lock {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 12px;
  height: 11px;
  border: 2px solid;
  border-top-right-radius: 50%;
  border-top-left-radius: 50%;
  border-bottom: transparent;
  margin-top: -12px;
}
.gg-lock::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 16px;
  height: 10px;
  border-radius: 2px;
  border: 2px solid transparent;
  box-shadow: 0 0 0 2px;
  left: -4px;
  top: 9px;
}

.gg-log-in {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 6px;
  height: 16px;
  border: 2px solid;
  border-left: 0;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  margin-right: -10px;
}
.gg-log-in::after, .gg-log-in::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
}
.gg-log-in::after {
  border-top: 2px solid;
  border-right: 2px solid;
  transform: rotate(45deg);
  width: 8px;
  height: 8px;
  left: -8px;
  bottom: 2px;
}
.gg-log-in::before {
  border-radius: 3px;
  width: 10px;
  height: 2px;
  background: currentColor;
  left: -11px;
  bottom: 5px;
}

.gg-log-off {
  box-sizing: border-box;
  position: relative;
  display: block;
  border-radius: 16px;
  border: 2px solid;
  transform: scale(var(--ggs, 1));
  width: 16px;
  height: 16px;
  border-top: 2px solid transparent;
}
.gg-log-off::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border-radius: 3px;
  height: 8px;
  width: 2px;
  background: currentColor;
  left: 5px;
  bottom: 6px;
}

.gg-log-out {
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 6px;
  height: 16px;
  border: 2px solid;
  transform: scale(var(--ggs, 1));
  border-right: 0;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  margin-left: -10px;
}
.gg-log-out::after, .gg-log-out::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
}
.gg-log-out::after {
  border-top: 2px solid;
  border-left: 2px solid;
  transform: rotate(-45deg);
  width: 8px;
  height: 8px;
  left: 4px;
  bottom: 2px;
}
.gg-log-out::before {
  border-radius: 3px;
  width: 10px;
  height: 2px;
  background: currentColor;
  left: 5px;
  bottom: 5px;
}

.gg-loupe {
  display: block;
  box-sizing: border-box;
  position: relative;
  transform: scale(var(--ggs, 1));
  width: 18px;
  height: 18px;
  border: 2px solid;
  border-radius: 22px;
  border-bottom-right-radius: 6px;
  overflow: hidden;
  background: linear-gradient(to left, currentColor 8px, transparent 0) no-repeat center/8px 2px, linear-gradient(to left, currentColor 8px, transparent 0) no-repeat center/2px 8px;
}
.gg-loupe::after {
  display: block;
  box-sizing: border-box;
  content: "";
  position: absolute;
  width: 14px;
  height: 14px;
  border-radius: 100%;
  box-shadow: 0 0 0 6px;
}

.gg-magnet {
  display: block;
  box-sizing: border-box;
  width: 14px;
  border: 4px solid;
  transform: scale(var(--ggs, 1));
  border-bottom-left-radius: 100px;
  border-bottom-right-radius: 100px;
  border-top: 0;
  position: relative;
  height: 12px;
}
.gg-magnet::before {
  display: block;
  box-sizing: border-box;
  width: 14px;
  content: "";
  position: absolute;
  height: 3px;
  border-left: 4px solid;
  border-right: 4px solid;
  top: -5px;
  left: -4px;
  opacity: 0.5;
}

.gg-mail-forward {
  display: block;
  box-sizing: border-box;
  height: 6px;
  border-top: 2px solid;
  border-left: 2px solid;
  transform: scale(var(--ggs, 1));
  border-top-left-radius: 4px;
  position: relative;
  width: 14px;
}
.gg-mail-forward::before {
  display: block;
  box-sizing: border-box;
  height: 6px;
  border-top: 2px solid;
  content: "";
  position: absolute;
  width: 6px;
  border-right: 2px solid;
  top: -4px;
  right: -1px;
  transform: rotate(45deg);
}

.gg-mail-open {
  display: block;
  box-sizing: border-box;
  height: 14px;
  border: 2px solid;
  border-top: 0;
  transform: scale(var(--ggs, 1));
  position: relative;
  width: 18px;
  border-radius: 2px;
}
.gg-mail-open::after {
  display: block;
  box-sizing: border-box;
  height: 14px;
  border: 2px solid;
  content: "";
  position: absolute;
  border-radius: 3px;
  width: 14px;
  transform: rotate(-45deg);
  bottom: 3px;
  left: 0;
}

.gg-mail-reply {
  display: block;
  box-sizing: border-box;
  height: 6px;
  border-top: 2px solid;
  border-right: 2px solid;
  transform: scale(var(--ggs, 1));
  border-top-right-radius: 4px;
  position: relative;
  width: 14px;
}
.gg-mail-reply::before {
  display: block;
  box-sizing: border-box;
  height: 6px;
  border-top: 2px solid;
  content: "";
  position: absolute;
  width: 6px;
  border-left: 2px solid;
  top: -4px;
  left: -1px;
  transform: rotate(-45deg);
}

.gg-mail {
  display: block;
  box-sizing: border-box;
  height: 14px;
  border: 2px solid;
  overflow: hidden;
  transform: scale(var(--ggs, 1));
  position: relative;
  width: 18px;
  border-radius: 2px;
}
.gg-mail::after {
  display: block;
  box-sizing: border-box;
  height: 14px;
  border: 2px solid;
  content: "";
  position: absolute;
  border-radius: 3px;
  width: 14px;
  transform: rotate(-45deg);
  bottom: 3px;
  left: 0;
}

.gg-math-divide {
  transform: scale(var(--ggs, 1));
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 16px;
  height: 2px;
  background: currentColor;
  border-radius: 20px;
}
.gg-math-divide::after, .gg-math-divide::before {
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 16px;
  height: 2px;
  background: currentColor;
  border-radius: 20px;
}
.gg-math-divide::after, .gg-math-divide::before {
  content: "";
  position: absolute;
  width: 4px;
  height: 4px;
  top: -6px;
  left: 6px;
}
.gg-math-divide::after {
  top: 4px;
}

.gg-math-equal {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 16px;
  height: 2px;
  background: currentColor;
  border-radius: 10px;
  margin-top: -6px;
  box-shadow: 0 6px 0;
}

.gg-math-minus {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 16px;
  height: 2px;
  background: currentColor;
  border-radius: 10px;
}

.gg-math-percent {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: rotate(-45deg) scale(var(--ggs, 1));
  width: 16px;
  height: 2px;
  background: currentColor;
  border-radius: 20px;
}
.gg-math-percent::after, .gg-math-percent::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 4px;
  height: 4px;
  background: currentColor;
  border-radius: 20px;
  top: -7px;
  left: 6px;
}
.gg-math-percent::after {
  top: 5px;
}

.gg-math-plus {
  display: block;
  box-sizing: border-box;
  background: currentColor;
  border-radius: 10px;
  margin-top: -2px;
  position: relative;
  transform: scale(var(--ggs, 1));
  width: 16px;
  height: 2px;
}
.gg-math-plus::after {
  display: block;
  box-sizing: border-box;
  background: currentColor;
  border-radius: 10px;
  content: "";
  position: absolute;
  width: 2px;
  height: 16px;
  top: -7px;
  left: 7px;
}

.gg-maximize-alt {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 14px;
  height: 14px;
  box-shadow: -6px -6px 0 -4px, 6px 6px 0 -4px, 6px -6px 0 -4px, -6px 6px 0 -4px;
}
.gg-maximize-alt::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border-radius: 3px;
  width: 22px;
  height: 2px;
  border-left: 8px solid;
  border-right: 8px solid;
  transform: rotate(-45deg);
  bottom: 6px;
  left: -4px;
}
.gg-maximize-alt::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border-radius: 3px;
  width: 22px;
  height: 2px;
  border-left: 8px solid;
  border-right: 8px solid;
  transform: rotate(-45deg);
  bottom: 6px;
  left: -4px;
  transform: rotate(45deg);
}

.gg-maximize {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 14px;
  height: 14px;
  box-shadow: -6px -6px 0 -4px, 6px 6px 0 -4px, 6px -6px 0 -4px, -6px 6px 0 -4px;
}

.gg-maze {
  display: block;
  box-sizing: border-box;
  position: relative;
  transform: scale(var(--ggs, 1));
  width: 24px;
  height: 14px;
}
.gg-maze::after {
  content: "";
  display: block;
  position: absolute;
  box-sizing: border-box;
  height: 12px;
  transform: rotate(45deg);
}
.gg-maze::before {
  content: "";
  display: block;
  position: absolute;
  box-sizing: border-box;
  height: 12px;
  transform: rotate(45deg);
  width: 12px;
  right: 2px;
  bottom: -2px;
  border-left: 4px solid;
  border-top: 4px solid;
}
.gg-maze::after {
  width: 4px;
  background: currentColor;
  left: 3px;
  bottom: 1px;
}

.gg-media-live {
  transform: scale(var(--ggs, 1));
  border-top-color: transparent;
  border-bottom-color: transparent;
  box-sizing: border-box;
  position: relative;
  display: block;
  border: 2px solid;
  border-radius: 50%;
  width: 14px;
  height: 14px;
}
.gg-media-live::after {
  border-top-color: transparent;
  border-bottom-color: transparent;
  box-sizing: border-box;
  position: relative;
  display: block;
  border: 2px solid;
  border-radius: 50%;
  width: 14px;
  height: 14px;
}
.gg-media-live::before {
  box-sizing: border-box;
  position: relative;
  display: block;
  border: 2px solid;
  border-radius: 50%;
  width: 14px;
  height: 14px;
}
.gg-media-live::after, .gg-media-live::before {
  content: "";
  position: absolute;
  width: 6px;
  height: 6px;
  top: 2px;
  left: 2px;
}
.gg-media-live::after {
  width: 22px;
  height: 22px;
  top: -6px;
  left: -6px;
}

.gg-media-podcast {
  transform: scale(var(--ggs, 1));
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 14px;
  height: 14px;
  border: 2px solid;
  border-bottom-color: transparent;
  border-radius: 50%;
}
.gg-media-podcast::after, .gg-media-podcast::before {
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 14px;
  height: 14px;
  border: 2px solid;
  border-bottom-color: transparent;
  border-radius: 50%;
}
.gg-media-podcast::after, .gg-media-podcast::before {
  content: "";
  position: absolute;
  width: 6px;
  height: 6px;
  top: 2px;
  left: 2px;
}
.gg-media-podcast::after {
  width: 22px;
  height: 22px;
  top: -6px;
  left: -6px;
}

.gg-menu-boxed {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 18px;
  height: 18px;
  border-radius: 3px;
  border: 2px solid;
}
.gg-menu-boxed::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 10px;
  height: 2px;
  background: currentColor;
  border-radius: 3px;
  top: 2px;
  left: 2px;
  box-shadow: 0 4px 0, 0 8px 0;
}

.gg-menu-cake {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 16px;
  height: 18px;
}
.gg-menu-cake::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  height: 2px;
  background: currentColor;
  border-radius: 22px;
}
.gg-menu-cake::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  height: 2px;
  background: currentColor;
  border-radius: 22px;
  width: 18px;
  box-shadow: 0 -4px 0, 0 -8px 0;
  left: 1px;
  bottom: 2px;
}
.gg-menu-cake::after {
  width: 4px;
  height: 4px;
  left: 8px;
}

.gg-menu-cheese {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 18px;
  height: 10px;
}
.gg-menu-cheese::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  height: 2px;
}
.gg-menu-cheese::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  height: 2px;
  width: 18px;
  background: currentColor;
  border-radius: 22px;
  box-shadow: 0 -6px 0, 0 -12px 0;
  bottom: 0;
}
.gg-menu-cheese::after {
  width: 2px;
  left: 2px;
  bottom: 3px;
  border-top: 3px solid;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
}

.gg-menu-grid-o {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 16px;
  height: 16px;
}
.gg-menu-grid-o::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 4px;
  height: 4px;
  background: currentColor;
  box-shadow: 0 6px 0, 6px 6px 0, 12px 6px 0, 6px 12px 0, 12px 12px 0, 6px 0 0, 12px 0 0, 0 12px 0;
  border-radius: 22px;
}

.gg-menu-grid-r {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 16px;
  height: 16px;
}
.gg-menu-grid-r::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 4px;
  height: 4px;
  background: currentColor;
  box-shadow: 0 6px 0, 6px 6px 0, 12px 6px 0, 6px 12px 0, 12px 12px 0, 6px 0 0, 12px 0 0, 0 12px 0;
}

.gg-menu-hotdog {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 16px;
  height: 12px;
  border-top: 3px solid;
  border-bottom: 3px solid;
  border-radius: 3px;
}
.gg-menu-hotdog::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 20px;
  height: 2px;
  background: currentColor;
  border-radius: 4px;
  top: 2px;
  left: -2px;
}

.gg-menu-left-alt {
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 16px;
  height: 14px;
  transform: scale(var(--ggs, 1));
}
.gg-menu-left-alt::after {
  content: "";
  position: absolute;
  box-sizing: border-box;
  display: block;
  height: 2px;
  border-radius: 4px;
  background: currentColor;
}
.gg-menu-left-alt::before {
  content: "";
  position: absolute;
  box-sizing: border-box;
  display: block;
  height: 2px;
  border-radius: 4px;
  background: currentColor;
  width: 16px;
  box-shadow: 0 12px 0;
}
.gg-menu-left-alt::after {
  width: 10px;
  top: 6px;
}

.gg-menu-left {
  transform: scale(var(--ggs, 1));
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 20px;
  height: 2px;
  border-radius: 3px;
  background: currentColor;
}
.gg-menu-left::after, .gg-menu-left::before {
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 20px;
  height: 2px;
  border-radius: 3px;
  background: currentColor;
}
.gg-menu-left::after, .gg-menu-left::before {
  content: "";
  position: absolute;
  top: -6px;
  width: 10px;
}
.gg-menu-left::after {
  top: 6px;
  width: 14px;
}

.gg-menu-motion {
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 18px;
  height: 14px;
  transform: scale(var(--ggs, 1));
}
.gg-menu-motion::before {
  content: "";
  position: absolute;
  box-sizing: border-box;
  display: block;
  width: 10px;
  height: 2px;
  bottom: 0;
  box-shadow: 4px -6px 0, 8px -12px 0;
  border-radius: 4px;
  background: currentColor;
}

.gg-menu-oreos {
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 16px;
  height: 18px;
  transform: scale(var(--ggs, 1));
}
.gg-menu-oreos::after, .gg-menu-oreos::before {
  content: "";
  position: absolute;
  box-sizing: border-box;
  display: block;
  width: 16px;
  height: 8px;
  border-top: 3px solid;
  border-bottom: 3px solid;
  border-radius: 3px;
}
.gg-menu-oreos::after {
  bottom: 0;
}

.gg-menu-right-alt {
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 16px;
  height: 14px;
  transform: scale(var(--ggs, 1));
}
.gg-menu-right-alt::after {
  content: "";
  position: absolute;
  box-sizing: border-box;
  display: block;
  height: 2px;
  border-radius: 4px;
  background: currentColor;
}
.gg-menu-right-alt::before {
  content: "";
  position: absolute;
  box-sizing: border-box;
  display: block;
  height: 2px;
  border-radius: 4px;
  background: currentColor;
  width: 16px;
  box-shadow: 0 12px 0;
}
.gg-menu-right-alt::after {
  width: 10px;
  right: 0;
  top: 6px;
}

.gg-menu-right {
  transform: scale(var(--ggs, 1));
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 20px;
  height: 2px;
  border-radius: 3px;
  background: currentColor;
}
.gg-menu-right::after, .gg-menu-right::before {
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 20px;
  height: 2px;
  border-radius: 3px;
  background: currentColor;
}
.gg-menu-right::after, .gg-menu-right::before {
  content: "";
  position: absolute;
  top: -6px;
  right: 0;
  width: 10px;
}
.gg-menu-right::after {
  top: 6px;
  width: 14px;
}

.gg-menu-round {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 22px;
  border-radius: 24px;
  border: 2px solid;
}
.gg-menu-round::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 10px;
  height: 2px;
  background: currentColor;
  border-radius: 3px;
  top: 4px;
  left: 4px;
  box-shadow: 0 4px 0, 0 8px 0;
}

.gg-menu {
  transform: scale(var(--ggs, 1));
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 20px;
  height: 2px;
  border-radius: 3px;
  background: currentColor;
}
.gg-menu::after, .gg-menu::before {
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 20px;
  height: 2px;
  border-radius: 3px;
  background: currentColor;
}
.gg-menu::after, .gg-menu::before {
  content: "";
  position: absolute;
  top: -6px;
}
.gg-menu::after {
  top: 6px;
}

.gg-merge-horizontal {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 14px;
  height: 2px;
  border-radius: 3px;
  background: currentColor;
}
.gg-merge-horizontal::after, .gg-merge-horizontal::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 6px;
  height: 6px;
  transform: rotate(-45deg);
}
.gg-merge-horizontal::after {
  border-bottom: 2px solid;
  border-left: 2px solid;
  left: 4px;
  bottom: 5px;
}
.gg-merge-horizontal::before {
  border-top: 2px solid;
  border-right: 2px solid;
  right: 4px;
  top: 5px;
}

.gg-merge-vertical {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 2px;
  height: 14px;
  border-radius: 3px;
  background: currentColor;
}
.gg-merge-vertical::after, .gg-merge-vertical::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 6px;
  height: 6px;
  border-bottom: 2px solid;
  top: 4px;
}
.gg-merge-vertical::after {
  border-left: 2px solid;
  left: 5px;
  transform: rotate(45deg);
}
.gg-merge-vertical::before {
  border-right: 2px solid;
  transform: rotate(-45deg);
  right: 5px;
}

.gg-mic {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 16px;
  height: 12px;
  border-bottom-left-radius: 120px;
  border-bottom-right-radius: 120px;
  border: 2px solid;
  border-top: 0;
  margin-top: 3px;
}
.gg-mic::after, .gg-mic::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
}
.gg-mic::after {
  border: 2px solid;
  width: 8px;
  height: 18px;
  left: 2px;
  top: -10px;
  border-radius: 4px;
}
.gg-mic::before {
  width: 10px;
  height: 4px;
  top: 12px;
  left: 1px;
  border-right: 4px solid transparent;
  box-shadow: 0 2px 0, inset -2px 0 0;
}

.gg-microbit {
  display: block;
  box-sizing: border-box;
  border-radius: 20px;
  position: relative;
  transform: scale(var(--ggs, 1));
  width: 24px;
  height: 14px;
  border: 3px solid;
}
.gg-microbit::before {
  display: block;
  box-sizing: border-box;
  border-radius: 20px;
  content: "";
  position: absolute;
  width: 4px;
  height: 4px;
  background: currentColor;
  top: 2px;
  left: 2px;
  box-shadow: 10px 0 0 0;
}

.gg-microsoft {
  transform: scale(var(--ggs, 1));
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 18px;
  height: 18px;
}
.gg-microsoft::after, .gg-microsoft::before {
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 18px;
  height: 18px;
}
.gg-microsoft::after, .gg-microsoft::before {
  content: "";
  position: absolute;
  width: 8px;
  border-top: 8px solid;
  border-bottom: 8px solid;
}
.gg-microsoft::after {
  right: 0;
}

.gg-mini-player {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 18px;
  height: 18px;
  border: 2px solid;
  border-radius: 3px;
}
.gg-mini-player::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 8px;
  height: 8px;
  background: currentColor;
  border-top-left-radius: 3px;
  border-bottom-right-radius: 3px;
  bottom: -1px;
  right: -1px;
}

.gg-minimize-alt {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: rotate(-45deg) scale(var(--ggs, 1));
  width: 26px;
  height: 2px;
  border-left: 10px solid;
  border-right: 10px solid;
}
.gg-minimize-alt::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 6px;
  height: 6px;
  border-top: 2px solid;
  top: -2px;
}
.gg-minimize-alt::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 6px;
  height: 6px;
  border-top: 2px solid;
  top: -2px;
  border-right: 2px solid;
  left: -5px;
  transform: rotate(45deg);
}
.gg-minimize-alt::after {
  border-left: 2px solid;
  right: -5px;
  transform: rotate(-45deg);
}

.gg-minimize {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 4px;
  height: 4px;
  box-shadow: -8px -4px 0 -1px, -6px -4px 0 -1px, 8px 4px 0 -1px, 6px 4px 0 -1px, 8px -4px 0 -1px, 6px -4px 0 -1px, -8px 4px 0 -1px, -6px 4px 0 -1px;
}
.gg-minimize::after, .gg-minimize::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 2px;
  height: 18px;
  border-top: 6px solid;
  border-bottom: 6px solid;
  box-shadow: 18px 0 0 -2px;
  top: -7px;
}
.gg-minimize::after {
  left: -3px;
}
.gg-minimize::before {
  right: -3px;
}

.gg-modem {
  position: relative;
  display: block;
  box-sizing: border-box;
  transform: scale(var(--ggs, 1));
  width: 20px;
  height: 20px;
  border-radius: 4px;
  border-top: 12px solid transparent;
  box-shadow: inset 0 0 0 2px;
}
.gg-modem::after, .gg-modem::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  background: currentColor;
  right: 4px;
  bottom: 3px;
  width: 2px;
  height: 2px;
  border-radius: 22px;
}
.gg-modem::after {
  right: -1px;
  bottom: 11px;
  width: 20px;
  transform: rotate(30deg);
}

.gg-monday {
  display: block;
  box-sizing: border-box;
  position: relative;
  transform: scale(var(--ggs, 1));
  width: 21px;
  height: 16px;
}
.gg-monday::after {
  background: currentColor;
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 6px;
  border-radius: 22px;
}
.gg-monday::before {
  background: currentColor;
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 6px;
  border-radius: 22px;
  height: 16px;
  transform: rotate(35deg);
  box-shadow: 7px -5px 0 0;
}
.gg-monday::after {
  height: 6px;
  bottom: 1px;
  right: 0;
}

.gg-moon {
  display: block;
  box-sizing: border-box;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  transform: rotate(-135deg) scale(var(--ggs, 1));
  width: 20px;
  height: 20px;
  border: 2px solid;
  border-bottom-color: transparent;
}
.gg-moon::after {
  display: block;
  box-sizing: border-box;
  border-radius: 50%;
  content: "";
  position: absolute;
  width: 12px;
  height: 18px;
  border: 2px solid transparent;
  box-shadow: 0 0 0 2px;
  top: 8px;
  left: 2px;
}

.gg-more-alt {
  transform: scale(var(--ggs, 1));
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 4px;
  height: 4px;
  background: currentColor;
  border-radius: 100%;
}
.gg-more-alt::after, .gg-more-alt::before {
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 4px;
  height: 4px;
  background: currentColor;
  border-radius: 100%;
}
.gg-more-alt::after, .gg-more-alt::before {
  content: "";
  position: absolute;
  top: 0;
}
.gg-more-alt::after {
  left: -6px;
}
.gg-more-alt::before {
  right: -6px;
}

.gg-more-o {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 24px;
  height: 24px;
  border: 2px solid;
  border-radius: 24px;
}
.gg-more-o::before {
  content: "";
  position: absolute;
  box-sizing: border-box;
  display: block;
  width: 4px;
  height: 4px;
  background-color: currentColor;
  border-radius: 20px;
  top: 8px;
  left: 8px;
  box-shadow: -5px 0 0, 5px 0 0;
}

.gg-more-r {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 24px;
  height: 20px;
  border: 2px solid;
  border-radius: 3px;
}
.gg-more-r::before {
  content: "";
  position: absolute;
  box-sizing: border-box;
  display: block;
  width: 4px;
  height: 4px;
  background-color: currentColor;
  border-radius: 20px;
  top: 6px;
  left: 8px;
  box-shadow: -5px 0 0, 5px 0 0;
}

.gg-more-vertical-alt {
  transform: scale(var(--ggs, 1));
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 4px;
  height: 4px;
  background: currentColor;
  border-radius: 100%;
}
.gg-more-vertical-alt::after, .gg-more-vertical-alt::before {
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 4px;
  height: 4px;
  background: currentColor;
  border-radius: 100%;
}
.gg-more-vertical-alt::after, .gg-more-vertical-alt::before {
  content: "";
  position: absolute;
}
.gg-more-vertical-alt::after {
  left: 0;
  top: 6px;
}
.gg-more-vertical-alt::before {
  top: -6px;
  right: 0;
}

.gg-more-vertical-o {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 24px;
  height: 24px;
  border: 2px solid;
  border-radius: 24px;
}
.gg-more-vertical-o::before {
  content: "";
  position: absolute;
  box-sizing: border-box;
  display: block;
  width: 4px;
  height: 4px;
  background-color: currentColor;
  border-radius: 20px;
  top: 8px;
  left: 8px;
  box-shadow: 0 -5px 0, 0 5px 0;
}

.gg-more-vertical-r {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 20px;
  height: 24px;
  border: 2px solid;
  border-radius: 3px;
}
.gg-more-vertical-r::before {
  content: "";
  position: absolute;
  box-sizing: border-box;
  display: block;
  width: 4px;
  height: 4px;
  background-color: currentColor;
  border-radius: 20px;
  top: 8px;
  left: 6px;
  box-shadow: 0 -5px 0, 0 5px 0;
}

.gg-more-vertical {
  transform: scale(var(--ggs, 1));
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 6px;
  height: 6px;
  border: 2px solid;
  border-radius: 100%;
}
.gg-more-vertical::after, .gg-more-vertical::before {
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 6px;
  height: 6px;
  border: 2px solid;
  border-radius: 100%;
}
.gg-more-vertical::after {
  content: "";
  position: absolute;
  top: 6px;
  left: -2px;
}
.gg-more-vertical::before {
  content: "";
  position: absolute;
  top: 6px;
  left: -2px;
  top: -10px;
}

.gg-more {
  transform: scale(var(--ggs, 1));
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 6px;
  height: 6px;
  border: 2px solid;
  border-radius: 100%;
}
.gg-more::after, .gg-more::before {
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 6px;
  height: 6px;
  border: 2px solid;
  border-radius: 100%;
}
.gg-more::after, .gg-more::before {
  content: "";
  position: absolute;
  top: -2px;
}
.gg-more::after {
  left: -9px;
}
.gg-more::before {
  right: -9px;
}

.gg-mouse {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 16px;
  height: 24px;
  border: 2px solid;
  border-radius: 10px;
}
.gg-mouse::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border-radius: 3px;
  width: 2px;
  height: 6px;
  background: currentColor;
  top: 3px;
  left: 5px;
}

.gg-move-down {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 10px;
  height: 8px;
  border-left: 2px solid;
  border-right: 2px solid;
}
.gg-move-down::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
}
.gg-move-down::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  background: currentColor;
  left: 2px;
  top: 0;
  width: 2px;
  height: 12px;
}
.gg-move-down::after {
  width: 0;
  height: 0;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  border-top: 5px solid;
  bottom: -7px;
}

.gg-move-left {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 8px;
  height: 10px;
  border-top: 2px solid;
  border-bottom: 2px solid;
}
.gg-move-left::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
}
.gg-move-left::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  background: currentColor;
  right: 0;
  top: 2px;
  width: 12px;
  height: 2px;
}
.gg-move-left::after {
  width: 0;
  height: 0;
  border-top: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-right: 5px solid;
  left: -7px;
}

.gg-move-right {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 8px;
  height: 10px;
  border-top: 2px solid;
  border-bottom: 2px solid;
}
.gg-move-right::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
}
.gg-move-right::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  background: currentColor;
  left: 0;
  top: 2px;
  width: 12px;
  height: 2px;
}
.gg-move-right::after {
  width: 0;
  height: 0;
  border-top: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-left: 5px solid;
  right: -7px;
}

.gg-move-task {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 8px;
  height: 10px;
  border-top: 2px solid;
  border-bottom: 2px solid;
}
.gg-move-task::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
}
.gg-move-task::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  background: currentColor;
  top: 2px;
  width: 8px;
  height: 2px;
  left: 0;
}
.gg-move-task::after {
  width: 0;
  height: 0;
  border-top: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-left: 5px solid;
  left: -6px;
}

.gg-move-up {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 10px;
  height: 8px;
  border-left: 2px solid;
  border-right: 2px solid;
}
.gg-move-up::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
}
.gg-move-up::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  background: currentColor;
  left: 2px;
  bottom: 0;
  width: 2px;
  height: 12px;
}
.gg-move-up::after {
  width: 0;
  height: 0;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  border-bottom: 5px solid;
  top: -7px;
}

.gg-music-note {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 2px;
  height: 14px;
  background: currentColor;
}
.gg-music-note::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border: 2px solid;
}
.gg-music-note::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border: 2px solid;
  bottom: -4px;
  width: 8px;
  height: 8px;
  left: -6px;
  border-radius: 10px;
}
.gg-music-note::after {
  width: 10px;
  height: 6px;
  top: -4px;
  left: 0;
  border-radius: 2px;
  transform: skewY(-15deg);
}

.gg-music-speaker {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 16px;
  height: 22px;
  border: 2px solid;
  border-radius: 3px;
}
.gg-music-speaker::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border: 2px solid;
  border-radius: 100px;
}
.gg-music-speaker::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border: 2px solid;
  border-radius: 100px;
  bottom: 2px;
  width: 8px;
  height: 8px;
  left: 2px;
}
.gg-music-speaker::after {
  width: 4px;
  height: 4px;
  left: 4px;
  top: 2px;
}

.gg-music {
  display: block;
  box-sizing: border-box;
  border: 2px solid;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom: 0;
  margin-top: -4px;
  position: relative;
  transform: skewY(-15deg) scale(var(--ggs, 1));
  width: 14px;
  height: 16px;
}
.gg-music::after, .gg-music::before {
  display: block;
  box-sizing: border-box;
  border: 2px solid;
}
.gg-music::after, .gg-music::before {
  content: "";
  position: absolute;
  width: 7px;
  height: 7px;
  top: 9px;
  left: -7px;
  transform: skewY(15deg);
  border-radius: 10px;
}
.gg-music::after {
  left: 5px;
}

.gg-nametag {
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 6px;
  height: 6px;
  border: 2px solid;
  transform: scale(var(--ggs, 1));
}
.gg-nametag::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 12px;
  height: 12px;
  box-shadow: -5px -5px 0 -3px, 5px 5px 0 -3px, 5px -5px 0 -3px, -5px 5px 0 -3px;
  left: -5px;
  top: -5px;
}

.gg-notes {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 20px;
  height: 22px;
  border: 2px solid;
  border-radius: 3px;
}
.gg-notes::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border-radius: 3px;
  height: 2px;
  background: currentColor;
  left: 2px;
}
.gg-notes::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border-radius: 3px;
  height: 2px;
  background: currentColor;
  left: 2px;
  box-shadow: 0 4px 0, 0 8px 0;
  width: 12px;
  top: 2px;
}
.gg-notes::after {
  width: 6px;
  top: 14px;
}

.gg-notifications {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 14px;
  height: 14px;
  background: linear-gradient(to left, currentColor 10px, transparent 0) no-repeat right bottom/2px 8px, linear-gradient(to left, currentColor 10px, transparent 0) no-repeat left top/8px 2px;
}
.gg-notifications::after {
  content: "";
  position: absolute;
  display: block;
  box-sizing: border-box;
}
.gg-notifications::before {
  content: "";
  position: absolute;
  display: block;
  box-sizing: border-box;
  width: 14px;
  height: 14px;
  border-left: 2px solid;
  border-bottom: 2px solid;
}
.gg-notifications::after {
  width: 6px;
  height: 6px;
  border-radius: 4px;
  background: currentColor;
  top: -2px;
  right: -2px;
}

.gg-npm {
  box-sizing: border-box;
  display: block;
  position: relative;
  width: 18px;
  height: 18px;
  transform: scale(var(--ggs, 1));
  border: 3px solid;
  border-radius: 2px;
}
.gg-npm::before {
  box-sizing: border-box;
  display: block;
  content: "";
  position: absolute;
  width: 3px;
  height: 12px;
  background: currentColor;
  bottom: -3px;
  right: 3px;
}

.gg-oculus {
  display: block;
  position: relative;
  box-sizing: border-box;
  transform: scale(var(--ggs, 1));
  width: 20px;
  height: 12px;
  border: 4px solid;
  border-radius: 22px;
}

.gg-open-collective {
  display: block;
  box-sizing: border-box;
  border-radius: 22px;
  position: relative;
  transform: scale(var(--ggs, 1));
  width: 18px;
  height: 18px;
  border: 3px solid;
  border-right-color: transparent;
}
.gg-open-collective::before {
  display: block;
  box-sizing: border-box;
  border-radius: 22px;
  content: "";
  position: absolute;
  width: 14px;
  height: 14px;
  border: 3px solid transparent;
  border-right: 3px solid;
  top: -1px;
  right: -3px;
  opacity: 0.5;
}

.gg-options {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 10px;
  height: 2px;
  box-shadow: -3px 4px 0 0, 3px -4px 0 0;
}
.gg-options::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 8px;
  height: 8px;
  border: 2px solid;
  border-radius: 100%;
}
.gg-options::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 8px;
  height: 8px;
  border: 2px solid;
  border-radius: 100%;
  top: -7px;
  left: -4px;
}
.gg-options::after {
  bottom: -7px;
  right: -4px;
}

.gg-organisation {
  background: linear-gradient(to bottom, currentColor 5px, transparent 0) no-repeat center 2px/12px 2px, linear-gradient(to bottom, currentColor 5px, transparent 0) no-repeat center 6px/12px 2px, linear-gradient(to bottom, currentColor 5px, transparent 0) no-repeat center 10px/12px 2px, linear-gradient(to bottom, currentColor 18px, transparent 0) no-repeat center/2px 18px;
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 12px;
  height: 18px;
  border: 2px solid;
}
.gg-organisation::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
}
.gg-organisation::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  background: currentColor;
  right: -8px;
  top: 6px;
  box-shadow: 0 4px 0, -4px 4px 0, -4px 0 0;
  width: 2px;
  height: 2px;
}
.gg-organisation::after {
  width: 12px;
  height: 14px;
  border: 2px solid;
  left: 8px;
  bottom: -2px;
}

.gg-overflow {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 16px;
  height: 16px;
  border-radius: 20px;
  border-bottom: 8px solid;
}
.gg-overflow::after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  display: block;
  border-radius: 20px;
}
.gg-overflow::before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  display: block;
  border-radius: 20px;
  width: 20px;
  height: 20px;
  border-bottom: 10px solid;
  opacity: 0.6;
  left: -2px;
  top: -2px;
}
.gg-overflow::after {
  width: 16px;
  height: 16px;
  border-top: 8px solid;
  opacity: 0.2;
  left: 0;
  top: 0;
}

.gg-pacman {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: rotate(45deg) scale(var(--ggs, 1));
  width: 10px;
  height: 10px;
  border-bottom: 2px solid;
  border-left: 2px solid;
}
.gg-pacman::after {
  content: "";
  display: block;
  position: absolute;
  box-sizing: border-box;
}
.gg-pacman::before {
  content: "";
  display: block;
  position: absolute;
  box-sizing: border-box;
  background: currentColor;
  top: 5px;
  border-radius: 3px;
  width: 2px;
  height: 2px;
  left: -5px;
}
.gg-pacman::after {
  width: 18px;
  height: 18px;
  border: 2px solid;
  border-radius: 100px;
  border-right-color: transparent;
  left: -10px;
  transform: rotate(-45deg);
}

.gg-password {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 30px;
  height: 12px;
  border: 2px solid;
  border-radius: 3px;
}
.gg-password::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 4px;
  background: currentColor;
}
.gg-password::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 4px;
  background: currentColor;
  left: 2px;
  top: 2px;
  box-shadow: 6px 0 0, 12px 0 0;
  height: 4px;
  border-radius: 100%;
}
.gg-password::after {
  height: 2px;
  right: 2px;
  bottom: 2px;
}

.gg-path-back {
  display: block;
  position: relative;
  box-sizing: border-box;
  transform: scale(var(--ggs, 1));
  width: 14px;
  height: 14px;
}
.gg-path-back::after, .gg-path-back::before {
  content: "";
  position: absolute;
  display: block;
  box-sizing: border-box;
  width: 10px;
  height: 10px;
}
.gg-path-back::after {
  border: 2px solid;
}
.gg-path-back::before {
  border-right: 5px solid;
  border-bottom: 5px solid;
  bottom: 0;
  right: 0;
}

.gg-path-crop {
  display: block;
  position: relative;
  box-sizing: border-box;
  transform: scale(var(--ggs, 1));
  width: 14px;
  height: 14px;
}
.gg-path-crop::after, .gg-path-crop::before {
  content: "";
  position: absolute;
  display: block;
  box-sizing: border-box;
  border: 2px solid;
  width: 10px;
  height: 10px;
}
.gg-path-crop::after {
  opacity: 0.5;
}
.gg-path-crop::before {
  background: linear-gradient(to left, currentColor 4px, transparent 0) no-repeat top left/4px 4px;
  bottom: 0;
  right: 0;
}

.gg-path-divide {
  display: block;
  position: relative;
  box-sizing: border-box;
  transform: scale(var(--ggs, 1));
  width: 14px;
  height: 14px;
}
.gg-path-divide::after, .gg-path-divide::before {
  content: "";
  position: absolute;
  display: block;
  box-sizing: border-box;
  width: 10px;
  height: 10px;
}
.gg-path-divide::after {
  border-top: 4px solid;
  border-left: 4px solid;
}
.gg-path-divide::before {
  background: linear-gradient(to left, currentColor 4px, transparent 0) no-repeat 1px 1px/4px 4px;
  border-right: 4px solid;
  border-bottom: 4px solid;
  bottom: 0;
  right: 0;
}

.gg-path-exclude {
  display: block;
  position: relative;
  box-sizing: border-box;
  transform: scale(var(--ggs, 1));
  width: 14px;
  height: 14px;
}
.gg-path-exclude::after, .gg-path-exclude::before {
  content: "";
  position: absolute;
  display: block;
  box-sizing: border-box;
  width: 10px;
  height: 10px;
}
.gg-path-exclude::after {
  border-top: 4px solid;
  border-left: 4px solid;
}
.gg-path-exclude::before {
  border-right: 4px solid;
  border-bottom: 4px solid;
  bottom: 0;
  right: 0;
}

.gg-path-front {
  display: block;
  position: relative;
  box-sizing: border-box;
  transform: scale(var(--ggs, 1));
  width: 14px;
  height: 14px;
}
.gg-path-front::after, .gg-path-front::before {
  content: "";
  position: absolute;
  display: block;
  box-sizing: border-box;
  width: 10px;
  height: 10px;
}
.gg-path-front::after {
  border-left: 5px solid;
  border-top: 5px solid;
}
.gg-path-front::before {
  border: 2px solid;
  bottom: 0;
  right: 0;
}

.gg-path-intersect {
  display: block;
  position: relative;
  box-sizing: border-box;
  transform: scale(var(--ggs, 1));
  width: 14px;
  height: 14px;
}
.gg-path-intersect::after {
  content: "";
  position: absolute;
  display: block;
  box-sizing: border-box;
  border: 2px solid;
  width: 10px;
  height: 10px;
}
.gg-path-intersect::before {
  content: "";
  position: absolute;
  display: block;
  box-sizing: border-box;
  border: 2px solid;
  width: 10px;
  height: 10px;
  background: linear-gradient(to left, currentColor 4px, transparent 0) no-repeat top left/4px 4px;
  bottom: 0;
  right: 0;
}

.gg-path-outline {
  display: block;
  position: relative;
  box-sizing: border-box;
  transform: scale(var(--ggs, 1));
  width: 14px;
  height: 14px;
}
.gg-path-outline::after {
  content: "";
  position: absolute;
  display: block;
  box-sizing: border-box;
  border: 2px solid;
  width: 10px;
  height: 10px;
}
.gg-path-outline::before {
  content: "";
  position: absolute;
  display: block;
  box-sizing: border-box;
  border: 2px solid;
  width: 10px;
  height: 10px;
  bottom: 0;
  right: 0;
}

.gg-path-trim {
  display: block;
  position: relative;
  box-sizing: border-box;
  transform: scale(var(--ggs, 1));
  width: 14px;
  height: 14px;
}
.gg-path-trim::after, .gg-path-trim::before {
  content: "";
  position: absolute;
  display: block;
  box-sizing: border-box;
  width: 10px;
  height: 10px;
}
.gg-path-trim::after {
  border-left: 3px solid;
  border-top: 3px solid;
}
.gg-path-trim::before {
  background: currentColor;
  bottom: 0;
  right: 0;
}

.gg-path-unite {
  display: block;
  position: relative;
  box-sizing: border-box;
  transform: scale(var(--ggs, 1));
  width: 14px;
  height: 14px;
}
.gg-path-unite::after {
  content: "";
  position: absolute;
  display: block;
  box-sizing: border-box;
  background: currentColor;
  width: 10px;
  height: 10px;
}
.gg-path-unite::before {
  content: "";
  position: absolute;
  display: block;
  box-sizing: border-box;
  background: currentColor;
  width: 10px;
  height: 10px;
  bottom: 0;
  right: 0;
}

.gg-patreon {
  transform: scale(var(--ggs, 1));
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 4px;
  height: 16px;
  background: currentColor;
  left: -6px;
}
.gg-patreon::after {
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 4px;
  height: 16px;
  background: currentColor;
  left: -6px;
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  opacity: 0.5;
  border-radius: 100px;
  left: 6px;
}

.gg-paypal {
  position: relative;
  box-sizing: border-box;
  display: block;
  width: 16px;
  height: 18px;
  transform: skew(-10deg) scale(var(--ggs, 1));
  background: linear-gradient(to left, currentColor 20px, transparent 0) no-repeat 3px bottom/4px 2px, linear-gradient(to left, currentColor 20px, transparent 0) no-repeat 5px bottom/3px 10px;
}
.gg-paypal::after {
  content: "";
  box-sizing: border-box;
  display: block;
  position: absolute;
  border: 2px solid;
}
.gg-paypal::before {
  content: "";
  box-sizing: border-box;
  display: block;
  position: absolute;
  border: 2px solid;
  width: 8px;
  height: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-left: 0;
  left: 5px;
  box-shadow: 2px 2px 0;
}
.gg-paypal::after {
  width: 6px;
  height: 16px;
  border-right: 0;
}

.gg-pen {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: rotate(-45deg) scale(var(--ggs, 1));
  width: 14px;
  height: 4px;
  border-right: 2px solid transparent;
  box-shadow: 0 0 0 2px, inset -2px 0 0;
  border-top-right-radius: 1px;
  border-bottom-right-radius: 1px;
  margin-right: -2px;
}
.gg-pen::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
}
.gg-pen::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  background: currentColor;
  border-left: 0;
  right: -6px;
  width: 3px;
  height: 4px;
  border-radius: 1px;
  top: 0;
}
.gg-pen::after {
  width: 8px;
  height: 7px;
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-right: 7px solid;
  left: -11px;
  top: -2px;
}

.gg-pentagon-bottom-left {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: rotate(45deg) scale(var(--ggs, 1));
  width: 10px;
  height: 10px;
}
.gg-pentagon-bottom-left:before {
  width: 51%;
}
.gg-pentagon-bottom-left:after {
  content: " ";
  position: absolute;
  top: 0;
  height: 100%;
}
.gg-pentagon-bottom-left:before {
  content: " ";
  position: absolute;
  top: 0;
  height: 100%;
  left: -2px;
  border-top: 2px solid;
  border-left: 2px solid;
  border-bottom: 2px solid;
  transform: skew(0deg, 30deg);
}
.gg-pentagon-bottom-left:after {
  right: -2px;
  width: 50%;
  border-right: 2px solid;
  border-bottom: 2px solid;
  border-top: 2px solid;
  transform: skew(0deg, -30deg);
}

.gg-pentagon-bottom-right {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: rotate(-45deg) scale(var(--ggs, 1));
  width: 10px;
  height: 10px;
}
.gg-pentagon-bottom-right:before {
  width: 51%;
}
.gg-pentagon-bottom-right:after {
  content: " ";
  position: absolute;
  top: 0;
  height: 100%;
}
.gg-pentagon-bottom-right:before {
  content: " ";
  position: absolute;
  top: 0;
  height: 100%;
  left: -2px;
  border-top: 2px solid;
  border-left: 2px solid;
  border-bottom: 2px solid;
  transform: skew(0deg, 30deg);
}
.gg-pentagon-bottom-right:after {
  right: -2px;
  width: 50%;
  border-right: 2px solid;
  border-bottom: 2px solid;
  border-top: 2px solid;
  transform: skew(0deg, -30deg);
}

.gg-pentagon-down {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 10px;
  height: 10px;
}
.gg-pentagon-down:before {
  width: 51%;
}
.gg-pentagon-down:after {
  content: " ";
  position: absolute;
  top: 0;
  height: 100%;
}
.gg-pentagon-down:before {
  content: " ";
  position: absolute;
  top: 0;
  height: 100%;
  left: -2px;
  border-top: 2px solid;
  border-left: 2px solid;
  border-bottom: 2px solid;
  transform: skew(0deg, 30deg);
}
.gg-pentagon-down:after {
  right: -2px;
  width: 50%;
  border-right: 2px solid;
  border-bottom: 2px solid;
  border-top: 2px solid;
  transform: skew(0deg, -30deg);
}

.gg-pentagon-left {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 10px;
  height: 8px;
}
.gg-pentagon-left:before {
  height: 51%;
}
.gg-pentagon-left:after {
  content: " ";
  position: absolute;
  left: 0;
  width: 100%;
}
.gg-pentagon-left:before {
  content: " ";
  position: absolute;
  left: 0;
  width: 100%;
  top: -2px;
  border-top: 2px solid;
  border-right: 2px solid;
  border-left: 2px solid;
  transform: skewX(-30deg);
}
.gg-pentagon-left:after {
  bottom: -2px;
  height: 50%;
  border-right: 2px solid;
  border-bottom: 2px solid;
  border-left: 2px solid;
  transform: skewX(30deg);
}

.gg-pentagon-right {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 10px;
  height: 8px;
}
.gg-pentagon-right:before {
  height: 51%;
}
.gg-pentagon-right:after {
  content: " ";
  position: absolute;
  left: 0;
  width: 100%;
}
.gg-pentagon-right:before {
  content: " ";
  position: absolute;
  left: 0;
  width: 100%;
  top: -2px;
  border-top: 2px solid;
  border-right: 2px solid;
  border-left: 2px solid;
  transform: skewX(30deg);
}
.gg-pentagon-right:after {
  bottom: -2px;
  height: 50%;
  border-right: 2px solid;
  border-bottom: 2px solid;
  border-left: 2px solid;
  transform: skewX(-30deg);
}

.gg-pentagon-top-left {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: rotate(45deg) scale(var(--ggs, 1));
  width: 10px;
  height: 8px;
}
.gg-pentagon-top-left:before {
  height: 51%;
}
.gg-pentagon-top-left:after {
  content: " ";
  position: absolute;
  left: 0;
  width: 100%;
}
.gg-pentagon-top-left:before {
  content: " ";
  position: absolute;
  left: 0;
  width: 100%;
  top: -2px;
  border-top: 2px solid;
  border-right: 2px solid;
  border-left: 2px solid;
  transform: skewX(-30deg);
}
.gg-pentagon-top-left:after {
  bottom: -2px;
  height: 50%;
  border-right: 2px solid;
  border-bottom: 2px solid;
  border-left: 2px solid;
  transform: skewX(30deg);
}

.gg-pentagon-top-right {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: rotate(-45deg) scale(var(--ggs, 1));
  width: 10px;
  height: 8px;
}
.gg-pentagon-top-right:before {
  height: 51%;
}
.gg-pentagon-top-right:after {
  content: " ";
  position: absolute;
  left: 0;
  width: 100%;
}
.gg-pentagon-top-right:before {
  content: " ";
  position: absolute;
  left: 0;
  width: 100%;
  top: -2px;
  border-top: 2px solid;
  border-right: 2px solid;
  border-left: 2px solid;
  transform: skewX(30deg);
}
.gg-pentagon-top-right:after {
  bottom: -2px;
  height: 50%;
  border-right: 2px solid;
  border-bottom: 2px solid;
  border-left: 2px solid;
  transform: skewX(-30deg);
}

.gg-pentagon-up {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 10px;
  height: 10px;
}
.gg-pentagon-up:before {
  width: 51%;
}
.gg-pentagon-up:after {
  content: " ";
  position: absolute;
  top: 0;
  height: 100%;
}
.gg-pentagon-up:before {
  content: " ";
  position: absolute;
  top: 0;
  height: 100%;
  left: -2px;
  border-top: 2px solid;
  border-left: 2px solid;
  border-bottom: 2px solid;
  transform: skew(0deg, -30deg);
}
.gg-pentagon-up:after {
  right: -2px;
  width: 50%;
  border-right: 2px solid;
  border-bottom: 2px solid;
  border-top: 2px solid;
  transform: skew(0deg, 30deg);
}

.gg-performance {
  background: linear-gradient(to left, currentColor 8px, transparent 0) no-repeat center/2px 6px;
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 22px;
  border: 2px solid;
  border-radius: 100px;
}
.gg-performance::after {
  content: "";
  display: block;
  position: absolute;
  border: 2px solid;
}
.gg-performance::before {
  content: "";
  display: block;
  position: absolute;
  border: 2px solid;
  bottom: 2px;
  width: 2px;
  height: 2px;
  box-sizing: content-box;
  border-radius: 100px;
  left: 6px;
}
.gg-performance::after {
  box-sizing: border-box;
  border-radius: 3px;
  width: 14px;
  height: 8px;
  border-top-left-radius: 100px;
  border-top-right-radius: 100px;
  border-bottom: 0;
  top: 2px;
  left: 2px;
}

.gg-pexels {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 6px;
  height: 14px;
  border: 2px solid;
  border-right: 0;
}
.gg-pexels::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
}
.gg-pexels::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 5px;
  height: 10px;
  border: 2px solid;
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
  border-left: 0;
  right: -5px;
  top: -2px;
}
.gg-pexels::after {
  background: currentColor;
  width: 2px;
  height: 6px;
  bottom: -2px;
  right: -1px;
}

.gg-phone {
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 22px;
  height: 22px;
  transform: scale(var(--ggs, 1));
}
.gg-phone::after, .gg-phone::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
}
.gg-phone::after {
  width: 18px;
  height: 18px;
  border-top-left-radius: 1px;
  border-bottom-right-radius: 1px;
  border-bottom-left-radius: 12px;
  border-left: 4px solid;
  border-bottom: 4px solid;
  left: 2px;
  bottom: 2px;
  background: linear-gradient(to left, currentColor 10px, transparent 0) no-repeat right 11px/6px 4px, linear-gradient(to left, currentColor 10px, transparent 0) no-repeat -1px 0/4px 6px;
}
.gg-phone::before {
  width: 20px;
  height: 20px;
  border: 6px double;
  border-top-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent;
  border-radius: 50%;
  transform: rotate(-45deg);
  bottom: 2px;
  left: 2px;
}

.gg-photoscan {
  box-sizing: border-box;
  display: block;
  position: relative;
  transform: scale(var(--ggs, 1));
  border-radius: 2px;
  width: 14px;
  height: 18px;
  border: 2px solid;
  border-bottom: 4px solid;
}
.gg-photoscan::before {
  content: "";
  position: absolute;
  box-sizing: border-box;
  display: block;
  width: 4px;
  height: 4px;
  border-radius: 20px;
  box-shadow: 0 0 0 2px, inset 0 0 0 2px;
  border: 1px solid transparent;
}

.gg-piano {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 23px;
  height: 18px;
  border: 2px solid;
  border-radius: 2px;
}
.gg-piano::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  height: 10px;
  background: currentColor;
  box-shadow: 5px 0 0, 10px 0 0;
}
.gg-piano::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  height: 10px;
  background: currentColor;
  box-shadow: 5px 0 0, 10px 0 0;
  top: -2px;
  width: 3px;
  left: 3px;
}
.gg-piano::after {
  width: 1px;
  bottom: -2px;
  left: 4px;
}

.gg-pill {
  display: block;
  box-sizing: border-box;
  width: 12px;
  border-top-left-radius: 140px;
  border-top-right-radius: 140px;
  border: 2px solid;
  transform: rotate(45deg) scale(var(--ggs, 1));
  position: relative;
  height: 14px;
}
.gg-pill::after {
  display: block;
  box-sizing: border-box;
  width: 12px;
  content: "";
  position: absolute;
  height: 12px;
  background: currentColor;
  left: -2px;
  bottom: -12px;
  border-bottom-left-radius: 140px;
  border-bottom-right-radius: 140px;
}

.gg-pin-alt {
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 2px;
  height: 8px;
  border-radius: 3px;
  transform: scale(var(--ggs, 1));
  background: currentColor;
}
.gg-pin-alt::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 12px;
  height: 12px;
  border: 6px double;
  border-radius: 100px;
  top: -10px;
  left: -5px;
}

.gg-pin-bottom {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 2px;
  height: 8px;
  background: currentColor;
}
.gg-pin-bottom::after, .gg-pin-bottom::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
}
.gg-pin-bottom::after {
  background: currentColor;
  bottom: -2px;
  border-radius: 3px;
  width: 10px;
  height: 2px;
  left: -4px;
}
.gg-pin-bottom::before {
  width: 8px;
  height: 8px;
  border: 6px double;
  border-radius: 100px;
  top: -10px;
  left: -5px;
}

.gg-pin-top {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 2px;
  height: 8px;
  background: currentColor;
  margin-top: -8px;
}
.gg-pin-top::after, .gg-pin-top::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
}
.gg-pin-top::after {
  background: currentColor;
  top: -2px;
  border-radius: 3px;
  width: 10px;
  height: 2px;
  left: -4px;
}
.gg-pin-top::before {
  width: 8px;
  height: 8px;
  border: 6px double;
  border-radius: 100px;
  bottom: -10px;
  left: -5px;
}

.gg-pin {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: rotate(45deg) scale(var(--ggs, 1));
  width: 18px;
  height: 18px;
  border-radius: 100% 100% 0 100%;
  border: 2px solid;
  margin-top: -4px;
}
.gg-pin::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 8px;
  height: 8px;
  border: 2px solid;
  top: 3px;
  left: 3px;
  border-radius: 40px;
}

.gg-play-backwards {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 3px;
  height: 10px;
  background: currentColor;
}
.gg-play-backwards::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 0;
  height: 10px;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-right: 6px solid;
  right: -7px;
}
.gg-play-backwards::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 0;
  height: 10px;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-right: 6px solid;
  right: -7px;
  right: -14px;
}

.gg-play-button-o {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 22px;
  border: 2px solid;
  border-radius: 20px;
}
.gg-play-button-o::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 0;
  height: 10px;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 6px solid;
  top: 4px;
  left: 7px;
}

.gg-play-button-r {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 22px;
  border: 2px solid;
  border-radius: 4px;
}
.gg-play-button-r::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 0;
  height: 10px;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 6px solid;
  top: 4px;
  left: 7px;
}

.gg-play-button {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 22px;
}
.gg-play-button::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 0;
  height: 10px;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 6px solid;
  top: 6px;
  left: 9px;
}

.gg-play-forwards {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 3px;
  height: 10px;
  background: currentColor;
}
.gg-play-forwards::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 0;
  height: 10px;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 6px solid;
  left: -7px;
}
.gg-play-forwards::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 0;
  height: 10px;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 6px solid;
  left: -7px;
  left: -14px;
}

.gg-play-list-add {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 12px;
  height: 6px;
  border-top: 0 solid transparent;
  border-bottom: 2px solid transparent;
  box-shadow: inset 0 -2px 0, -2px 4px 0 -2px, 0 -2px 0 0;
}
.gg-play-list-add::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 10px;
  height: 2px;
  background: currentColor;
  top: 6px;
  right: -8px;
}
.gg-play-list-add::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 10px;
  height: 2px;
  background: currentColor;
  top: 6px;
  right: -8px;
  width: 2px;
  height: 10px;
  top: 2px;
  right: -4px;
}

.gg-play-list-check {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 12px;
  height: 6px;
  border-top: 0 solid transparent;
  border-bottom: 2px solid transparent;
  box-shadow: inset 0 -2px 0, -2px 4px 0 -2px, 0 -2px 0 0;
}
.gg-play-list-check::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 5px;
  height: 8px;
  border-right: 2px solid;
  border-bottom: 2px solid;
  transform: rotate(45deg);
  top: 2px;
  right: -4px;
}

.gg-play-list-remove {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 12px;
  height: 6px;
  border-top: 0 solid transparent;
  border-bottom: 2px solid transparent;
  box-shadow: inset 0 -2px 0, -2px 4px 0 -2px, 0 -2px 0 0;
}
.gg-play-list-remove::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 8px;
  height: 2px;
  background: currentColor;
  transform: rotate(-45deg);
  top: 8px;
  right: -4px;
}
.gg-play-list-remove::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 8px;
  height: 2px;
  background: currentColor;
  transform: rotate(-45deg);
  top: 8px;
  right: -4px;
  width: 2px;
  height: 8px;
  top: 5px;
  right: -1px;
}

.gg-play-list-search {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 12px;
  height: 6px;
  border-top: 0 solid transparent;
  border-bottom: 2px solid transparent;
  box-shadow: inset 0 -2px 0, -2px 4px 0 -2px, 0 -2px 0 0;
}
.gg-play-list-search::after, .gg-play-list-search::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  bottom: -7px;
}
.gg-play-list-search::after {
  width: 3px;
  height: 2px;
  background: currentColor;
  transform: rotate(45deg);
  right: -4px;
}
.gg-play-list-search::before {
  width: 6px;
  height: 6px;
  border-radius: 4px;
  border: 2px solid;
  right: -3px;
}

.gg-play-list {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 12px;
  height: 6px;
  border-top: 0 solid transparent;
  border-bottom: 2px solid transparent;
  box-shadow: inset 0 -2px 0, -2px 4px 0 -2px, 0 -2px 0 0;
}
.gg-play-list::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 0;
  height: 0;
  border-top: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-left: 5px solid;
  top: 6px;
  right: -3px;
}

.gg-play-pause-o {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 22px;
  border: 2px solid;
  border-radius: 22px;
}
.gg-play-pause-o::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 6px;
  height: 6px;
  left: 6px;
  top: 6px;
  border-left: 2px solid;
  border-right: 2px solid;
}

.gg-play-pause-r {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 22px;
  border: 2px solid;
  border-radius: 4px;
}
.gg-play-pause-r::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 6px;
  height: 6px;
  left: 6px;
  top: 6px;
  border-left: 2px solid;
  border-right: 2px solid;
}

.gg-play-pause {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 8px;
  height: 10px;
  border-left: 3px solid;
  border-right: 3px solid;
}

.gg-play-stop-o {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 22px;
  border: 2px solid;
  border-radius: 20px;
}
.gg-play-stop-o::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 6px;
  height: 6px;
  top: 6px;
  left: 6px;
  background: currentColor;
}

.gg-play-stop-r {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 22px;
  border: 2px solid;
  border-radius: 4px;
}
.gg-play-stop-r::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 6px;
  height: 6px;
  top: 6px;
  left: 6px;
  background: currentColor;
}

.gg-play-stop {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 10px;
  height: 10px;
  background: currentColor;
}

.gg-play-track-next-o {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 22px;
  border: 2px solid;
  border-radius: 20px;
}
.gg-play-track-next-o::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  height: 8px;
  top: 5px;
}
.gg-play-track-next-o::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  height: 8px;
  top: 5px;
  width: 2px;
  border-radius: 2px;
  left: 11px;
  background: currentColor;
}
.gg-play-track-next-o::after {
  width: 0;
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-left: 5px solid;
  left: 5px;
}

.gg-play-track-next-r {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 22px;
  border: 2px solid;
  border-radius: 4px;
}
.gg-play-track-next-r::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  height: 8px;
  top: 5px;
}
.gg-play-track-next-r::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  height: 8px;
  top: 5px;
  width: 2px;
  border-radius: 2px;
  left: 11px;
  background: currentColor;
}
.gg-play-track-next-r::after {
  width: 0;
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-left: 5px solid;
  left: 5px;
}

.gg-play-track-next {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 3px;
  height: 10px;
  background: currentColor;
}
.gg-play-track-next::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 0;
  height: 10px;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 6px solid;
  left: -7px;
}

.gg-play-track-prev-o {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 22px;
  border: 2px solid;
  border-radius: 20px;
}
.gg-play-track-prev-o::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  height: 8px;
  top: 5px;
}
.gg-play-track-prev-o::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  height: 8px;
  top: 5px;
  width: 2px;
  border-radius: 2px;
  right: 11px;
  background: currentColor;
}
.gg-play-track-prev-o::after {
  width: 0;
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-right: 5px solid;
  right: 5px;
}

.gg-play-track-prev-r {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 22px;
  border: 2px solid;
  border-radius: 4px;
}
.gg-play-track-prev-r::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  height: 8px;
  top: 5px;
}
.gg-play-track-prev-r::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  height: 8px;
  top: 5px;
  width: 2px;
  border-radius: 2px;
  right: 11px;
  background: currentColor;
}
.gg-play-track-prev-r::after {
  width: 0;
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-right: 5px solid;
  right: 5px;
}

.gg-play-track-prev {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 3px;
  height: 10px;
  background: currentColor;
}
.gg-play-track-prev::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 0;
  height: 10px;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-right: 6px solid;
  right: -7px;
}

.gg-plug {
  position: relative;
  display: block;
  box-sizing: border-box;
  transform: scale(var(--ggs, 1));
  width: 12px;
  height: 20px;
}
.gg-plug::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
}
.gg-plug::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 12px;
  height: 10px;
  border: 2px solid;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  top: 5px;
}
.gg-plug::after {
  width: 2px;
  height: 4px;
  background: currentColor;
  left: 2px;
  border-radius: 22px;
  box-shadow: 6px 0 0, 3px 14px 0, 3px 16px 0;
}

.gg-pocket {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 20px;
  border: 2px solid;
  border-radius: 6px;
  border-bottom-left-radius: 22px;
  border-bottom-right-radius: 22px;
}
.gg-pocket::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border-radius: 22px;
  width: 8px;
  height: 2px;
  background: currentColor;
  bottom: 7px;
}
.gg-pocket::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border-radius: 22px;
  width: 8px;
  height: 2px;
  background: currentColor;
  bottom: 7px;
  transform: rotate(-48deg);
  right: 3px;
}
.gg-pocket::after {
  transform: rotate(48deg);
  left: 3px;
}

.gg-pokemon {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 18px;
  height: 18px;
  border: 5px solid transparent;
  box-shadow: 0 0 0 2px, inset 0 0 0 2px;
  border-radius: 100%;
}
.gg-pokemon::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 20px;
  height: 2px;
  border-left: 8px solid;
  border-right: 8px solid;
  left: -6px;
  top: 3px;
}

.gg-polaroid {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 18px;
  height: 20px;
  border: 2px solid;
  border-radius: 2px;
  box-shadow: inset 0 -5px 0;
}

.gg-poll {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 16px;
  height: 16px;
  border: 3px solid transparent;
  border-top: 6px solid transparent;
  box-shadow: 0 0 0 2px, inset 2px 0 0;
  border-radius: 1px;
}
.gg-poll::after, .gg-poll::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 2px;
  height: 10px;
  background: currentColor;
  top: -3px;
  left: 4px;
}
.gg-poll::after {
  height: 4px;
  top: 3px;
  left: 8px;
}

.gg-presentation {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 20px;
  height: 20px;
  border: 2px solid;
  border-radius: 22px;
}
.gg-presentation::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
}
.gg-presentation::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 12px;
  height: 6px;
  border: 2px solid;
  border-top-left-radius: 100px;
  border-top-right-radius: 100px;
  top: 2px;
  left: 2px;
  border-bottom: 0;
}
.gg-presentation::after {
  width: 18px;
  height: 2px;
  background: currentColor;
  left: -1px;
  top: 8px;
}

.gg-printer {
  background: linear-gradient(to left, currentColor 5px, transparent 0) no-repeat 0 10px/6px 2px, linear-gradient(to left, currentColor 5px, transparent 0) no-repeat 14px 10px/6px 2px, linear-gradient(to left, currentColor 5px, transparent 0) no-repeat 4px 4px/2px 2px;
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 24px;
  height: 14px;
  border: 2px solid transparent;
  border-bottom: 0;
  box-shadow: inset 0 2px 0, inset 2px 2px 0, inset -2px 2px 0, inset -2px 2px 0;
}
.gg-printer::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 12px;
  border: 2px solid;
  left: 4px;
}
.gg-printer::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 12px;
  border: 2px solid;
  left: 4px;
  height: 6px;
  top: -4px;
}
.gg-printer::after {
  height: 8px;
  top: 8px;
}

.gg-product-hunt {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 18px;
  height: 18px;
  border: 2px solid;
  border-radius: 100px;
}
.gg-product-hunt::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  top: 3px;
}
.gg-product-hunt::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  top: 3px;
  border: 2px solid;
  border-left: 0;
  border-top-right-radius: 40px;
  border-bottom-right-radius: 40px;
  width: 6px;
  height: 6px;
  left: 5px;
}
.gg-product-hunt::after {
  width: 2px;
  height: 8px;
  background: currentColor;
  left: 4px;
}

.gg-profile {
  display: block;
  box-sizing: border-box;
  border: 2px solid;
  border-radius: 100px;
  overflow: hidden;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 22px;
  position: relative;
}
.gg-profile::after, .gg-profile::before {
  display: block;
  box-sizing: border-box;
  border: 2px solid;
  border-radius: 100px;
}
.gg-profile::after, .gg-profile::before {
  content: "";
  position: absolute;
  top: 2px;
  left: 5px;
  width: 8px;
  height: 8px;
}
.gg-profile::after {
  border-radius: 200px;
  top: 11px;
  left: 0px;
  width: 18px;
  height: 18px;
}

.gg-pull-clear {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 20px;
  height: 12px;
  border: 2px solid;
  border-top: 0;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
}
.gg-pull-clear::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 12px;
  height: 2px;
  bottom: 2px;
  left: 2px;
  background: currentColor;
  box-shadow: 0 -4px 0 0;
}

.gg-push-chevron-down-o {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 22px;
  border: 2px solid;
  border-radius: 100px;
}
.gg-push-chevron-down-o::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border-bottom: 2px solid;
}
.gg-push-chevron-down-o::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border-bottom: 2px solid;
  bottom: 5px;
  width: 8px;
  height: 2px;
  left: 5px;
}
.gg-push-chevron-down-o::after {
  width: 6px;
  height: 6px;
  border-right: 2px solid;
  transform: rotate(45deg);
  left: 6px;
  top: 3px;
}

.gg-push-chevron-down-r {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 22px;
  border: 2px solid;
  border-radius: 4px;
}
.gg-push-chevron-down-r::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border-bottom: 2px solid;
}
.gg-push-chevron-down-r::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border-bottom: 2px solid;
  bottom: 5px;
  width: 8px;
  height: 2px;
  left: 5px;
}
.gg-push-chevron-down-r::after {
  width: 6px;
  height: 6px;
  border-right: 2px solid;
  transform: rotate(45deg);
  left: 6px;
  top: 3px;
}

.gg-push-chevron-down {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 22px;
  border: 2px solid transparent;
  border-radius: 100px;
}
.gg-push-chevron-down::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border-bottom: 2px solid;
}
.gg-push-chevron-down::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border-bottom: 2px solid;
  bottom: 0;
  width: 14px;
  height: 2px;
  left: 2px;
}
.gg-push-chevron-down::after {
  width: 10px;
  height: 10px;
  border-right: 2px solid;
  transform: rotate(45deg);
  left: 4px;
  top: 2px;
}

.gg-push-chevron-left-o {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 22px;
  border: 2px solid;
  border-radius: 100px;
}
.gg-push-chevron-left-o::after, .gg-push-chevron-left-o::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 2px;
  height: 8px;
  border-left: 2px solid;
  top: 5px;
  left: 5px;
}
.gg-push-chevron-left-o::after {
  width: 6px;
  height: 6px;
  border-bottom: 2px solid;
  transform: rotate(45deg);
  left: 9px;
  top: 6px;
}

.gg-push-chevron-left-r {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 22px;
  border: 2px solid;
  border-radius: 4px;
}
.gg-push-chevron-left-r::after, .gg-push-chevron-left-r::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 2px;
  height: 8px;
  border-left: 2px solid;
  top: 5px;
  left: 5px;
}
.gg-push-chevron-left-r::after {
  width: 6px;
  height: 6px;
  border-bottom: 2px solid;
  transform: rotate(45deg);
  left: 9px;
  top: 6px;
}

.gg-push-chevron-left {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 22px;
  border: 2px solid transparent;
  border-radius: 100px;
}
.gg-push-chevron-left::after, .gg-push-chevron-left::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 2px;
  height: 14px;
  border-left: 2px solid;
  top: 2px;
  left: 0;
}
.gg-push-chevron-left::after {
  width: 10px;
  height: 10px;
  border-bottom: 2px solid;
  transform: rotate(45deg);
  left: 6px;
  top: 4px;
}

.gg-push-chevron-right-o {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 22px;
  border: 2px solid;
  border-radius: 100px;
}
.gg-push-chevron-right-o::after, .gg-push-chevron-right-o::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 2px;
  height: 8px;
  border-right: 2px solid;
  top: 5px;
  right: 5px;
}
.gg-push-chevron-right-o::after {
  width: 6px;
  height: 6px;
  border-bottom: 2px solid;
  transform: rotate(-45deg);
  right: 9px;
  top: 6px;
}

.gg-push-chevron-right-r {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 22px;
  border: 2px solid;
  border-radius: 4px;
}
.gg-push-chevron-right-r::after, .gg-push-chevron-right-r::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 2px;
  height: 8px;
  border-right: 2px solid;
  top: 5px;
  right: 5px;
}
.gg-push-chevron-right-r::after {
  width: 6px;
  height: 6px;
  border-bottom: 2px solid;
  transform: rotate(-45deg);
  right: 9px;
  top: 6px;
}

.gg-push-chevron-right {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 22px;
  border: 2px solid transparent;
  border-radius: 100px;
}
.gg-push-chevron-right::after, .gg-push-chevron-right::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 2px;
  height: 14px;
  border-right: 2px solid;
  top: 2px;
  right: 0;
}
.gg-push-chevron-right::after {
  width: 10px;
  height: 10px;
  border-bottom: 2px solid;
  transform: rotate(-45deg);
  right: 6px;
  top: 4px;
}

.gg-push-chevron-up-o {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 22px;
  border: 2px solid;
  border-radius: 100px;
}
.gg-push-chevron-up-o::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border-top: 2px solid;
}
.gg-push-chevron-up-o::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border-top: 2px solid;
  top: 5px;
  width: 8px;
  height: 2px;
  left: 5px;
}
.gg-push-chevron-up-o::after {
  width: 6px;
  height: 6px;
  border-right: 2px solid;
  transform: rotate(-45deg);
  left: 6px;
  bottom: 3px;
}

.gg-push-chevron-up-r {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 22px;
  border: 2px solid;
  border-radius: 4px;
}
.gg-push-chevron-up-r::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border-top: 2px solid;
}
.gg-push-chevron-up-r::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border-top: 2px solid;
  top: 5px;
  width: 8px;
  height: 2px;
  left: 5px;
}
.gg-push-chevron-up-r::after {
  width: 6px;
  height: 6px;
  border-right: 2px solid;
  transform: rotate(-45deg);
  left: 6px;
  bottom: 3px;
}

.gg-push-chevron-up {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 22px;
  border: 2px solid transparent;
  border-radius: 100px;
}
.gg-push-chevron-up::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border-top: 2px solid;
}
.gg-push-chevron-up::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border-top: 2px solid;
  top: 0;
  width: 14px;
  height: 2px;
  left: 2px;
}
.gg-push-chevron-up::after {
  width: 10px;
  height: 10px;
  border-right: 2px solid;
  transform: rotate(-45deg);
  left: 4px;
  bottom: 2px;
}

.gg-push-down {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 2px;
  height: 16px;
  background: currentColor;
}
.gg-push-down::after, .gg-push-down::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 12px;
  height: 2px;
  border-bottom: 2px solid;
  bottom: -5px;
  left: -5px;
}
.gg-push-down::after {
  width: 8px;
  height: 8px;
  border-right: 2px solid;
  transform: rotate(45deg);
  left: -3px;
  bottom: 0;
}

.gg-push-left {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 16px;
  height: 2px;
  background: currentColor;
}
.gg-push-left::after, .gg-push-left::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 2px;
  height: 12px;
  border-left: 2px solid;
  left: -5px;
  top: -5px;
}
.gg-push-left::after {
  width: 8px;
  height: 8px;
  border-bottom: 2px solid;
  transform: rotate(45deg);
  top: -3px;
  left: 0;
}

.gg-push-right {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 16px;
  height: 2px;
  background: currentColor;
}
.gg-push-right::after, .gg-push-right::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 2px;
  height: 12px;
  border-right: 2px solid;
  right: -5px;
  top: -5px;
}
.gg-push-right::after {
  width: 8px;
  height: 8px;
  border-top: 2px solid;
  transform: rotate(45deg);
  top: -3px;
  right: 0;
}

.gg-push-up {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 2px;
  height: 16px;
  background: currentColor;
}
.gg-push-up::after, .gg-push-up::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 12px;
  height: 2px;
  border-top: 2px solid;
  top: -5px;
  left: -5px;
}
.gg-push-up::after {
  width: 8px;
  height: 8px;
  border-left: 2px solid;
  transform: rotate(45deg);
  left: -3px;
  top: 0;
}

.gg-qr {
  transform: scale(var(--ggs, 1));
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 14px;
  height: 14px;
  box-shadow: -6px -6px 0 -4px, 6px 6px 0 -4px, 6px -6px 0 -4px, -6px 6px 0 -4px;
}
.gg-qr::after {
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 14px;
  height: 14px;
  box-shadow: -6px -6px 0 -4px, 6px 6px 0 -4px, 6px -6px 0 -4px, -6px 6px 0 -4px;
  content: "";
  position: absolute;
  width: 4px;
  height: 4px;
  background: currentColor;
  box-shadow: 0 6px 0, 6px 6px 0, 6px 0 0;
  top: 2px;
  left: 2px;
}

.gg-quote-o {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 20px;
  height: 18px;
  border: 2px solid;
  border-radius: 2px;
}
.gg-quote-o::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 8px;
  height: 6px;
  border-left: 3px solid;
  border-right: 3px solid;
  left: 4px;
  top: 4px;
  transform: skewX(-20deg);
}

.gg-quote {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 20px;
  height: 18px;
  border: 2px solid transparent;
  border-radius: 2px;
}
.gg-quote::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 8px;
  height: 6px;
  border-left: 3px solid;
  border-right: 3px solid;
  left: 4px;
  top: 4px;
  transform: skewX(-20deg);
}

.gg-radio-check {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 20px;
  height: 20px;
  border: 2px solid;
  border-radius: 100px;
}

.gg-radio-checked {
  display: block;
  box-sizing: border-box;
  border-radius: 100px;
  border: 2px solid;
  position: relative;
  transform: scale(var(--ggs, 1));
  width: 20px;
  height: 20px;
}
.gg-radio-checked::after {
  display: block;
  box-sizing: border-box;
  border-radius: 100px;
  content: "";
  position: absolute;
  width: 8px;
  height: 8px;
  background: currentColor;
  top: 4px;
  left: 4px;
}

.gg-ratio {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 24px;
  height: 20px;
  border: 2px solid;
  border-radius: 4px;
}
.gg-ratio::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 6px;
  height: 6px;
}
.gg-ratio::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 6px;
  height: 6px;
  border-bottom: 2px solid;
  border-right: 2px solid;
  top: 8px;
  left: 12px;
}
.gg-ratio::after {
  border-top: 2px solid;
  border-left: 2px solid;
  top: 2px;
  left: 2px;
}

.gg-read {
  display: block;
  box-sizing: border-box;
  border: 2px solid;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-color: transparent;
  margin-left: -1px;
  position: relative;
  transform: scale(var(--ggs, 1));
  width: 6px;
  height: 5px;
  border-radius: 50px;
}
.gg-read::after, .gg-read::before {
  display: block;
  box-sizing: border-box;
  border: 2px solid;
}
.gg-read::after, .gg-read::before {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 10px;
  left: -10px;
  top: -5px;
  box-shadow: -6px 0 0 -4px currentColor;
}
.gg-read::after {
  left: 2px;
  box-shadow: 6px 0 0 -4px currentColor;
}

.gg-readme {
  display: block;
  position: relative;
  box-sizing: border-box;
  transform: scale(var(--ggs, 1));
  width: 2px;
  height: 17px;
  border-bottom: 4px solid;
  border-radius: 4px;
}
.gg-readme::after {
  content: "";
  display: block;
  position: absolute;
  box-sizing: border-box;
  width: 8px;
  height: 12px;
  box-shadow: 0 0 0 2px;
  border-radius: 1px;
  bottom: -1px;
  background: linear-gradient(to left, currentColor 4px, transparent 0) no-repeat center 5px/4px 2px, linear-gradient(to left, currentColor 4px, transparent 0) no-repeat center 2px/4px 2px, linear-gradient(to left, currentColor 4px, transparent 0) no-repeat center 8px/4px 2px;
}
.gg-readme::before {
  content: "";
  display: block;
  position: absolute;
  box-sizing: border-box;
  width: 8px;
  height: 12px;
  box-shadow: 0 0 0 2px;
  border-radius: 1px;
  bottom: -1px;
  background: linear-gradient(to left, currentColor 4px, transparent 0) no-repeat center 5px/4px 2px, linear-gradient(to left, currentColor 4px, transparent 0) no-repeat center 2px/4px 2px, linear-gradient(to left, currentColor 4px, transparent 0) no-repeat center 8px/4px 2px;
  border-top-right-radius: 3px;
  left: -8px;
}
.gg-readme::after {
  border-top-left-radius: 3px;
  right: -8px;
}

.gg-record {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 20px;
  height: 20px;
  border: 6px solid transparent;
  box-shadow: 0 0 0 2px, inset 0 0 0 10px;
  border-radius: 100px;
}

.gg-redo {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 14px;
  height: 14px;
  border: 2px solid;
  border-right-color: transparent;
  border-radius: 100px;
}
.gg-redo::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 6px;
  height: 6px;
  border-top: 2px solid;
  border-right: 2px solid;
  top: -3px;
  right: -1px;
  transform: rotate(68deg);
}

.gg-remote {
  transform: scale(var(--ggs, 1));
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 8px;
  height: 8px;
}
.gg-remote::after, .gg-remote::before {
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 8px;
  height: 8px;
}
.gg-remote::after {
  content: "";
  position: absolute;
  border-left: 2px solid;
  border-bottom: 2px solid;
  transform: rotate(45deg) scaleY(1);
}
.gg-remote::before {
  content: "";
  position: absolute;
  border-left: 2px solid;
  border-bottom: 2px solid;
  transform: rotate(45deg) scaleY(1);
  left: 5px;
  top: -2px;
}
.gg-remote::after {
  transform: rotate(-45deg) scaleX(-1);
  left: -5px;
  top: 2px;
}

.gg-remove-r {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 22px;
  border: 2px solid;
  border-radius: 4px;
}
.gg-remove-r::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 10px;
  height: 2px;
  background: currentColor;
  border-radius: 5px;
  top: 8px;
  left: 4px;
}

.gg-remove {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 22px;
  border: 2px solid;
  border-radius: 22px;
}
.gg-remove::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 10px;
  height: 2px;
  background: currentColor;
  border-radius: 5px;
  top: 8px;
  left: 4px;
}

.gg-rename {
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 20px;
  height: 16px;
  transform: scale(var(--ggs, 1));
  background: linear-gradient(to left, currentColor 22px, transparent 0) no-repeat 6px center/2px 22px;
}
.gg-rename::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 6px;
  height: 12px;
  border: 2px solid;
  top: 2px;
}
.gg-rename::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 6px;
  height: 12px;
  border: 2px solid;
  top: 2px;
  border-right: 0;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
.gg-rename::after {
  width: 10px;
  border-left: 0;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  right: 0;
}

.gg-reorder {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 14px;
  height: 2px;
  border-radius: 3px;
  background: currentColor;
}
.gg-reorder::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border-radius: 3px;
}
.gg-reorder::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border-radius: 3px;
  border: 2px solid;
  width: 6px;
  height: 6px;
  left: 12px;
  top: -2px;
}
.gg-reorder::after {
  top: -4px;
  left: 0;
  width: 10px;
  height: 2px;
  opacity: 0.5;
  background: currentColor;
  box-shadow: 0 8px 0 0, 0 12px 0 0, 0 16px 0 0;
}

.gg-repeat {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  box-shadow: -2px -2px 0 0, 2px 2px 0 0;
  width: 14px;
  height: 6px;
}
.gg-repeat::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 0;
  height: 0;
  border-top: 3px solid transparent;
  border-bottom: 3px solid transparent;
}
.gg-repeat::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 0;
  height: 0;
  border-top: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-left: 5px solid;
  top: -4px;
  right: 0;
}
.gg-repeat::after {
  border-right: 5px solid;
  bottom: -4px;
  left: 0;
}

.gg-ring {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 20px;
  height: 20px;
}
.gg-ring::after {
  content: "";
  display: block;
  position: absolute;
  border: 2px solid;
  box-sizing: border-box;
}
.gg-ring::before {
  content: "";
  display: block;
  position: absolute;
  border: 2px solid;
  box-sizing: border-box;
  border-top-right-radius: 100px;
  transform: rotate(-45deg);
  width: 8px;
  height: 8px;
  left: 6px;
  top: -1px;
}
.gg-ring::after {
  width: 18px;
  height: 18px;
  border-radius: 100px;
  border-top-color: transparent;
  left: 1px;
  top: 2px;
}

.gg-row-first {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 14px;
  height: 10px;
}
.gg-row-first::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border-radius: 3px;
  height: 2px;
  background: currentColor;
}
.gg-row-first::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border-radius: 3px;
  height: 2px;
  background: currentColor;
  width: 10px;
  top: 4px;
  opacity: 0.5;
  box-shadow: 0 4px 0;
}
.gg-row-first::after {
  width: 14px;
}

.gg-row-last {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 14px;
  height: 10px;
}
.gg-row-last::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border-radius: 3px;
  height: 2px;
  background: currentColor;
}
.gg-row-last::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border-radius: 3px;
  height: 2px;
  background: currentColor;
  width: 10px;
  opacity: 0.5;
  box-shadow: 0 4px 0;
}
.gg-row-last::after {
  width: 14px;
  bottom: 0;
}

.gg-ruler {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 24px;
  height: 13px;
  border: 2px solid;
  border-radius: 3px;
}
.gg-ruler::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border-radius: 3px;
  width: 2px;
  height: 5px;
  background: currentColor;
  left: 7px;
  top: -1px;
  box-shadow: 8px 0 0;
}
.gg-ruler::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border-radius: 3px;
  width: 2px;
  height: 5px;
  background: currentColor;
  left: 7px;
  top: -1px;
  box-shadow: 8px 0 0;
  height: 7px;
  left: 3px;
}

.gg-sand-clock {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 12px;
  height: 20px;
  border-top: 2px solid;
  border-bottom: 2px solid;
}
.gg-sand-clock::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
}
.gg-sand-clock::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
  bottom: -2px;
  width: 10px;
  height: 10px;
  border: 2px solid;
  left: 1px;
}
.gg-sand-clock::after {
  width: 6px;
  height: 6px;
  border: 2px solid transparent;
  border-bottom-left-radius: 14px;
  border-bottom-right-radius: 14px;
  top: 0;
  left: 3px;
  box-shadow: 0 0 0 2px, inset 2px 0 0;
}

.gg-scan {
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 18px;
  height: 18px;
  transform: scale(var(--ggs, 1));
  background: linear-gradient(to left, currentColor 22px, transparent 0) no-repeat center/2px 22px;
}
.gg-scan::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 6px;
  height: 14px;
  border: 2px solid;
  top: 2px;
}
.gg-scan::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 6px;
  height: 14px;
  border: 2px solid;
  top: 2px;
  border-right: 0;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
.gg-scan::after {
  border-left: 0;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  right: 0;
}

.gg-screen-mirror {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 18px;
  height: 10px;
  border: 2px solid;
  border-bottom: 0;
}
.gg-screen-mirror::after, .gg-screen-mirror::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
}
.gg-screen-mirror::after {
  border-bottom: 5px solid;
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  bottom: -2px;
  left: 3px;
}
.gg-screen-mirror::before {
  width: 18px;
  height: 2px;
  left: -2px;
  bottom: 0;
  border-left: 5px solid;
  border-right: 5px solid;
}

.gg-screen-shot {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 16px;
  height: 20px;
  border: 2px solid;
  border-radius: 1px;
}
.gg-screen-shot::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 4px;
  height: 4px;
}
.gg-screen-shot::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 4px;
  height: 4px;
  border-bottom: 2px solid;
  border-right: 2px solid;
  bottom: 2px;
  right: 2px;
}
.gg-screen-shot::after {
  border-top: 2px solid;
  border-left: 2px solid;
  top: 2px;
  left: 2px;
}

.gg-screen-wide {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 24px;
  height: 11px;
  border: 2px solid;
  border-radius: 3px;
  margin-top: -4px;
}
.gg-screen-wide::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border-radius: 3px;
  width: 8px;
  height: 2px;
  background: currentColor;
  top: 10px;
  left: 6px;
}
.gg-screen-wide::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border-radius: 3px;
  width: 8px;
  height: 2px;
  background: currentColor;
  top: 10px;
  left: 6px;
  width: 2px;
  height: 4px;
  top: 8px;
  left: 9px;
}

.gg-screen {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 14px;
  border: 2px solid;
  border-radius: 3px;
  margin-top: -4px;
}
.gg-screen::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border-radius: 3px;
  width: 10px;
  height: 2px;
  background: currentColor;
  top: 14px;
  left: 4px;
}
.gg-screen::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border-radius: 3px;
  width: 10px;
  height: 2px;
  background: currentColor;
  top: 14px;
  left: 4px;
  width: 2px;
  height: 6px;
  top: 10px;
  left: 8px;
}

.gg-scroll-h {
  display: block;
  box-sizing: border-box;
  width: 6px;
  height: 6px;
  position: relative;
  transform: scale(var(--ggs, 1));
  border: 2px solid;
  border-radius: 10px;
}
.gg-scroll-h::after, .gg-scroll-h::before {
  display: block;
  box-sizing: border-box;
  width: 6px;
  height: 6px;
}
.gg-scroll-h::after, .gg-scroll-h::before {
  content: "";
  position: absolute;
  transform: rotate(45deg);
  top: -2px;
}
.gg-scroll-h::after {
  border-top: 2px solid;
  border-right: 2px solid;
  right: -8px;
}
.gg-scroll-h::before {
  border-bottom: 2px solid;
  border-left: 2px solid;
  left: -8px;
}

.gg-scroll-v {
  display: block;
  box-sizing: border-box;
  width: 6px;
  height: 6px;
  position: relative;
  transform: scale(var(--ggs, 1));
  border: 2px solid;
  border-radius: 10px;
}
.gg-scroll-v::after, .gg-scroll-v::before {
  display: block;
  box-sizing: border-box;
  width: 6px;
  height: 6px;
}
.gg-scroll-v::after, .gg-scroll-v::before {
  content: "";
  position: absolute;
  transform: rotate(-45deg);
  left: -2px;
}
.gg-scroll-v::after {
  border-bottom: 2px solid;
  border-left: 2px solid;
  bottom: -8px;
}
.gg-scroll-v::before {
  border-top: 2px solid;
  border-right: 2px solid;
  top: -8px;
}

.gg-search-found {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 16px;
  height: 16px;
  border: 2px solid;
  border-radius: 100%;
  margin-left: -4px;
  margin-top: -4px;
}
.gg-search-found::after, .gg-search-found::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
}
.gg-search-found::after {
  border-radius: 3px;
  width: 2px;
  height: 8px;
  background: currentColor;
  transform: rotate(-45deg);
  top: 10px;
  left: 12px;
}
.gg-search-found::before {
  width: 6px;
  height: 4px;
  border-left: 2px solid;
  border-bottom: 2px solid;
  transform: rotate(-40deg);
  top: 3px;
  left: 3px;
}

.gg-search-loading {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 16px;
  height: 16px;
  border: 2px solid;
  border-radius: 100%;
  margin-left: -4px;
  margin-top: -4px;
}
.gg-search-loading::after, .gg-search-loading::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 2px;
  background: currentColor;
}
.gg-search-loading::after {
  border-radius: 3px;
  height: 8px;
  transform: rotate(-45deg);
  top: 10px;
  left: 12px;
}
.gg-search-loading::before {
  height: 2px;
  border-radius: 100%;
  top: 5px;
  left: 5px;
  box-shadow: -3px 0 0, 3px 0 0;
}

.gg-search {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 16px;
  height: 16px;
  border: 2px solid;
  border-radius: 100%;
  margin-left: -4px;
  margin-top: -4px;
}
.gg-search::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border-radius: 3px;
  width: 2px;
  height: 8px;
  background: currentColor;
  transform: rotate(-45deg);
  top: 10px;
  left: 12px;
}

.gg-select-o {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 22px;
  border: 2px solid;
  border-radius: 20px;
}
.gg-select-o::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 6px;
  height: 6px;
  left: 6px;
  transform: rotate(-45deg);
}
.gg-select-o::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 6px;
  height: 6px;
  left: 6px;
  transform: rotate(-45deg);
  border-left: 2px solid;
  border-bottom: 2px solid;
  bottom: 4px;
}
.gg-select-o::after {
  border-right: 2px solid;
  border-top: 2px solid;
  top: 4px;
}

.gg-select-r {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 22px;
  border: 2px solid;
  border-radius: 4px;
}
.gg-select-r::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 6px;
  height: 6px;
  left: 6px;
  transform: rotate(-45deg);
}
.gg-select-r::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 6px;
  height: 6px;
  left: 6px;
  transform: rotate(-45deg);
  border-left: 2px solid;
  border-bottom: 2px solid;
  bottom: 4px;
}
.gg-select-r::after {
  border-right: 2px solid;
  border-top: 2px solid;
  top: 4px;
}

.gg-select {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 22px;
}
.gg-select::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 8px;
  height: 8px;
  left: 7px;
  transform: rotate(-45deg);
}
.gg-select::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 8px;
  height: 8px;
  left: 7px;
  transform: rotate(-45deg);
  border-left: 2px solid;
  border-bottom: 2px solid;
  bottom: 4px;
}
.gg-select::after {
  border-right: 2px solid;
  border-top: 2px solid;
  top: 4px;
}

.gg-server {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 16px;
  height: 20px;
  border: 2px solid;
  border-radius: 3px;
}
.gg-server::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  height: 2px;
  background: currentColor;
}
.gg-server::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  height: 2px;
  background: currentColor;
  top: 2px;
  box-shadow: 0 4px 0;
  border-radius: 3px;
  width: 8px;
  left: 2px;
}
.gg-server::after {
  width: 2px;
  border-radius: 10px;
  left: 5px;
  bottom: 2px;
}

.gg-serverless {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 15px;
  height: 13px;
  overflow: hidden;
}
.gg-serverless::after {
  background: currentColor;
  content: "";
  box-sizing: border-box;
  position: absolute;
  display: block;
  height: 3px;
  box-shadow: 0 5px 0, 0 10px 0;
  transform: skew(-20deg);
}
.gg-serverless::before {
  background: currentColor;
  content: "";
  box-sizing: border-box;
  position: absolute;
  display: block;
  height: 3px;
  box-shadow: 0 5px 0, 0 10px 0;
  transform: skew(-20deg);
  width: 8px;
  left: -2px;
}
.gg-serverless::after {
  width: 12px;
  right: -5px;
}

.gg-shape-circle {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 16px;
  height: 16px;
  border: 3px solid;
  border-radius: 100px;
}

.gg-shape-half-circle {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 8px;
  height: 16px;
  border: 3px solid;
  border-top-left-radius: 100px;
  border-bottom-left-radius: 100px;
  border-right: 0;
}

.gg-shape-hexagon {
  display: block;
  box-sizing: border-box;
  width: 18px;
  height: 10px;
  border-left: 3px solid currentColor;
  border-right: 3px solid currentColor;
  position: relative;
  transform: scale(var(--ggs, 1));
}
.gg-shape-hexagon::after, .gg-shape-hexagon::before {
  display: block;
  box-sizing: border-box;
  width: 18px;
  height: 10px;
  border-left: 3px solid currentColor;
  border-right: 3px solid currentColor;
}
.gg-shape-hexagon::after {
  content: "";
  position: absolute;
  left: -3px;
}
.gg-shape-hexagon::before {
  content: "";
  position: absolute;
  left: -3px;
  transform: rotate(60deg);
}
.gg-shape-hexagon::after {
  transform: rotate(-60deg);
}

.gg-shape-rhombus {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: rotate(45deg) scale(var(--ggs, 1));
  width: 14px;
  height: 14px;
  border: 3px solid;
}

.gg-shape-square {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 16px;
  height: 16px;
  border: 3px solid;
}

.gg-shape-triangle {
  position: relative;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 17px;
  border-left: 3px solid transparent;
  border-bottom: 3px solid;
  display: block;
  box-sizing: border-box;
  border-right: 3px solid transparent;
}
.gg-shape-triangle::before {
  display: block;
  box-sizing: border-box;
  border-right: 3px solid transparent;
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  border-left: 3px solid;
  border-top: 3px solid;
  border-bottom: 3px solid transparent;
  transform: rotate(45deg) skew(10deg, 10deg);
  left: -2px;
  bottom: -13px;
}

.gg-shape-zigzag {
  display: block;
  box-sizing: border-box;
  width: 5px;
  height: 7px;
  border-left: 2px solid;
  border-bottom: 2px solid;
  margin-left: -18px;
  transform: rotate(-49deg) scale(var(--ggs, 1));
  position: relative;
}
.gg-shape-zigzag::after, .gg-shape-zigzag::before {
  display: block;
  box-sizing: border-box;
  width: 5px;
  height: 7px;
  border-left: 2px solid;
  border-bottom: 2px solid;
}
.gg-shape-zigzag::after {
  content: "";
  position: absolute;
  left: 2px;
  top: 5px;
}
.gg-shape-zigzag::before {
  content: "";
  position: absolute;
  left: 2px;
  top: 5px;
  left: 7px;
  top: 10px;
}

.gg-share {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 6px;
  height: 6px;
  background: currentColor;
  border-radius: 100px;
  box-shadow: 10px -6px 0, 10px 6px 0;
}
.gg-share::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border-radius: 3px;
  width: 10px;
  height: 2px;
  background: currentColor;
  left: 2px;
}
.gg-share::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border-radius: 3px;
  width: 10px;
  height: 2px;
  background: currentColor;
  left: 2px;
  top: 0;
  transform: rotate(-35deg);
}
.gg-share::after {
  bottom: 0;
  transform: rotate(35deg);
}

.gg-shield {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 14px;
  height: 4px;
  background: currentColor;
  border-radius: 100px;
  margin-top: -6px;
}
.gg-shield::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border-radius: 3px;
  width: 8px;
  height: 16px;
  border: 2px solid;
}
.gg-shield::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border-radius: 3px;
  width: 8px;
  height: 16px;
  border: 2px solid;
  border-bottom-left-radius: 40px;
  border-right: 0;
  left: 0;
}
.gg-shield::after {
  border-bottom-right-radius: 40px;
  border-left: 0;
  right: 0;
}

.gg-shopping-bag {
  transform: scale(var(--ggs, 1));
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 20px;
  height: 20px;
  border: 2px solid;
  border-radius: 3px;
}
.gg-shopping-bag::after {
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 20px;
  height: 20px;
  border: 2px solid;
  border-radius: 3px;
  content: "";
  position: absolute;
  width: 10px;
  height: 6px;
  border-bottom-left-radius: 120px;
  border-bottom-right-radius: 120px;
  border-top: 0;
  top: 2px;
  left: 3px;
}

.gg-shopping-cart {
  display: block;
  box-sizing: border-box;
  position: relative;
  transform: scale(var(--ggs, 1));
  width: 20px;
  height: 21px;
  background: linear-gradient(to left, currentColor 12px, transparent 0) no-repeat -1px 6px/18px 2px, linear-gradient(to left, currentColor 12px, transparent 0) no-repeat 6px 14px/11px 2px, linear-gradient(to left, currentColor 12px, transparent 0) no-repeat 0 2px/4px 2px, radial-gradient(circle, currentColor 60%, transparent 40%) no-repeat 12px 17px/4px 4px, radial-gradient(circle, currentColor 60%, transparent 40%) no-repeat 6px 17px/4px 4px;
}
.gg-shopping-cart::after, .gg-shopping-cart::before {
  content: "";
  display: block;
  position: absolute;
  box-sizing: border-box;
  width: 2px;
  height: 14px;
  background: currentColor;
  top: 2px;
  left: 4px;
  transform: skew(12deg);
}
.gg-shopping-cart::after {
  height: 10px;
  top: 6px;
  left: 16px;
  transform: skew(-12deg);
}

.gg-shortcut {
  display: block;
  box-sizing: border-box;
  border-radius: 3px;
  border: 2px solid;
  position: relative;
  transform: scale(var(--ggs, 1));
  width: 18px;
  height: 18px;
}
.gg-shortcut::after {
  display: block;
  box-sizing: border-box;
  border-radius: 3px;
  content: "";
  position: absolute;
  width: 2px;
  height: 12px;
  background: currentColor;
  left: 6px;
  top: 1px;
  transform: rotate(45deg);
}

.gg-shutterstock {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 20px;
  height: 20px;
  border: 2px solid;
  border-radius: 3px;
}
.gg-shutterstock::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 6px;
  height: 6px;
  border-radius: 1px;
}
.gg-shutterstock::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 6px;
  height: 6px;
  border-radius: 1px;
  border-bottom: 2px solid;
  border-right: 2px solid;
  bottom: 2px;
  right: 2px;
}
.gg-shutterstock::after {
  border-top: 2px solid;
  border-left: 2px solid;
  top: 2px;
  left: 2px;
}

.gg-sidebar-open {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 18px;
  height: 16px;
  border: 2px solid;
}
.gg-sidebar-open::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 4px;
  height: 12px;
  background: currentColor;
  top: 0;
  left: 0;
}

.gg-sidebar-right {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 18px;
  height: 16px;
  border-right: 2px solid;
}
.gg-sidebar-right::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 14px;
  height: 16px;
  border: 2px solid;
  top: 0;
  right: 2px;
}

.gg-sidebar {
  display: block;
  box-sizing: border-box;
  height: 16px;
  border-left: 2px solid;
  position: relative;
  transform: scale(var(--ggs, 1));
  width: 18px;
}
.gg-sidebar::before {
  display: block;
  box-sizing: border-box;
  height: 16px;
  content: "";
  position: absolute;
  width: 14px;
  border: 2px solid;
  top: 0;
  left: 2px;
}

.gg-signal {
  display: block;
  box-sizing: border-box;
  width: 2px;
  border-radius: 4px;
  background: currentColor;
  position: relative;
  transform: scale(var(--ggs, 1));
  height: 8px;
}
.gg-signal::after, .gg-signal::before {
  display: block;
  box-sizing: border-box;
  width: 2px;
  border-radius: 4px;
  background: currentColor;
}
.gg-signal::after {
  content: "";
  position: absolute;
}
.gg-signal::before {
  content: "";
  position: absolute;
  height: 12px;
  left: 4px;
  bottom: 0;
}
.gg-signal::after {
  height: 4px;
  left: -4px;
  bottom: 0;
}

.gg-size {
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 16px;
  height: 16px;
  transform: scale(var(--ggs, 1));
  border-left: 8px solid transparent;
  box-shadow: inset 0 0 0 2px;
}
.gg-size::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 6px;
  height: 12px;
  border: 2px solid;
  border-right: 0;
  right: 6px;
  top: 2px;
}
.gg-size::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 6px;
  height: 12px;
  border: 2px solid;
  border-right: 0;
  right: 6px;
  top: 2px;
  width: 4px;
  height: 8px;
  right: 12px;
  top: 4px;
}

.gg-sketch {
  display: block;
  position: relative;
  box-sizing: border-box;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 18px;
  background: linear-gradient(to left, currentColor 16px, transparent 0) no-repeat center 6px/6px 2px;
}
.gg-sketch::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 14px;
  border: 2px solid;
  left: 4px;
}
.gg-sketch::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 14px;
  border: 2px solid;
  left: 4px;
  height: 14px;
  border-top-color: transparent;
  border-right-color: transparent;
  transform: rotate(-45deg) skew(-8deg, -8deg);
  bottom: 4px;
}
.gg-sketch::after {
  height: 7px;
  border-bottom: 0;
  transform: perspective(12px) rotateX(35deg);
}

.gg-slack {
  position: relative;
  box-sizing: border-box;
  transform: scale(var(--ggs, 1));
  display: block;
  width: 20px;
  height: 20px;
  background: linear-gradient(to left, currentColor 5px, transparent 0) no-repeat 7px 2px/2px 2px, linear-gradient(to left, currentColor 5px, transparent 0) no-repeat 15px 7px/2px 2px, linear-gradient(to left, currentColor 5px, transparent 0) no-repeat 2px 10px/2px 2px, linear-gradient(to left, currentColor 5px, transparent 0) no-repeat 10px 15px/2px 2px, linear-gradient(to left, currentColor 5px, transparent 0) no-repeat 10px 2px/4px 5px, linear-gradient(to left, currentColor 5px, transparent 0) no-repeat 5px 12px/4px 5px;
}
.gg-slack::after {
  background: currentColor;
  content: "";
  position: absolute;
  box-sizing: border-box;
  display: block;
  height: 4px;
  border-radius: 22px;
}
.gg-slack::before {
  background: currentColor;
  content: "";
  position: absolute;
  box-sizing: border-box;
  display: block;
  height: 4px;
  border-radius: 22px;
  width: 9px;
  top: 5px;
  box-shadow: 10px 5px 0;
}
.gg-slack::after {
  width: 4px;
  left: 5px;
  box-shadow: -5px 10px 0, 0 10px 0, 0 15px 0, 5px 15px 0, 5px 5px 0, 5px 0 0, 10px 5px 0;
}

.gg-sleep {
  display: block;
  box-sizing: border-box;
  transform: scale(var(--ggs, 1));
  position: relative;
  width: 18px;
  height: 18px;
  border: 2px solid;
  border-radius: 22px;
}
.gg-sleep::after {
  display: block;
  box-sizing: border-box;
  content: "";
  position: absolute;
  width: 14px;
  height: 2px;
  background: currentColor;
  bottom: 2px;
}

.gg-smart-home-boiler {
  display: block;
  position: relative;
  box-sizing: border-box;
  transform: scale(var(--ggs, 1));
  width: 14px;
  height: 20px;
  border: 2px solid;
  border-radius: 2px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background: linear-gradient(to left, currentColor 14px, transparent 0) no-repeat center 4px/14px 2px;
}
.gg-smart-home-boiler::before {
  content: "";
  position: absolute;
  display: block;
  box-sizing: border-box;
  width: 6px;
  height: 3px;
  border-left: 2px solid;
  border-right: 2px solid;
  transform: skewX(20deg);
  bottom: -4px;
  left: 2px;
}

.gg-smart-home-cooker {
  display: block;
  position: relative;
  box-sizing: border-box;
  transform: scale(var(--ggs, 1));
  width: 18px;
  height: 18px;
  border: 2px solid;
  border-radius: 4px;
  background: linear-gradient(to left, currentColor 18px, transparent 0) no-repeat center 2px/18px 2px;
}
.gg-smart-home-cooker::after {
  content: "";
  position: absolute;
  display: block;
  box-sizing: border-box;
  width: 6px;
  left: 4px;
}
.gg-smart-home-cooker::before {
  content: "";
  position: absolute;
  display: block;
  box-sizing: border-box;
  width: 6px;
  left: 4px;
  height: 6px;
  border: 2px solid;
  border-radius: 4px;
  bottom: 2px;
}
.gg-smart-home-cooker::after {
  height: 3px;
  box-shadow: 0 -2px 0, inset 0 0 0 2px;
  border-left: 2px solid transparent;
  border-right: 2px solid transparent;
  top: -4px;
}

.gg-smart-home-heat {
  display: block;
  position: relative;
  box-sizing: border-box;
  transform: scale(var(--ggs, 1));
  width: 24px;
  height: 14px;
  border: 2px solid;
  border-radius: 22px;
  background: linear-gradient(to left, currentColor 24px, transparent 0) no-repeat center 2px/10px 2px;
}
.gg-smart-home-heat::after {
  content: "";
  position: absolute;
  display: block;
  box-sizing: border-box;
  width: 6px;
  height: 6px;
  border: 2px solid;
  top: 2px;
}
.gg-smart-home-heat::before {
  content: "";
  position: absolute;
  display: block;
  box-sizing: border-box;
  width: 6px;
  height: 6px;
  border: 2px solid;
  top: 2px;
  border-right: 0;
  border-top-left-radius: 22px;
  border-bottom-left-radius: 22px;
  left: 2px;
}
.gg-smart-home-heat::after {
  border-left: 0;
  border-top-right-radius: 22px;
  border-bottom-right-radius: 22px;
  right: 2px;
}

.gg-smart-home-light {
  display: block;
  position: relative;
  box-sizing: border-box;
  transform: scale(var(--ggs, 1));
  width: 10px;
  height: 14px;
  border: 2px solid;
  border-radius: 14px;
  box-shadow: 0 11px 0 -4px;
}
.gg-smart-home-light::after {
  content: "";
  position: absolute;
  display: block;
  box-sizing: border-box;
  background: currentColor;
  width: 2px;
  height: 6px;
  border-radius: 6px;
  bottom: -8px;
}
.gg-smart-home-light::before {
  content: "";
  position: absolute;
  display: block;
  box-sizing: border-box;
  background: currentColor;
  width: 2px;
  height: 6px;
  border-radius: 6px;
  bottom: -8px;
  transform: rotate(20deg);
  left: -2px;
}
.gg-smart-home-light::after {
  transform: rotate(-20deg);
  right: -2px;
}

.gg-smart-home-refrigerator {
  display: block;
  position: relative;
  box-sizing: border-box;
  transform: scale(var(--ggs, 1));
  width: 14px;
  height: 22px;
  border: 2px solid;
  border-radius: 3px;
}
.gg-smart-home-refrigerator::after {
  content: "";
  position: absolute;
  display: block;
  box-sizing: border-box;
  background: currentColor;
}
.gg-smart-home-refrigerator::before {
  content: "";
  position: absolute;
  display: block;
  box-sizing: border-box;
  background: currentColor;
  width: 2px;
  height: 4px;
  border-radius: 2px;
  top: 2px;
  left: 2px;
  box-shadow: 0 8px 0;
}
.gg-smart-home-refrigerator::after {
  width: 14px;
  height: 2px;
  top: 7px;
  left: -2px;
}

.gg-smart-home-wash-machine {
  display: block;
  position: relative;
  box-sizing: border-box;
  transform: scale(var(--ggs, 1));
  width: 18px;
  height: 20px;
  border: 2px solid;
  background: linear-gradient(to left, currentColor 18px, transparent 0) no-repeat center 4px/18px 2px;
  border-radius: 3px;
}
.gg-smart-home-wash-machine::after {
  content: "";
  position: absolute;
  display: block;
  box-sizing: border-box;
  border-radius: 22px;
}
.gg-smart-home-wash-machine::before {
  content: "";
  position: absolute;
  display: block;
  box-sizing: border-box;
  border-radius: 22px;
  bottom: 1px;
  left: 3px;
  width: 8px;
  height: 8px;
  border: 2px solid;
  box-shadow: 5px -9px 0 -3px, 2px -9px 0 -3px;
}
.gg-smart-home-wash-machine::after {
  top: 1px;
  left: 1px;
  width: 4px;
  height: 2px;
  background: currentColor;
}

.gg-smartphone-chip {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 16px;
  height: 16px;
  border: 6px double;
  border-radius: 3px;
}
.gg-smartphone-chip::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border-radius: 3px;
  width: 2px;
  height: 2px;
  left: -3px;
  top: -9px;
  background: currentColor;
  box-shadow: 4px 0 0, 8px 0 0, 0 20px 0, 4px 20px 0, -6px 6px 0, 14px 6px 0, 14px 10px 0, 14px 14px 0, -6px 10px 0, -6px 14px 0, 8px 20px 0;
}

.gg-smartphone-ram {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 26px;
  height: 14px;
  border: 2px solid;
  border-radius: 3px;
}
.gg-smartphone-ram::after, .gg-smartphone-ram::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border-radius: 3px;
  width: 2px;
  height: 2px;
  background: currentColor;
  box-shadow: 16px 4px 0;
  top: 2px;
  left: 2px;
}
.gg-smartphone-ram::after {
  box-shadow: 4px 0 0, 8px 0 0, 12px 0 0, 16px 0 0, 0 18px 0, 4px 18px 0, 8px 18px 0, 12px 18px 0, 16px 18px 0;
  top: -5px;
}

.gg-smartphone-shake {
  background: linear-gradient(to left, currentColor 5px, transparent 0) no-repeat 4px 12px/2px 2px;
  box-sizing: border-box;
  position: relative;
  transform: scale(var(--ggs, 1));
  display: block;
  width: 14px;
  height: 20px;
  border: 2px solid;
  border-radius: 2px;
}
.gg-smartphone-shake::after, .gg-smartphone-shake::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  height: 10px;
  width: 6px;
  border: 2px solid transparent;
  overflow: hidden;
  box-shadow: 2px 0 0, inset -2px 0 0;
  top: 3px;
  left: -12px;
}
.gg-smartphone-shake::after {
  transform: scaleX(-1);
  left: 16px;
}

.gg-smartphone {
  background: linear-gradient(to left, currentColor 5px, transparent 0) no-repeat 4px 12px/2px 2px;
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 14px;
  height: 20px;
  border: 2px solid;
  border-radius: 2px;
}

.gg-smile-mouth-open {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 20px;
  height: 20px;
  border: 2px solid;
  border-radius: 100px;
}
.gg-smile-mouth-open::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  left: 4px;
}
.gg-smile-mouth-open::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  left: 4px;
  background: currentColor;
  box-shadow: 6px 0 0;
  width: 2px;
  height: 2px;
  border-radius: 50%;
  top: 5px;
}
.gg-smile-mouth-open::after {
  width: 8px;
  height: 4px;
  border-radius: 150px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border: 2px solid;
  top: 9px;
}

.gg-smile-neutral {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 20px;
  height: 20px;
  border: 2px solid;
  border-radius: 100px;
}
.gg-smile-neutral::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  height: 2px;
  background: currentColor;
  left: 4px;
}
.gg-smile-neutral::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  height: 2px;
  background: currentColor;
  left: 4px;
  box-shadow: 6px 0 0;
  width: 2px;
  border-radius: 10px;
  top: 5px;
}
.gg-smile-neutral::after {
  border-radius: 3px;
  width: 8px;
  top: 10px;
}

.gg-smile-no-mouth {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 20px;
  height: 20px;
  border: 2px solid;
  border-radius: 100px;
}
.gg-smile-no-mouth::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  height: 2px;
  background: currentColor;
  left: 4px;
  box-shadow: 6px 0 0;
  width: 2px;
  border-radius: 10px;
  top: 5px;
}

.gg-smile-none {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 20px;
  height: 20px;
  border: 2px solid;
  border-radius: 100px;
}
.gg-smile-none::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  height: 2px;
  background: currentColor;
  left: 4px;
}
.gg-smile-none::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  height: 2px;
  background: currentColor;
  left: 4px;
  box-shadow: 5px 0 0;
  width: 3px;
  border-radius: 10px;
  top: 5px;
}
.gg-smile-none::after {
  border-radius: 3px;
  width: 8px;
  top: 10px;
}

.gg-smile-sad {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 20px;
  height: 20px;
  border: 2px solid;
  border-radius: 100px;
}
.gg-smile-sad::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  left: 4px;
}
.gg-smile-sad::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  left: 4px;
  background: currentColor;
  box-shadow: 6px 0 0;
  width: 2px;
  height: 2px;
  border-radius: 50%;
  top: 5px;
}
.gg-smile-sad::after {
  width: 8px;
  height: 4px;
  border-radius: 150px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border: 2px solid;
  border-top-color: transparent;
  top: 9px;
  transform: scaleY(-1);
}

.gg-smile-upside {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 20px;
  height: 20px;
  border: 2px solid;
  border-radius: 100px;
}
.gg-smile-upside::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  left: 4px;
}
.gg-smile-upside::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  left: 4px;
  background: currentColor;
  box-shadow: 6px 0 0;
  width: 2px;
  height: 2px;
  border-radius: 50%;
  bottom: 5px;
}
.gg-smile-upside::after {
  width: 8px;
  height: 4px;
  border-radius: 150px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border: 2px solid;
  border-bottom-color: transparent;
  bottom: 9px;
}

.gg-smile {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 20px;
  height: 20px;
  border: 2px solid;
  border-radius: 100px;
}
.gg-smile::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  left: 4px;
}
.gg-smile::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  left: 4px;
  background: currentColor;
  box-shadow: 6px 0 0;
  width: 2px;
  height: 2px;
  border-radius: 50%;
  top: 5px;
}
.gg-smile::after {
  width: 8px;
  height: 4px;
  border-radius: 150px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border: 2px solid;
  border-top-color: transparent;
  top: 9px;
}

.gg-software-download {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 16px;
  height: 6px;
  border: 2px solid;
  border-top: 0;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  margin-top: 8px;
}
.gg-software-download::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 8px;
  height: 8px;
  border-left: 2px solid;
  border-bottom: 2px solid;
  transform: rotate(-45deg);
  left: 2px;
  bottom: 4px;
}
.gg-software-download::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border-radius: 3px;
  width: 2px;
  height: 10px;
  background: currentColor;
  left: 5px;
  bottom: 5px;
}

.gg-software-upload {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 16px;
  height: 6px;
  border: 2px solid;
  border-top: 0;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  margin-top: 8px;
}
.gg-software-upload::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 8px;
  height: 8px;
  border-left: 2px solid;
  border-top: 2px solid;
  transform: rotate(45deg);
  left: 2px;
  bottom: 4px;
}
.gg-software-upload::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border-radius: 3px;
  width: 2px;
  height: 10px;
  background: currentColor;
  left: 5px;
  bottom: 3px;
}

.gg-sort-az {
  display: block;
  box-sizing: border-box;
  height: 2px;
  border-radius: 4px;
  background: currentColor;
  position: relative;
  transform: scale(var(--ggs, 1));
  width: 8px;
}
.gg-sort-az::after, .gg-sort-az::before {
  display: block;
  box-sizing: border-box;
  height: 2px;
  border-radius: 4px;
  background: currentColor;
}
.gg-sort-az::after {
  content: "";
  position: absolute;
}
.gg-sort-az::before {
  content: "";
  position: absolute;
  width: 12px;
  top: -4px;
  left: -2px;
}
.gg-sort-az::after {
  width: 4px;
  top: 4px;
  left: 2px;
}

.gg-sort-za {
  display: block;
  box-sizing: border-box;
  height: 2px;
  border-radius: 4px;
  background: currentColor;
  position: relative;
  transform: scale(var(--ggs, 1));
  width: 8px;
}
.gg-sort-za::after, .gg-sort-za::before {
  display: block;
  box-sizing: border-box;
  height: 2px;
  border-radius: 4px;
  background: currentColor;
}
.gg-sort-za::after {
  content: "";
  position: absolute;
}
.gg-sort-za::before {
  content: "";
  position: absolute;
  width: 12px;
  top: 4px;
  left: -2px;
}
.gg-sort-za::after {
  width: 4px;
  top: -4px;
  left: 2px;
}

.gg-space-between-v {
  display: block;
  box-sizing: border-box;
  transform: scale(var(--ggs, 1));
  width: 8px;
  height: 14px;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  box-shadow: inset 0 0 0 2px;
}
.gg-space-between-v::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 12px;
  height: 4px;
  border: 2px solid;
  left: -2px;
}
.gg-space-between-v::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 12px;
  height: 4px;
  border: 2px solid;
  left: -2px;
  top: -6px;
  border-top: 0;
}
.gg-space-between-v::after {
  bottom: -6px;
  border-bottom: 0;
}

.gg-space-between {
  display: block;
  box-sizing: border-box;
  transform: scale(var(--ggs, 1));
  width: 14px;
  height: 8px;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  box-shadow: inset 0 0 0 2px;
}
.gg-space-between::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  height: 12px;
  width: 4px;
  border: 2px solid;
  top: -2px;
}
.gg-space-between::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  height: 12px;
  width: 4px;
  border: 2px solid;
  top: -2px;
  left: -6px;
  border-left: 0;
}
.gg-space-between::after {
  right: -6px;
  border-right: 0;
}

.gg-spectrum {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 16px;
  height: 16px;
  overflow: hidden;
}
.gg-spectrum::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 30px;
  height: 30px;
  left: -15px;
  bottom: -15px;
  border: 8px solid;
  border-radius: 18px;
}

@keyframes spinneralt {
  0% {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
.gg-spinner-alt {
  transform: scale(var(--ggs, 1));
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 20px;
  height: 20px;
}
.gg-spinner-alt::before {
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 20px;
  height: 20px;
  content: "";
  position: absolute;
  border-radius: 100px;
  animation: spinneralt 1s cubic-bezier(0.6, 0, 0.4, 1) infinite;
  border: 3px solid transparent;
  border-top-color: currentColor;
}

@keyframes spinner-two-alt {
  0% {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
.gg-spinner-two-alt {
  box-sizing: border-box;
  display: block;
  width: 20px;
  height: 20px;
  transform: scale(var(--ggs, 1));
  position: relative;
}
.gg-spinner-two-alt::before {
  box-sizing: border-box;
  display: block;
  width: 20px;
  height: 20px;
  content: "";
  position: absolute;
  border-radius: 100px;
  animation: spinner-two-alt 1s cubic-bezier(0.6, 0, 0.4, 1) infinite;
  border: 3px solid transparent;
  border-bottom-color: currentColor;
  border-top-color: currentColor;
}

@keyframes spinner-two {
  0% {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
.gg-spinner-two {
  transform: scale(var(--ggs, 1));
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 20px;
  height: 20px;
}
.gg-spinner-two::after {
  box-sizing: border-box;
  display: block;
  width: 20px;
  height: 20px;
  content: "";
  position: absolute;
  border-radius: 100px;
}
.gg-spinner-two::before {
  box-sizing: border-box;
  display: block;
  width: 20px;
  height: 20px;
  content: "";
  position: absolute;
  border-radius: 100px;
  animation: spinner-two 1s cubic-bezier(0.6, 0, 0.4, 1) infinite;
  border: 3px solid transparent;
  border-bottom-color: currentColor;
  border-top-color: currentColor;
}
.gg-spinner-two::after {
  border: 3px solid;
  opacity: 0.2;
}

.gg-spinner {
  transform: scale(var(--ggs, 1));
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 20px;
  height: 20px;
}
.gg-spinner::after, .gg-spinner::before {
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 20px;
  height: 20px;
}
.gg-spinner::after {
  content: "";
  position: absolute;
  border-radius: 100px;
}
.gg-spinner::before {
  content: "";
  position: absolute;
  border-radius: 100px;
  animation: spinner 1s cubic-bezier(0.6, 0, 0.4, 1) infinite;
  border: 3px solid transparent;
  border-top-color: currentColor;
}
.gg-spinner::after {
  border: 3px solid;
  opacity: 0.2;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
.gg-square {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 12px;
  height: 12px;
  border-radius: 1px;
  border: 3px solid transparent;
  box-shadow: 0 0 0 3px, inset 0 0 0 3px;
}

.gg-stack {
  display: block;
  box-sizing: border-box;
  width: 14px;
  height: 14px;
  border: 2px solid;
  margin-right: 8px;
  margin-top: 8px;
  transform: scale(var(--ggs, 1));
  position: relative;
}
.gg-stack::after, .gg-stack::before {
  display: block;
  box-sizing: border-box;
  width: 14px;
  height: 14px;
  border: 2px solid;
}
.gg-stack::after {
  content: "";
  position: absolute;
  border-left: 0;
  border-bottom: 0;
  right: -5px;
  top: -5px;
}
.gg-stack::before {
  content: "";
  position: absolute;
  border-left: 0;
  border-bottom: 0;
  right: -5px;
  top: -5px;
  right: -8px;
  top: -8px;
}

.gg-stark {
  background: currentColor;
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: rotate(45deg) scale(var(--ggs, 1));
  width: 3px;
  height: 16px;
}
.gg-stark::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 8px;
  height: 16px;
  border: 3px solid;
}
.gg-stark::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 8px;
  height: 16px;
  border: 3px solid;
  border-top-left-radius: 100px;
  border-bottom-left-radius: 100px;
  border-right: 0;
  border-top-color: transparent;
  left: -7px;
  bottom: 0;
}
.gg-stark::after {
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
  border-left: 0;
  border-bottom-color: transparent;
  right: -7px;
  top: 0;
}

.gg-stopwatch {
  background: linear-gradient(to left, currentColor 7px, transparent 0) no-repeat 6px 2px/2px 6px;
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 18px;
  height: 18px;
  border-radius: 100%;
  border: 2px solid transparent;
  box-shadow: 0 0 0 2px;
}
.gg-stopwatch::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 4px;
  height: 2px;
  background: currentColor;
  transform: rotate(45deg);
  right: -4px;
  top: -3px;
}

.gg-stories {
  display: block;
  box-sizing: border-box;
  border-radius: 3px;
  position: relative;
  transform: scale(var(--ggs, 1));
  width: 12px;
  height: 16px;
  border: 2px solid;
}
.gg-stories::after, .gg-stories::before {
  display: block;
  box-sizing: border-box;
  border-radius: 3px;
}
.gg-stories::after {
  content: "";
  position: absolute;
  background: currentColor;
  width: 2px;
  height: 14px;
  top: -1px;
}
.gg-stories::before {
  content: "";
  position: absolute;
  background: currentColor;
  width: 2px;
  height: 14px;
  top: -1px;
  left: -6px;
}
.gg-stories::after {
  right: -6px;
}

.gg-studio {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 18px;
  height: 18px;
  border: 2px solid;
}
.gg-studio::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 4px;
  height: 4px;
  background: currentColor;
  right: 2px;
  bottom: 2px;
}

.gg-style {
  display: block;
  box-sizing: border-box;
  width: 8px;
  height: 8px;
  position: relative;
  transform: scale(var(--ggs, 1));
  box-shadow: -3px 3px 0 -1px, 3px -3px 0 -1px;
}
.gg-style::after, .gg-style::before {
  display: block;
  box-sizing: border-box;
  width: 8px;
  height: 8px;
}
.gg-style::after {
  content: "";
  position: absolute;
  border: 2px solid;
}
.gg-style::before {
  content: "";
  position: absolute;
  border: 2px solid;
  top: -5px;
  left: -5px;
}
.gg-style::after {
  bottom: -5px;
  right: -5px;
}

.gg-sun {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 24px;
  height: 24px;
  background: linear-gradient(to bottom, currentColor 4px, transparent 0) no-repeat 5px -6px/2px 6px, linear-gradient(to bottom, currentColor 4px, transparent 0) no-repeat 5px 14px/2px 6px, linear-gradient(to bottom, currentColor 4px, transparent 0) no-repeat -8px 5px/6px 2px, linear-gradient(to bottom, currentColor 4px, transparent 0) no-repeat 14px 5px/6px 2px;
  border-radius: 100px;
  box-shadow: inset 0 0 0 2px;
  border: 6px solid transparent;
}
.gg-sun::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 24px;
  height: 2px;
  border-right: 4px solid;
  border-left: 4px solid;
  left: -6px;
  top: 5px;
}
.gg-sun::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 24px;
  height: 2px;
  border-right: 4px solid;
  border-left: 4px solid;
  left: -6px;
  top: 5px;
  transform: rotate(-45deg);
}
.gg-sun::after {
  transform: rotate(45deg);
}

.gg-support {
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 20px;
  height: 20px;
  transform: rotate(45deg) scale(var(--ggs, 1));
  border: 5px solid transparent;
  box-shadow: 0 0 0 2px, inset 0 0 0 2px;
  border-radius: 40px;
}
.gg-support::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
}
.gg-support::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border-left: 9px solid;
  border-right: 9px solid;
  width: 24px;
  height: 2px;
  left: -7px;
  top: 4px;
}
.gg-support::after {
  width: 2px;
  height: 24px;
  border-top: 9px solid;
  border-bottom: 9px solid;
  left: 4px;
  top: -7px;
}

.gg-swap-vertical {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: rotate(90deg) scale(var(--ggs, 1));
  box-shadow: -2px 2px 0, 2px -2px 0;
  width: 6px;
  height: 2px;
}
.gg-swap-vertical::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 0;
  height: 0;
  border-top: 3px solid transparent;
  border-bottom: 3px solid transparent;
}
.gg-swap-vertical::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 0;
  height: 0;
  border-top: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-left: 3px solid;
  top: -4px;
  right: -4px;
}
.gg-swap-vertical::after {
  border-right: 3px solid;
  bottom: -4px;
  left: -4px;
}

.gg-swap {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  box-shadow: -2px 2px 0, 2px -2px 0;
  width: 6px;
  height: 2px;
}
.gg-swap::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 0;
  height: 0;
  border-top: 3px solid transparent;
  border-bottom: 3px solid transparent;
}
.gg-swap::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 0;
  height: 0;
  border-top: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-left: 3px solid;
  top: -4px;
  right: -4px;
}
.gg-swap::after {
  border-right: 3px solid;
  bottom: -4px;
  left: -4px;
}

.gg-sweden {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 21px;
  height: 16px;
}
.gg-sweden::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  height: 6px;
  background: currentColor;
  box-shadow: 0 8px 0;
  top: 0;
}
.gg-sweden::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  height: 6px;
  background: currentColor;
  box-shadow: 0 8px 0;
  top: 0;
  left: 0;
  width: 7px;
}
.gg-sweden::after {
  width: 12px;
  right: 0;
}

.gg-swiss {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 18px;
  height: 18px;
  border: 4px solid;
}
.gg-swiss::after, .gg-swiss::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 5px;
  height: 5px;
  background: currentColor;
  box-shadow: 0 9px 0;
  top: -2px;
}
.gg-swiss::after {
  left: -2px;
}
.gg-swiss::before {
  right: -2px;
}

.gg-sync {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  border-radius: 40px;
  border: 2px solid;
  margin: 1px;
  border-left-color: transparent;
  border-right-color: transparent;
  width: 18px;
  height: 18px;
}
.gg-sync::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 0;
  height: 0;
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  transform: rotate(-45deg);
}
.gg-sync::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 0;
  height: 0;
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  transform: rotate(-45deg);
  border-left: 6px solid;
  bottom: -1px;
  right: -3px;
}
.gg-sync::after {
  border-right: 6px solid;
  top: -1px;
  left: -3px;
}

.gg-tab {
  display: block;
  box-sizing: border-box;
  position: relative;
  transform: scale(var(--ggs, 1));
  width: 20px;
  height: 16px;
  border: 2px solid;
  border-radius: 3px;
}
.gg-tab::before {
  display: block;
  box-sizing: border-box;
  content: "";
  position: absolute;
  width: 10px;
  height: 6px;
  background: currentColor;
  border-radius: 1px;
  transform: skewX(20deg);
  top: -2px;
  right: -1px;
}

.gg-tag {
  display: block;
  border: 2px solid;
  border-right: 0;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  box-sizing: border-box;
  transform: scale(var(--ggs, 1));
  position: relative;
  width: 19px;
  height: 14px;
}
.gg-tag::before {
  display: block;
  border: 2px solid;
  content: "";
  position: absolute;
  width: 2px;
  height: 2px;
  box-sizing: content-box;
  border-radius: 100px;
  right: 0;
  top: 2px;
}
.gg-tag::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 10px;
  height: 10px;
  border-bottom: 2px solid;
  border-right: 2px solid;
  border-bottom-right-radius: 4px;
  transform: rotate(-45deg);
  top: 0;
  right: -5px;
}

.gg-tally {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: skew(-10deg) scale(var(--ggs, 1));
  width: 18px;
  height: 20px;
}
.gg-tally::after {
  content: "";
  position: absolute;
  box-sizing: border-box;
  display: block;
  width: 3px;
  background: currentColor;
  border-radius: 0.5px;
}
.gg-tally::before {
  content: "";
  position: absolute;
  box-sizing: border-box;
  display: block;
  width: 3px;
  background: currentColor;
  border-radius: 0.5px;
  height: 20px;
  box-shadow: 5px 0 0;
  left: 5px;
}
.gg-tally::after {
  height: 8px;
  box-shadow: 15px 0 0;
}

.gg-tap-double {
  display: block;
  position: relative;
  box-sizing: border-box;
  transform: scale(var(--ggs, 1));
  width: 20px;
  height: 22px;
}
.gg-tap-double::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border-radius: 22px;
}
.gg-tap-double::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border-radius: 22px;
  width: 20px;
  height: 20px;
  border: 6px double transparent;
  border-top: 6px double;
}
.gg-tap-double::after {
  width: 6px;
  height: 10px;
  border: 2px solid transparent;
  border-bottom-width: 5px;
  box-shadow: 0 0 0 2px, inset 0 0 0 2px;
  left: 7px;
  bottom: 2px;
}

.gg-tap-single {
  display: block;
  position: relative;
  box-sizing: border-box;
  transform: scale(var(--ggs, 1));
  width: 16px;
  height: 22px;
}
.gg-tap-single::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border: 2px solid transparent;
  border-radius: 22px;
}
.gg-tap-single::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border: 2px solid transparent;
  border-radius: 22px;
  width: 16px;
  height: 16px;
  border-top: 2px solid;
  top: 2px;
}
.gg-tap-single::after {
  width: 6px;
  height: 10px;
  border-bottom-width: 5px;
  box-shadow: 0 0 0 2px, inset 0 0 0 2px;
  left: 5px;
  top: 8px;
}

.gg-template {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 18px;
  height: 6px;
  border: 2px solid;
  margin-top: -14px;
}
.gg-template::after, .gg-template::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 8px;
  height: 10px;
  top: 6px;
}
.gg-template::after {
  border: 2px solid;
  left: -2px;
}
.gg-template::before {
  border-top: 6px double;
  border-bottom: 2px solid;
  right: -2px;
}

.gg-tennis {
  display: block;
  box-sizing: border-box;
  border: 2px solid;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  position: relative;
  transform: scale(var(--ggs, 1));
  overflow: hidden;
}
.gg-tennis::after, .gg-tennis::before {
  display: block;
  box-sizing: border-box;
  border: 2px solid;
  width: 20px;
  height: 20px;
  border-radius: 20px;
}
.gg-tennis::after {
  content: "";
  position: absolute;
}
.gg-tennis::before {
  content: "";
  position: absolute;
  left: -11px;
  top: -11px;
}
.gg-tennis::after {
  right: -11px;
  bottom: -11px;
}

.gg-terminal {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 24px;
  height: 20px;
  border: 2px solid;
  border-radius: 2px;
}
.gg-terminal::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
}
.gg-terminal::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border-right: 2px solid;
  border-bottom: 2px solid;
  transform: rotate(-45deg);
  width: 6px;
  height: 6px;
  top: 5px;
  left: 3px;
}
.gg-terminal::after {
  width: 4px;
  height: 2px;
  background: currentColor;
  top: 10px;
  left: 11px;
}

.gg-terrain {
  display: block;
  position: relative;
  box-sizing: border-box;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 14px;
  overflow: hidden;
}
.gg-terrain::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
}
.gg-terrain::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 0;
  height: 10px;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 10px solid;
  top: 4px;
}
.gg-terrain::after {
  width: 6px;
  height: 22px;
  background: currentColor;
  transform: rotate(-32deg) skewY(-35deg);
  left: 14px;
  top: 3px;
}

.gg-thermometer {
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 22px;
}
.gg-thermometer::after {
  content: "";
  display: block;
  position: absolute;
  width: 2px;
  border-radius: 22px;
  transform: rotate(45deg);
}
.gg-thermometer::before {
  content: "";
  display: block;
  position: absolute;
  width: 2px;
  border-radius: 22px;
  transform: rotate(45deg);
  height: 12px;
  border: 2px solid transparent;
  box-shadow: 0 0 0 2px, inset 0 0 0 6px;
  right: 6px;
  top: 1px;
}
.gg-thermometer::after {
  height: 8px;
  background: currentColor;
  bottom: 0;
  left: 3px;
}

.gg-thermostat {
  position: relative;
  display: block;
  box-sizing: border-box;
  transform: scale(var(--ggs, 1));
  width: 10px;
  height: 24px;
}
.gg-thermostat::after, .gg-thermostat::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border: 2px solid;
}
.gg-thermostat::after {
  width: 12px;
  height: 12px;
  border-top-color: transparent;
  bottom: 0;
  border-radius: 100px;
  background: radial-gradient(circle, currentColor 20%, transparent 20%) no-repeat center;
}
.gg-thermostat::before {
  width: 8px;
  height: 16px;
  border-bottom-color: transparent;
  left: 2px;
  border-top-left-radius: 100px;
  border-top-right-radius: 100px;
}

.gg-tikcode {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 18px;
  height: 18px;
  background: linear-gradient(to left, currentColor 10px, transparent 0) no-repeat 10px 0/8px 2px, linear-gradient(to left, currentColor 10px, transparent 0) no-repeat 10px 6px/8px 2px, linear-gradient(to left, currentColor 10px, transparent 0) no-repeat 10px 10px/2px 8px, linear-gradient(to left, currentColor 10px, transparent 0) no-repeat 13px 10px/2px 8px, linear-gradient(to left, currentColor 10px, transparent 0) no-repeat 16px 10px/2px 8px, linear-gradient(to left, currentColor 10px, transparent 0) no-repeat 16px 0/2px 8px, linear-gradient(to left, currentColor 10px, transparent 0) no-repeat 10px 0/2px 8px;
}
.gg-tikcode::after, .gg-tikcode::before {
  content: "";
  position: absolute;
  display: block;
  box-sizing: border-box;
  width: 8px;
  height: 8px;
  border: 2px solid;
}
.gg-tikcode::after {
  bottom: 0;
}

.gg-time {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 18px;
  height: 18px;
  border-radius: 100%;
  border: 2px solid transparent;
  box-shadow: 0 0 0 2px currentColor;
}
.gg-time::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 7px;
  height: 7px;
  border-left: 2px solid;
  border-bottom: 2px solid;
  top: 1px;
  left: 5px;
}

.gg-timelapse {
  box-sizing: border-box;
  transform: scale(var(--ggs, 1));
  position: relative;
  display: block;
  width: 22px;
  height: 22px;
  border: 2px solid;
  border-radius: 22px;
}
.gg-timelapse::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 5px;
  height: 4px;
  border: 7px solid;
  border-left-color: transparent;
  border-bottom-color: transparent;
  border-radius: 22px;
  top: 2px;
  right: 2px;
}
.gg-timelapse::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 5px;
  height: 4px;
  border: 7px solid;
  border-left-color: transparent;
  border-bottom-color: transparent;
  border-radius: 22px;
  top: 2px;
  right: 2px;
  transform: rotate(45deg);
}
.gg-timelapse::after {
  border-top-color: transparent;
  transform: rotate(85deg);
}

.gg-timer {
  border: 2px solid;
  width: 18px;
  height: 18px;
  border-radius: 40px;
  background: linear-gradient(to left, currentColor 10px, transparent 0) no-repeat 6px -2px/2px 6px;
  box-sizing: border-box;
  transform: scale(var(--ggs, 1));
  position: relative;
  display: block;
  border-top-color: transparent;
}
.gg-timer::before {
  border: 2px solid;
  width: 18px;
  height: 18px;
  border-radius: 40px;
}
.gg-timer::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
}
.gg-timer::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border-right-color: transparent;
  border-left-color: transparent;
  border-bottom-color: transparent;
  right: -2px;
  top: -2px;
  transform: rotate(45deg);
}
.gg-timer::after {
  width: 2px;
  height: 6px;
  background: currentColor;
  transform: rotate(-50deg);
  left: 4px;
  bottom: 5px;
  border-radius: 100px;
}

.gg-today {
  display: block;
  position: relative;
  box-sizing: border-box;
  transform: scale(var(--ggs, 1));
  width: 18px;
  height: 22px;
  border: 2px solid;
  border-radius: 3px;
}
.gg-today::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  left: 2px;
  background: currentColor;
  border-radius: 1px;
}
.gg-today::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  left: 2px;
  background: currentColor;
  border-radius: 1px;
  bottom: 2px;
  width: 10px;
  height: 10px;
  opacity: 0.5;
}
.gg-today::after {
  top: 2px;
  width: 6px;
  height: 2px;
}

.gg-toggle-off {
  display: block;
  box-sizing: border-box;
  border-radius: 20px;
  border: 2px solid;
  position: relative;
  transform: scale(var(--ggs, 1));
  width: 24px;
  height: 14px;
}
.gg-toggle-off::before {
  display: block;
  box-sizing: border-box;
  border-radius: 20px;
  content: "";
  position: absolute;
  width: 6px;
  height: 6px;
  background: currentColor;
  top: 2px;
  left: 12px;
}

.gg-toggle-on {
  display: block;
  box-sizing: border-box;
  border-radius: 20px;
  border: 2px solid;
  position: relative;
  transform: scale(var(--ggs, 1));
  width: 24px;
  height: 14px;
}
.gg-toggle-on::before {
  display: block;
  box-sizing: border-box;
  border-radius: 20px;
  content: "";
  position: absolute;
  width: 6px;
  height: 6px;
  background: currentColor;
  top: 2px;
  left: 2px;
}

.gg-toggle-square-off {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 24px;
  height: 14px;
  border: 2px solid;
  border-radius: 2px;
}
.gg-toggle-square-off::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 6px;
  height: 6px;
  background: currentColor;
  top: 2px;
  left: 12px;
  border-radius: 1px;
}

.gg-toggle-square {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 24px;
  height: 14px;
  border: 2px solid;
  border-radius: 2px;
}
.gg-toggle-square::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 6px;
  height: 6px;
  background: currentColor;
  top: 2px;
  left: 2px;
  border-radius: 1px;
}

.gg-toolbar-bottom {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 18px;
  height: 12px;
  box-shadow: 0 0 0 2px, inset 0 -2px 0;
  border: 2px solid transparent;
  border-radius: 1px;
}

.gg-toolbar-left {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 18px;
  height: 12px;
  box-shadow: 0 0 0 2px, inset 2px 0 0;
  border: 2px solid transparent;
  border-radius: 1px;
}

.gg-toolbar-right {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 18px;
  height: 12px;
  box-shadow: 0 0 0 2px, inset -2px 0 0;
  border: 2px solid transparent;
  border-radius: 1px;
}

.gg-toolbar-top {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 18px;
  height: 12px;
  box-shadow: 0 0 0 2px, inset 0 2px 0;
  border: 2px solid transparent;
  border-radius: 1px;
}

.gg-toolbox {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 16px;
  border: 2px solid;
  border-radius: 2px;
}
.gg-toolbox::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
}
.gg-toolbox::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border: 2px solid;
  border-bottom: 0;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  left: 4px;
  width: 10px;
  height: 4px;
  top: -5px;
}
.gg-toolbox::after {
  width: 18px;
  height: 2px;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  box-shadow: 0 -2px 0, inset 4px 0 0, inset -4px 0 0;
  top: 4px;
}

.gg-touchpad {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  border: 2px solid;
  border-radius: 3px;
  width: 22px;
  height: 18px;
}
.gg-touchpad::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 2px;
  height: 6px;
  bottom: -2px;
  left: 8px;
  background: currentColor;
}
.gg-touchpad::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 20px;
  height: 2px;
  left: -2px;
  bottom: 3px;
  background: currentColor;
}

.gg-track {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 10px;
  height: 10px;
  border: 2px solid transparent;
  box-shadow: 0 0 0 2px, inset 0 0 0 10px;
  border-radius: 100px;
}
.gg-track::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border-radius: 3px;
}
.gg-track::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border-radius: 3px;
  border-left: 4px solid;
  border-right: 4px solid;
  width: 18px;
  height: 2px;
  left: -6px;
  top: 2px;
}
.gg-track::after {
  width: 2px;
  height: 18px;
  border-top: 4px solid;
  border-bottom: 4px solid;
  left: 2px;
  top: -6px;
}

.gg-transcript {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  border: 2px solid;
  border-radius: 3px;
  width: 22px;
  height: 18px;
}
.gg-transcript::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  height: 2px;
  border-radius: 3px;
  background: currentColor;
  bottom: 2px;
}
.gg-transcript::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  height: 2px;
  border-radius: 3px;
  background: currentColor;
  bottom: 2px;
  width: 10px;
  left: 2px;
  box-shadow: 4px -4px 0;
}
.gg-transcript::after {
  width: 3px;
  right: 2px;
  box-shadow: -11px -4px 0;
}

.gg-trash-empty {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 10px;
  height: 12px;
  border: 2px solid transparent;
  box-shadow: 0 0 0 2px;
  border-bottom-left-radius: 1px;
  border-bottom-right-radius: 1px;
  margin-top: 4px;
}
.gg-trash-empty::after, .gg-trash-empty::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
}
.gg-trash-empty::after {
  background: currentColor;
  border-radius: 3px;
  width: 16px;
  height: 2px;
  top: -4px;
  left: -5px;
}
.gg-trash-empty::before {
  width: 10px;
  height: 4px;
  border: 2px solid;
  border-bottom: transparent;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  top: -7px;
  left: -2px;
}

.gg-trash {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 10px;
  height: 12px;
  border: 2px solid transparent;
  box-shadow: 0 0 0 2px, inset -2px 0 0, inset 2px 0 0;
  border-bottom-left-radius: 1px;
  border-bottom-right-radius: 1px;
  margin-top: 4px;
}
.gg-trash::after, .gg-trash::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
}
.gg-trash::after {
  background: currentColor;
  border-radius: 3px;
  width: 16px;
  height: 2px;
  top: -4px;
  left: -5px;
}
.gg-trash::before {
  width: 10px;
  height: 4px;
  border: 2px solid;
  border-bottom: transparent;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  top: -7px;
  left: -2px;
}

.gg-tree {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 10px;
  height: 20px;
}
.gg-tree::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border-radius: 8px;
}
.gg-tree::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border-radius: 8px;
  width: 10px;
  height: 16px;
  border: 2px solid;
}
.gg-tree::after {
  width: 2px;
  height: 12px;
  background: currentColor;
  left: 4px;
  bottom: 0;
}

.gg-trees {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 16px;
  height: 20px;
  border-left: 4px solid transparent;
  border-right: 3px solid transparent;
  border-top: 10px solid transparent;
  box-shadow: inset 2px 0 0, inset -2px 0 0;
}
.gg-trees::after, .gg-trees::before {
  content: "";
  display: block;
  position: absolute;
  box-sizing: border-box;
  width: 10px;
  height: 16px;
  border: 2px solid;
  border-radius: 8px;
  left: -4px;
  top: -10px;
}
.gg-trees::after {
  width: 8px;
  height: 12px;
  top: -6px;
  left: 4px;
}

.gg-trello {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 20px;
  height: 20px;
  border: 2px solid;
  border-radius: 2px;
}
.gg-trello::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 5px;
  background: currentColor;
  border-radius: 1px;
  top: 2px;
}
.gg-trello::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 5px;
  background: currentColor;
  border-radius: 1px;
  top: 2px;
  left: 2px;
  height: 12px;
}
.gg-trello::after {
  height: 8px;
  right: 2px;
}

.gg-trending-down {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: rotate(45deg) scale(var(--ggs, 1));
  width: 10px;
  height: 8px;
  border-bottom: 2px solid;
  border-right: 2px solid;
}
.gg-trending-down::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
}
.gg-trending-down::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  background: currentColor;
  left: 8px;
  width: 10px;
  height: 2px;
  top: 0;
}
.gg-trending-down::after {
  width: 0;
  height: 0;
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-left: 6px solid;
  top: -3px;
  right: -14px;
}

.gg-trending {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: rotate(-45deg) scale(var(--ggs, 1));
  width: 10px;
  height: 8px;
  border-top: 2px solid;
  border-right: 2px solid;
}
.gg-trending::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
}
.gg-trending::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  background: currentColor;
  left: 8px;
  width: 10px;
  height: 2px;
  bottom: 0;
}
.gg-trending::after {
  width: 0;
  height: 0;
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-left: 6px solid;
  bottom: -3px;
  right: -14px;
}

.gg-trophy {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 10px;
  height: 12px;
  border: 2px solid;
  border-bottom-left-radius: 100px;
  border-bottom-right-radius: 100px;
}
.gg-trophy::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
}
.gg-trophy::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  box-shadow: inset 0 0 0 2px, 0 2px 0 0;
  bottom: -4px;
  width: 6px;
  height: 4px;
  border-left: 2px solid transparent;
  border-right: 2px solid transparent;
  left: 0;
}
.gg-trophy::after {
  width: 16px;
  height: 6px;
  border-left: 2px solid;
  border-right: 2px solid;
  left: -5px;
  top: 0;
}

.gg-tv {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 16px;
  height: 11px;
  border: 2px solid;
  border-radius: 2px;
}
.gg-tv::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
}
.gg-tv::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border-left: 2px solid;
  border-bottom: 2px solid;
  border-bottom-left-radius: 2px;
  top: -6px;
  left: 3px;
  transform: rotate(-45deg);
  width: 6px;
  height: 5px;
}
.gg-tv::after {
  width: 8px;
  height: 1px;
  background: currentColor;
  border-radius: 10px;
  bottom: -4px;
  right: 2px;
}

.gg-twilio {
  display: block;
  position: relative;
  box-sizing: border-box;
  transform: scale(var(--ggs, 1));
  width: 24px;
  height: 24px;
  border-radius: 22px;
  box-shadow: inset 0 0 0 3px;
}
.gg-twilio::after {
  content: "";
  display: block;
  position: absolute;
  box-sizing: border-box;
  background: currentColor;
  border-radius: 6px;
  width: 4px;
  height: 4px;
  top: 7px;
  box-shadow: 0 6px 0;
}
.gg-twilio::before {
  content: "";
  display: block;
  position: absolute;
  box-sizing: border-box;
  background: currentColor;
  border-radius: 6px;
  width: 4px;
  height: 4px;
  top: 7px;
  box-shadow: 0 6px 0;
  left: 7px;
}
.gg-twilio::after {
  right: 7px;
}

.gg-twitter {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 20px;
  height: 20px;
}
.gg-twitter::after {
  content: "";
  display: block;
  position: absolute;
  box-sizing: border-box;
  left: 4px;
}
.gg-twitter::before {
  content: "";
  display: block;
  position: absolute;
  box-sizing: border-box;
  left: 4px;
  width: 9px;
  height: 14px;
  border-left: 4px solid;
  border-bottom: 4px solid;
  border-bottom-left-radius: 6px;
  background: linear-gradient(to left, currentColor 12px, transparent 0) no-repeat center 2px/10px 4px;
  top: 4px;
}
.gg-twitter::after {
  width: 4px;
  height: 4px;
  background: currentColor;
  border-radius: 20px;
  top: 2px;
  box-shadow: 7px 4px 0, 7px 12px 0;
}

.gg-ui-kit {
  display: block;
  box-sizing: border-box;
  position: relative;
  transform: scale(var(--ggs, 1));
  width: 10px;
  height: 16px;
  border-radius: 3px;
  border: 2px solid;
}
.gg-ui-kit::after, .gg-ui-kit::before {
  display: block;
  box-sizing: border-box;
}
.gg-ui-kit::after {
  content: "";
  position: absolute;
  border: 2px solid;
  width: 6px;
  height: 12px;
}
.gg-ui-kit::before {
  content: "";
  position: absolute;
  border: 2px solid;
  width: 6px;
  height: 12px;
  border-right: 0;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  transform: perspective(10px) rotateY(30deg);
  left: -8px;
}
.gg-ui-kit::after {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  transform: perspective(10px) rotateY(-30deg);
  border-left: 0;
  right: -8px;
}

.gg-umbrella {
  display: block;
  box-sizing: border-box;
  transform: scale(var(--ggs, 1));
  position: relative;
  width: 22px;
  height: 22px;
  background: linear-gradient(to bottom, currentColor 20px, transparent 0) no-repeat center 10px/2px 8px;
}
.gg-umbrella::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border: 2px solid;
}
.gg-umbrella::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border: 2px solid;
  width: 16px;
  height: 10px;
  left: 3px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}
.gg-umbrella::after {
  width: 6px;
  height: 5px;
  border-top: 0;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  bottom: 0;
  left: 6px;
}

.gg-unavailable {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: rotate(-45deg) scale(var(--ggs, 1));
  width: 18px;
  height: 18px;
  border: 2px solid;
  border-radius: 100px;
}
.gg-unavailable::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border-radius: 3px;
  width: 2px;
  height: 18px;
  background: currentColor;
  top: -2px;
  left: 6px;
}

.gg-unblock {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: rotate(45deg) scale(var(--ggs, 1));
  width: 18px;
  height: 18px;
  border: 2px solid;
  border-radius: 100%;
}
.gg-unblock::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 2px;
  height: 7px;
  background: currentColor;
  border-radius: 5px;
  left: 6px;
}
.gg-unblock::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 2px;
  height: 7px;
  background: currentColor;
  border-radius: 5px;
  left: 6px;
  top: -2px;
}
.gg-unblock::after {
  bottom: -2px;
}

.gg-undo {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 14px;
  height: 14px;
  border: 2px solid;
  border-left-color: transparent;
  border-radius: 100px;
}
.gg-undo::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 6px;
  height: 6px;
  border-top: 2px solid;
  border-left: 2px solid;
  top: -3px;
  left: -1px;
  transform: rotate(-68deg);
}

.gg-unfold {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 20px;
  height: 20px;
  border-radius: 100%;
  border-bottom: 10px solid;
}

.gg-unsplash {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 16px;
  height: 15px;
}
.gg-unsplash::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
}
.gg-unsplash::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 16px;
  height: 9px;
  border: 5px solid;
  border-top: 0;
  bottom: 0;
}
.gg-unsplash::after {
  width: 6px;
  height: 4px;
  background: currentColor;
  top: 0;
  left: 5px;
}

.gg-usb-c {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 16px;
  height: 6px;
  border: 2px solid transparent;
  border-radius: 100px;
  box-shadow: 0 0 0 2px, inset 0 0 0 10px;
}

.gg-usb {
  display: block;
  box-sizing: border-box;
  position: relative;
  width: 14px;
  height: 14px;
  border: 2px solid;
  transform: scale(var(--ggs, 1));
  margin-bottom: -6px;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}
.gg-usb::before {
  display: block;
  box-sizing: border-box;
  content: "";
  position: absolute;
  width: 6px;
  height: 5px;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  border-top: 1px solid transparent;
  border-bottom: 2px solid transparent;
  box-shadow: 0 0 0 2px, inset 5px 4px 0 -4px, inset -5px 4px 0 -4px;
  top: -7px;
  left: 2px;
}

.gg-user-add {
  display: block;
  transform: scale(var(--ggs, 1));
  box-sizing: border-box;
  width: 20px;
  height: 18px;
  background: linear-gradient(to left, currentColor 8px, transparent 0) no-repeat 14px 6px/6px 2px, linear-gradient(to left, currentColor 8px, transparent 0) no-repeat 16px 4px/2px 6px;
}
.gg-user-add::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border: 2px solid;
}
.gg-user-add::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border: 2px solid;
  width: 8px;
  height: 8px;
  border-radius: 30px;
  top: 0;
  left: 2px;
}
.gg-user-add::after {
  width: 12px;
  height: 9px;
  border-bottom: 0;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  top: 9px;
}

.gg-user-list {
  display: block;
  transform: scale(var(--ggs, 1));
  box-sizing: border-box;
  width: 20px;
  height: 18px;
  background: linear-gradient(to left, currentColor 8px, transparent 0) no-repeat 14px 4px/6px 2px, linear-gradient(to left, currentColor 8px, transparent 0) no-repeat 14px 8px/6px 2px, linear-gradient(to left, currentColor 8px, transparent 0) no-repeat 14px 12px/6px 2px;
}
.gg-user-list::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border: 2px solid;
}
.gg-user-list::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border: 2px solid;
  width: 8px;
  height: 8px;
  border-radius: 30px;
  top: 0;
  left: 2px;
}
.gg-user-list::after {
  width: 12px;
  height: 9px;
  border-bottom: 0;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  top: 9px;
}

.gg-user-remove {
  display: block;
  transform: scale(var(--ggs, 1));
  box-sizing: border-box;
  width: 20px;
  height: 18px;
  background: linear-gradient(to left, currentColor 8px, transparent 0) no-repeat 14px 6px/6px 2px;
}
.gg-user-remove::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border: 2px solid;
}
.gg-user-remove::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border: 2px solid;
  width: 8px;
  height: 8px;
  border-radius: 30px;
  top: 0;
  left: 2px;
}
.gg-user-remove::after {
  width: 12px;
  height: 9px;
  border-bottom: 0;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  top: 9px;
}

.gg-user {
  display: block;
  transform: scale(var(--ggs, 1));
  box-sizing: border-box;
  width: 12px;
  height: 18px;
}
.gg-user::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border: 2px solid;
}
.gg-user::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border: 2px solid;
  width: 8px;
  height: 8px;
  border-radius: 30px;
  top: 0;
  left: 2px;
}
.gg-user::after {
  width: 12px;
  height: 9px;
  border-bottom: 0;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  top: 9px;
}

.gg-userlane {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 16px;
  height: 8px;
  border-radius: 20px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border: 4px solid;
  border-top-color: transparent;
}
.gg-userlane::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 6px;
  height: 6px;
  background: currentColor;
  top: -12px;
}
.gg-userlane::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 6px;
  height: 6px;
  background: currentColor;
  top: -12px;
  border-radius: 20px;
  left: -4px;
}
.gg-userlane::after {
  right: -4px;
}

.gg-view-cols {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  border: 2px solid;
  border-radius: 3px;
  width: 20px;
  height: 14px;
}
.gg-view-cols::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 2px;
  height: 12px;
  top: -2px;
  left: 4px;
  background: currentColor;
  box-shadow: 6px 0 0;
}

.gg-view-comfortable {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  border: 2px solid;
  border-radius: 3px;
  width: 20px;
  height: 14px;
}
.gg-view-comfortable::after, .gg-view-comfortable::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  background: currentColor;
}
.gg-view-comfortable::after {
  width: 2px;
  height: 8px;
  bottom: -2px;
  left: 4px;
}
.gg-view-comfortable::before {
  width: 20px;
  height: 2px;
  left: -2px;
  top: 4px;
}

.gg-view-day {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  border: 2px solid;
  border-radius: 3px;
  width: 20px;
  height: 14px;
}
.gg-view-day::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 2px;
  height: 12px;
  top: -2px;
  left: 7px;
  background: currentColor;
}
.gg-view-day::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 2px;
  height: 12px;
  top: -2px;
  left: 7px;
  background: currentColor;
  width: 20px;
  height: 2px;
  left: -2px;
  top: 4px;
}

.gg-view-grid {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  border: 2px solid;
  border-radius: 3px;
  width: 20px;
  height: 14px;
}
.gg-view-grid::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 2px;
  height: 14px;
  top: -2px;
  left: 4px;
  background: currentColor;
  box-shadow: 6px 0 0;
}
.gg-view-grid::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 2px;
  height: 14px;
  top: -2px;
  left: 4px;
  background: currentColor;
  box-shadow: 6px 0 0;
  width: 20px;
  height: 2px;
  left: -2px;
  top: 2px;
  box-shadow: 0 4px 0;
}

.gg-view-list {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  border: 2px solid;
  border-radius: 3px;
  width: 20px;
  height: 14px;
}
.gg-view-list::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 2px;
  height: 14px;
  top: -2px;
  left: 3px;
  background: currentColor;
}
.gg-view-list::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 2px;
  height: 14px;
  top: -2px;
  left: 3px;
  background: currentColor;
  width: 20px;
  height: 2px;
  left: -2px;
  top: 2px;
  box-shadow: 0 4px 0;
}

.gg-view-month {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  border: 2px solid;
  border-radius: 3px;
  width: 20px;
  height: 14px;
}
.gg-view-month::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 2px;
  height: 14px;
  top: -2px;
  left: 3px;
  background: currentColor;
  box-shadow: 4px 0 0, 8px 0 0;
}
.gg-view-month::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 2px;
  height: 14px;
  top: -2px;
  left: 3px;
  background: currentColor;
  box-shadow: 4px 0 0, 8px 0 0;
  width: 20px;
  height: 2px;
  left: -2px;
  top: 2px;
  box-shadow: 0 4px 0;
}

.gg-view-split {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  border: 2px solid;
  border-radius: 3px;
  width: 20px;
  height: 14px;
}
.gg-view-split::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 2px;
  height: 12px;
  top: -2px;
  left: 7px;
  background: currentColor;
}

.gg-vinyl {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 8px;
  height: 8px;
  border: 3px solid transparent;
  box-shadow: 0 0 0 6px, inset 0 0 0 2px;
  border-radius: 22px;
}

.gg-voicemail-o {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 22px;
  background: linear-gradient(to left, currentColor 10px, transparent 0) no-repeat 5px 10px/8px 2px;
  border-radius: 30px;
  border: 2px solid;
}
.gg-voicemail-o::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 6px;
  height: 6px;
  border: 2px solid;
  border-radius: 10px;
  top: 6px;
}
.gg-voicemail-o::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 6px;
  height: 6px;
  border: 2px solid;
  border-radius: 10px;
  top: 6px;
  left: 2px;
}
.gg-voicemail-o::after {
  right: 2px;
}

.gg-voicemail-r {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 21px;
  height: 14px;
  background: linear-gradient(to left, currentColor 10px, transparent 0) no-repeat 5px 6px/7px 2px;
  border-radius: 3px;
  border: 2px solid;
}
.gg-voicemail-r::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 6px;
  height: 6px;
  border: 2px solid;
  border-radius: 10px;
  top: 2px;
}
.gg-voicemail-r::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 6px;
  height: 6px;
  border: 2px solid;
  border-radius: 10px;
  top: 2px;
  left: 2px;
}
.gg-voicemail-r::after {
  right: 2px;
}

.gg-voicemail {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 12px;
  height: 10px;
  border-bottom: 2px solid;
}
.gg-voicemail::after, .gg-voicemail::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 10px;
  height: 10px;
  border: 2px solid;
  border-radius: 10px;
  top: 0;
  left: -5px;
}
.gg-voicemail::after {
  left: 7px;
}

.gg-volume {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 8px;
  height: 8px;
  border: 2px solid;
  border-right: 0;
  -webkit-perspective: 12px;
  perspective: 12px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.gg-volume::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
}
.gg-volume::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  left: 2px;
  transform: rotateY(-90deg);
  width: 10px;
  height: 10px;
  border: 2px solid;
  border-left: 0;
  top: -3px;
}
.gg-volume::after {
  width: 8px;
  height: 16px;
  border: 6px double;
  border-left: 0;
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
  right: -14px;
  top: -6px;
}

.gg-webcam {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 8px;
  height: 8px;
  border: 2px solid transparent;
  box-shadow: 0 0 0 2px, inset 0 0 0 2px;
  border-radius: 100px;
  margin-top: -4px;
}
.gg-webcam::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 10px;
  height: 4px;
  border-right: 4px solid transparent;
  box-shadow: 0 2px 0, inset -2px 0 0;
  bottom: -6px;
  left: -3px;
}

.gg-website {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 18px;
  border: 2px solid;
  border-radius: 3px;
  box-shadow: 0 -1px 0;
}
.gg-website::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 6px;
  top: 2px;
}
.gg-website::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 6px;
  top: 2px;
  background: currentColor;
  left: 2px;
  box-shadow: 0 4px 0, 0 8px 0;
  border-radius: 3px;
  height: 2px;
}
.gg-website::after {
  height: 10px;
  border: 2px solid;
  right: 2px;
  border-radius: 1px;
}

.gg-windows {
  transform: scale(var(--ggs, 1));
  display: block;
  position: relative;
  box-sizing: border-box;
  width: 17px;
  height: 15px;
}
.gg-windows::before {
  display: block;
  position: relative;
  box-sizing: border-box;
  width: 17px;
  height: 15px;
  content: "";
  position: absolute;
  width: 7px;
  height: 6px;
  background: currentColor;
  box-shadow: 8px 0 0, 8px 7px 0, 0 7px 0;
  transform: perspective(30px) rotateY(-30deg);
}

.gg-work-alt {
  display: block;
  box-sizing: border-box;
  box-shadow: 0 0 0 2px;
  position: relative;
  transform: scale(var(--ggs, 1));
  width: 14px;
  height: 10px;
  border-radius: 1px;
}
.gg-work-alt::after {
  display: block;
  box-sizing: border-box;
  box-shadow: 0 0 0 2px;
  content: "";
  position: absolute;
  width: 6px;
  height: 1px;
  border-top-left-radius: 1px;
  border-top-right-radius: 1px;
  left: 4px;
  top: -3px;
}

.gg-yinyang {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: rotate(95deg) scale(var(--ggs, 1));
  width: 20px;
  height: 20px;
  border: 2px solid;
  border-radius: 22px;
}
.gg-yinyang::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 10px;
  top: 4px;
}
.gg-yinyang::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 10px;
  top: 4px;
  border: 2px solid;
  left: 0;
}
.gg-yinyang::after {
  border: 2px solid transparent;
  right: 0;
  box-shadow: inset 0 0 0 4px, 0 -3px 0 1px, -2px -4px 0 1px, -8px -5px 0 -1px, -11px -3px 0 -2px, -12px -1px 0 -3px, -6px -6px 0 -1px;
}

.gg-youtube {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 16px;
  height: 10px;
  box-shadow: 0 0 0 2px;
  border-radius: 15px 15px 15px 15px/45px 45px 45px 45px;
}
.gg-youtube::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  left: 7px;
  top: 2px;
  border-left: 4px solid currentColor;
  border-top: 3px solid transparent;
  border-bottom: 3px solid transparent;
}

.gg-zeit {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 0px;
  height: 0px;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-bottom: 18px solid currentColor;
}

.gg-zoom-in {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 16px;
  height: 16px;
  border: 2px solid;
  border-radius: 100%;
  background: linear-gradient(to bottom, currentColor 5px, transparent 0) no-repeat 2px 5px/8px 2px;
  margin-top: -4px;
}
.gg-zoom-in::after, .gg-zoom-in::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 2px;
  height: 8px;
  background: currentColor;
}
.gg-zoom-in::after {
  transform: rotate(-45deg);
  border-radius: 3px;
  top: 10px;
  left: 12px;
}
.gg-zoom-in::before {
  top: 2px;
  left: 5px;
}

.gg-zoom-out {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 16px;
  height: 16px;
  border: 2px solid;
  border-radius: 100%;
  margin-top: -4px;
}
.gg-zoom-out::after, .gg-zoom-out::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  background: currentColor;
}
.gg-zoom-out::after {
  transform: rotate(-45deg);
  border-radius: 3px;
  width: 2px;
  height: 8px;
  top: 10px;
  left: 12px;
}
.gg-zoom-out::before {
  width: 8px;
  height: 2px;
  top: 5px;
  left: 2px;
}